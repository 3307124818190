import get from 'lodash/get';
import * as ActionType from '../actions/actionType';

const innitialState = {
  isLogin: false,
  authUser: {},
  accessToken: undefined
};

export default (state = innitialState, action) => {
  switch (action.type) {
    case ActionType.USER_LOGIN_SUCCESS: {
      localStorage.setItem(
        'authenticated',
        get(action, 'authUser.accessToken', '')
      );
      return {
        ...state,
        authUser: action.authUser,
        isLogin: true,
        accessToken: get(action, 'authUser.accessToken', '')
      };
    }
    case ActionType.USER_RESUME_SUCCESS: {
      localStorage.setItem(
        'authenticated',
        get(action, 'authUser.accessToken', '')
      );
      return {
        ...state,
        authUser: action.data,
        isLogin: true,
        accessToken: get(action, 'data.accessToken', '')
      };
    }
    case ActionType.RENEW_TOKEN_SUCCESS: {
      const { authUser, accessToken } = action.data;
      return {
        ...state,
        authUser: {
          ...state.authUser,
          roleId: authUser.roleIds[0] // roleId obtained from accessToken will be stored in array
        },
        accessToken
      };
    }
    case ActionType.USER_UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        authUser: action.data
      };
    }
    case ActionType.USER_LOGOUT_RECEIVED: {
      localStorage.removeItem('authenticated');
      return innitialState;
    }
    default: {
      return state;
    }
  }
};

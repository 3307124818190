import styled from '@emotion/styled';

export default styled.div`
  label: component-menu;
  max-width: 1200px;
  margin: auto;
  padding: 0 14px;
  ul.list-menu {
    margin: 0;
    padding: 0;
    display: flex;
    overflow: auto;
    > li {
      flex: 1 1 auto;
      left: 0;
      padding: 0;
      margin-right: 8px;
      &:last-chind {
        margin-right: 0;
      }
      a {
        font-family: Kanit;
        font-weight: 500;
        font-size: 28px;
        color: #393f4d;
        background: #ffe0c2;
        border-radius: 30px 30px 0px 0px;
        display: block;
        width: 100%;
        text-align: center;
        padding: 20px 10px;
        white-space: nowrap;
        &.selected {
          background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
          color: #ffffff;
        }
      }
    }
  }
`;

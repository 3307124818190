import styled from '@emotion/styled';

export default styled.div`
  label: component-loading;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: #fff;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: all .4s ease-in-out;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  > .content {
    max-width: 370px;
    @media (max-width: 425px) {
      max-width: 100%;
    }
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .wrap-icon {
      margin-bottom: 100px;
    }
    .message {
      font-family: Kanit;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: #393f4d;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Breadcrumb from '../../components/Breadcrumb';

import useLoading from '../../hooks/loading';
import { getUserById, updateUser } from '../../service';

import FormData from './Form';
import { StylePageInfo as Style } from './style';

const Update = (props) => {
  const { match, history } = props;
  const id = get(match, 'params.id', '');
  const loading = useLoading();
  const [state, setState] = React.useState({});
  const [isReady, setIsReady] = React.useState(false);

  const loadData = async () => {
    try {
      const res = await getUserById(id);
      setState(get(res, 'data'));
      setIsReady(true);
    } catch (err) {
      console.log('err >>', err);
    }
    loading.close();
  };

  React.useEffect(() => {
    loadData();
  }, [id]); // eslint-disable-line

  const handleSubmit = async (body) => {
    loading.open();
    try {
      await updateUser(id, body);
      history.push('/users');
    } catch (err) {
      console.log('error ', err);
    }
    loading.close();
  };

  const handlePageBack = () => {
    history.push('/users');
  };

  if (!isReady) return '';
  return (
    <Style>
      <div className="container">
        <Breadcrumb title="จัดการผู้ใช้งาน" onBack={handlePageBack} />
        <FormData
          id={state.id}
          isUpdate
          defaultValue={state}
          onSubmit={handleSubmit}
        />
      </div>
    </Style>
  );
};

Update.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

Update.defaultProps = {};

export default Update;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Modal, Form, DatePicker, Select } from 'antd';

import Button from '../Button';
import { Pen } from '../Icons';
import {
  optionOccurencePlace,
  optionReportedChannel,
  dateToFormat,
  stringToDate
} from '../../utils';

import useLoading from '../../hooks/loading';
import { updateIncident } from '../../service';

import Style from './style';

const EditCaseInfo = (props) => {
  const { value, onSuccess } = props;
  const [form] = Form.useForm();
  const loading = useLoading();

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    form.setFields([
      {
        name: 'occurencePeriodFrom',
        value: stringToDate(get(value, 'occurencePeriodFrom'))
      },
      {
        name: 'occurencePeriodTo',
        value: stringToDate(get(value, 'occurencePeriodTo'))
      },
      {
        name: 'occurencePlace',
        value: get(value, 'occurencePlace')
      },
      {
        name: 'reportedChannel',
        value: get(value, 'reportedChannel')
      }
    ]);
    setIsModalVisible(!isModalVisible);
  };

  const onFinish = async (data) => {
    loading.open();
    try {
      const body = {
        ...value,
        occurencePeriodFrom: dateToFormat(get(data, 'occurencePeriodFrom', '')),
        occurencePeriodTo: dateToFormat(get(data, 'occurencePeriodTo', '')),
        occurencePlace: get(data, 'occurencePlace', ''),
        reportedChannel: get(data, 'reportedChannel', '')
      };
      await updateIncident(get(value, 'id'), body);
      setIsModalVisible(false);
      onSuccess();
    } catch (err) {
      console.log('error', err);
      loading.close();
    }
  };

  return (
    <>
      <Button className="with-icon" size="sm" primary onClick={showModal}>
        <span>แก้ไข</span>
        <Pen color="#FFFFFF" />
      </Button>
      <Modal
        title="แก้ไขข้อมูลเบื้องต้น"
        visible={isModalVisible}
        centered
        width={700}
        footer={false}
        className="main-modal"
      >
        <Style>
          <div className="wrap-form">
            <Form
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <Form.Item label="เวลาที่เกิดเหตุ" name="occurencePeriodFrom">
                <DatePicker format="DD/MM/YYYY HH:mm" showTime />
              </Form.Item>
              <Form.Item
                label="สิ้นสุดเวลาที่เกิดเหตุ"
                name="occurencePeriodTo"
              >
                <DatePicker format="DD/MM/YYYY HH:mm" showTime />
              </Form.Item>
              <Form.Item label="สถานที่เกิดเหตุ" name="occurencePlace">
                <Select options={optionOccurencePlace} />
              </Form.Item>
              <Form.Item label="ช่องทางการแจ้งเตือน" name="reportedChannel">
                <Select options={optionReportedChannel} />
              </Form.Item>
              <hr />
              <div className="wrap-button">
                <Button size="lg" onClick={showModal}>
                  ยกเลิกการแก้ไขข้อมูล
                </Button>
                <Button size="lg" type="submit" primary>
                  บันทึก
                </Button>
              </div>
            </Form>
          </div>
        </Style>
      </Modal>
    </>
  );
};

EditCaseInfo.propTypes = {
  value: PropTypes.shape(),
  onSuccess: PropTypes.func
};

EditCaseInfo.defaultProps = {
  value: {},
  onSuccess: () => null
};

export default EditCaseInfo;

import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { Modal, Form, Input, Select, Radio, Row, Col } from 'antd';

import Button from '../Button';
import InputPhone from '../InputPhone';

import useLoading from '../../hooks/loading';
import { getProvinces, updateIncident } from '../../service';
import { Pen } from '../Icons';
import Style from './style';

const EditPatientInfo = (props) => {
  const { value, onSuccess } = props;
  const [form] = Form.useForm();
  const loading = useLoading();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [provinces, setProvinces] = React.useState([]);

  const init = async () => {
    try {
      const res = await getProvinces();
      const values = map(res.data, (obj) => ({
        label: obj.provinceName,
        value: obj.provinceCode,
      }));
      setProvinces(orderBy(values, ['value'], ['asc']));
    } catch (err) {
      console.log('EditPatientInfo err', err);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
    form.setFields([
      {
        name: 'firstName',
        value: get(value, 'firstName'),
      },
      {
        name: 'lastName',
        value: get(value, 'lastName'),
      },
      {
        name: 'gender',
        value: get(value, 'gender'),
      },
      {
        name: 'telephone',
        value: get(value, 'telephone'),
      },
      {
        name: 'address',
        value: get(value, 'address'),
      },
      {
        name: 'provinceCode',
        value: get(value, 'provinceCode'),
      },
      {
        name: 'socila',
        value: get(value, 'socila'),
      },
    ]);
  };

  const onFinish = async (data) => {
    loading.open();
    try {
      const body = {
        ...value,
        ...data,
      };
      await updateIncident(get(value, 'id'), body);
      setIsModalVisible(false);
      onSuccess();
    } catch (err) {
      console.log('error', err);
      loading.close();
    }
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <>
      <Button className="with-icon" size="sm" primary onClick={showModal}>
        <span>แก้ไข</span>
        <Pen color="#FFFFFF" />
      </Button>
      <Modal
        title="แก้ไขข้อมูลผู้มีความเสี่ยง"
        visible={isModalVisible}
        centered
        width={700}
        footer={false}
        className="main-modal"
      >
        <Style>
          <div className="wrap-form">
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Row>
                <Col md={12}>
                  <Form.Item label="ชื่อ ผู้มีความเสี่ยง" name="firstName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="นามสกุล ผู้มีความเสี่ยง" name="lastName">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Item label="เพศ" name="gender">
                    <Radio.Group>
                      <Radio value="male">ชาย</Radio>
                      <Radio value="female">หญิง</Radio>
                      <Radio value="other">ไม่ระบุ</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="เบอร์โทรศัพท์มือถือ ผู้มีความเสี่ยง"
                    name="telephone"
                  >
                    <InputPhone />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="ที่อยู่" name="address">
                <Input.TextArea rows={4} />
              </Form.Item>
              <Row>
                <Col md={12}>
                  <Form.Item label="จังหวัด" name="provinceCode">
                    <Select
                      showSearch
                      options={provinces}
                      filterOption={filterOption}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="บัญชีผู้ใช้ FG, IG, Tiktok" name="socila">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <hr />
              <div className="wrap-button">
                <Button size="lg" onClick={showModal}>
                  ยกเลิกการแก้ไขข้อมูล
                </Button>
                <Button size="lg" type="submit" primary>
                  บันทึก
                </Button>
              </div>
            </Form>
          </div>
        </Style>
      </Modal>
    </>
  );
};

EditPatientInfo.propTypes = {
  value: PropTypes.shape(),
  onSuccess: PropTypes.func,
};

EditPatientInfo.defaultProps = {
  value: {},
  onSuccess: () => null,
};

export default EditPatientInfo;

import styled from '@emotion/styled';

export default styled.div`
  label: page-incidents-form;
  background: #ffffff;
  padding: 24px 32px;
  > .title {
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  section {
    h2 {
      display: block;
      text-align: center;
      background: #ff937e;
      padding: 24px;
      color: #fff;
      border-radius: 20px 20px 0px 0px;
    }
    textarea.ant-input {
      height: unset;
    }
    &.info,
    &.relates {
      h2 {
        margin-bottom: 24px;
      }
    }
    .content-section {
      padding: 0 24px;
    }
  }
  .select-type {
    background: #fffaf5;
    padding: 24px;
    margin-bottom: 24px;
  }
  .wrap-relates {
    .relate {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
    .wrap-button-remove {
      display: flex;
      justify-content: flex-end;
    }
    button.button-with-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      &.remove svg {
        width: 14px;
        height: 14px;
        path {
          fill: #ee6538;
        }
      }
      > svg {
        margin-right: 6px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .wrap-button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    button {
      width: 255px;
      margin: 0 16px;
    }
  }

  .officer {
    > ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 -12px;
      padding-bottom: 24px;
      border-bottom: 1px solid #E5E5E5;
      li {
        flex: 1 1 auto;
        padding: 0 12px;
        &.active > button {
          background: #ee6538;
          color: #fff;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #feecd7;
          cursor: pointer;
          box-shadow: 0px 0px 4px 0px #bed9f1;
          height: 80px;
          padding: 12px;
          border-radius: 16px;
          color: #393f4d;
          border: none;
          font-size: 20px;
          font-weight: 500;
          &:disabled {
            background: #f2f6fb;
            cursor: not-allowed;
            opacity: 0.7;
          }
          > svg {
            margin-right: 24px;
          }
          width: 100%;
        }
      }
    }
    > .content {
      padding-top: 22px;
      .contact-crime-suppression {
        h3 {
          margin-bottom: 8px;
        }
        .ant-form-item {
          margin-bottom: 8px;
        }
      }
      .risk-assessment {
        h3 {
          margin-bottom: 22px;
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { LayoutMenu } from '../../components/Layout';
import useLoading from '../../hooks/loading';
import Chat from './Chat';
import Style from './style';


import { getAllChannel } from '../../service';

const Message = () => {
  const loading = useLoading();
  const [userSelected, setUserSelected] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const inputEl = React.useRef(null);

  const handleLoadData = async () => {
    loading.open();
    const query = {
      sortBy: 'chat_channels.updatedAt',
      order: 'desc',
    };
    if (inputEl.current.value !== '') {
      query.search = inputEl.current.value;
    }
    try {
      const result = await getAllChannel(query);
      setUsers(result.data);
      setUserSelected({});
    } catch (e) {
      console.error(e);
    }
    loading.close();
  };

  React.useEffect(() => {
    handleLoadData();
  }, []); // eslint-disable-line

  const handleSelectUser = (user) => {
    if (user.userId !== userSelected.userId) {
      setUserSelected(user);
    } else {
      setUserSelected({});
    }
  };

  return (
    <LayoutMenu>
      <Style>
        <div className="list">
          <div className="title">
            <span>เคสทั้งหมด</span>
            <span className="count">{users.length}</span>
          </div>
          <div className="wrap-search">
            <div className="wrap-input-search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  opacity="0.4"
                  d="M13.7812 13.6289L10.1172 9.96484C10.9102 9.00781 11.3477 7.77734 11.3477 6.4375C11.3477 3.32031 8.77734 0.75 5.66016 0.75C2.51562 0.75 0 3.32031 0 6.4375C0 9.58203 2.54297 12.125 5.66016 12.125C6.97266 12.125 8.20312 11.6875 9.1875 10.8945L12.8516 14.5586C12.9883 14.6953 13.1523 14.75 13.3438 14.75C13.5078 14.75 13.6719 14.6953 13.7812 14.5586C14.0547 14.3125 14.0547 13.9023 13.7812 13.6289ZM1.3125 6.4375C1.3125 4.03125 3.25391 2.0625 5.6875 2.0625C8.09375 2.0625 10.0625 4.03125 10.0625 6.4375C10.0625 8.87109 8.09375 10.8125 5.6875 10.8125C3.25391 10.8125 1.3125 8.87109 1.3125 6.4375Z"
                  fill="black"
                />
              </svg>
              <input
                type="text"
                placeholder="Search case..."
                ref={inputEl}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    handleLoadData();
                  }
                }}
              />
            </div>
          </div>
          <div className="wrap-customer">
            <ul>
              {map(users, (user) => {
                let name = 'ไม่มีข้อมูล';
                if (!isEmpty(user.firstName)) {
                  name = `${user.firstName} - ${user.lastName}`;
                }
                let status = '';
                if (user.status === 'new') status = 'ใหม่';
                return (
                  <li
                    key={user.userId}
                    className={
                      user.userId === userSelected.userId ? 'active' : ''
                    }
                  >
                    <button
                      type="button"
                      onClick={() => {
                        handleSelectUser(user);
                      }}
                    >
                      <div className="id">
                        <span>{user.providerLoginId}</span>
                        <span className="time">
                          {moment(user.updatedAt).format('DD MMM YYYY HH:mm')}
                        </span>
                      </div>
                      <div className="name">{name}</div>
                      {!isEmpty(status) && (
                        <div className="tag">
                          <span className="new">{status}</span>
                        </div>
                      )}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="wrap-chat">
          {userSelected.userId && (
            <Chat userId={userSelected.userId} data={userSelected} />
          )}
        </div>
      </Style>
    </LayoutMenu>
  );
};

Message.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Message.defaultProps = {
  // children: ''
};

export default Message;

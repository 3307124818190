import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import join from 'lodash/join';
import { Row, Col } from 'antd';

import UpdatedAt from '../UpdatedAt';
import { getThaiDate } from '../../utils';
import { getProblemTypes } from '../../service';
import Style from './style';

const ActionIncident = (props) => {
  const { items, severityLevels } = props;
  const [problemTypes, setProblemTypes] = React.useState([]);

  const initPage = async () => {
    try {
      const resultProblemType = await getProblemTypes();
      setProblemTypes(get(resultProblemType, 'data', {}));
    } catch (err) {
      console.log('loadData err', err);
    }
  };

  React.useEffect(() => {
    initPage();
  }, []); // eslint-disable-line

  const renderSuppression = (data) => {
    const labelproblemType = map(
      get(data, 'context.problemTypeIds', []),
      (id) => {
        const dataInfo = find(problemTypes, (obj) => obj.id === id);
        return get(dataInfo, 'problemType');
      }
    );
    const severityLevel = find(
      severityLevels,
      (obj) => obj.value === get(data, 'context.severityLevelId')
    );
    return (
      <div className="assessment-preview item-detail" key={data.id}>
        <div className="wrap-content">
          <div className="title-item">สรุปรายละเอียดผู้เข้ารับบริการ</div>
          <Row>
            <Col md={6}>
              <div className="label">ประเภทกลุ่มปัญหา</div>
            </Col>
            <Col md={18} className="value">
              {join(labelproblemType, ', ')}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="label">ระดับความเสี่ยง</div>
            </Col>
            <Col md={18} className="value">
              {get(severityLevel, 'label', 'ไม่มีข้อมูล')}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="label">รายละเอียด</div>
            </Col>
            <Col md={18} className="value">
              {get(data, 'context.detail')}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="label">ข้อเสนอแนะ</div>
            </Col>
            <Col md={18} className="value">
              {get(data, 'context.suggestion')}
            </Col>
          </Row>
          <hr />
          <UpdatedAt
            className="time"
            user={{
              image: get(data, 'lastUpdatedByImageUrl'),
              name: get(data, 'actionBy'),
              role: get(data, 'lastUpdatedByRoles.0.name')
            }}
            date={get(data, 'updatedAt')}
          />
        </div>
      </div>
    );
  };

  const renderFollow = (data) => {
    return (
      <div className="render-follow item-detail" key={data.id}>
        <div className="wrap-content">
          <div className="title-item">การดำเนินการต่อ</div>
          <div className="content">
            ติดต่ออีกครั้ง {getThaiDate(get(data, 'context.nextFollowDate'))}
          </div>
          <hr />
          <UpdatedAt
            className="time"
            user={{
              image: get(data, 'lastUpdatedByImageUrl'),
              name: get(data, 'actionBy'),
              role: get(data, 'lastUpdatedByRoles.0.name')
            }}
            date={get(data, 'updatedAt')}
          />
        </div>
      </div>
    );
  };

  return (
    <Style>
      {map(items, (item) => {
        switch (get(item, 'actionName')) {
          case 'risk_assessment': {
            return renderSuppression(item);
          }
          case 'follow': {
            return renderFollow(item);
          }
          case 'send_crime_suppression_onsite':
          case 'close': {
            let title = 'ยุติการให้คำปรึกษา';
            if (get(item, 'actionName') === 'send_crime_suppression_onsite') {
              title = 'ส่งต่อกองปราบฯ เพื่อเตรียมลงพื้นที่';
            }
            return (
              <div className="item-detail" key={item.id}>
                <div className="wrap-content">
                  <div className="title-item">{title}</div>
                  <hr />
                  <UpdatedAt
                    className="time"
                    user={{
                      image: get(item, 'lastUpdatedByImageUrl'),
                      name: get(item, 'actionBy'),
                      role: get(item, 'lastUpdatedByRoles.0.name')
                    }}
                    date={get(item, 'updatedAt')}
                  />
                </div>
              </div>
            );
          }
          default: {
            return '';
          }
        }
      })}
    </Style>
  );
};

ActionIncident.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  severityLevels: PropTypes.arrayOf(PropTypes.shape())
};

ActionIncident.defaultProps = {
  items: [],
  severityLevels: []
};

export default ActionIncident;

import styled from '@emotion/styled';

export default styled.div`
  background: #fff;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  > .wrap-form {
    width: 450px;
    padding: 50px;
    box-shadow: 0px 1px 10px #ddd;
    margin: 40px 12px;
    .ant-input,
    .ant-input-password {
      background: #fff;
      border: none;
      border-bottom: 2px solid #ddd;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      &:focus {
        box-shadow: unset;
      }
    }
    .ant-input-password {
      .ant-input {
        border-bottom: none;
      }
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: unset;
    }
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
      box-shadow: unset;
    }
    .button-login {
      display: block;
      width: 100%;
      text-align: center;
      height: 50px;
      background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 30px;
      color: #ffffff;
      border: none;
      outline: none;
      margin-top: 40px;
      margin-bottom: 40px;
      font-weight: bold;
    }
    .or {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      span {
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 4px 10px;
      }
      &:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 50%;
        height: 2px;
        background: #ddd;
        left: 0;
        right: 0;
        transform: translateY(-50%);
      }
    }
    .social-login {
      button {
        display: block;
        width: 100%;
        text-align: center;
        height: 50px;
        border: 1px solid #ddd;
        border-radius: 30px;
        font-weight: bold;
        &.line {
          color: #fff;
          background: #00b900;
          border-color: #00b900;
        }
      }
    }
  }
  .wrap-footer {
    width: 100%;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown, Menu, Popconfirm } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Button from '../Button';
import Table from '../Table';
import * as Icon from '../Icons';

import { getIncidents, mapIncidentIncident } from '../../service';
import { getThaiDate, getSortValue } from '../../utils';
import Style from './style';

const CaseLinkMedicalRecord = (props) => {
  const { id } = props;
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const inputEl = React.useRef(null);
  const defaultPageLimit = 10;
  const [isLoading, setLoading] = React.useState(true);
  const [dataFilter, setDataFilter] = React.useState({
    page: 1,
    pageLimit: defaultPageLimit,
    sort: 'updatedAt|desc'
  });

  const [state, setState] = React.useState({
    total: 1,
    rows: []
  });

  const loadData = async (query = {}) => {
    setLoading(true);
    const { page, pageLimit = defaultPageLimit, sort = {} } = query;
    const pageSize = Number(pageLimit);
    try {
      const keyword = get(inputEl, 'current.value', '');
      const query = {
        offset: Number(page) * pageSize - pageSize,
        limit: pageSize,
        keyword,
        ...getSortValue(sort)
      };
      const res = await getIncidents(query);
      setState({
        ...state,
        total: get(res, 'meta.total', 1),
        rows: get(res, 'data', [])
      });
    } catch (err) {
      console.log('loadData err', err);
    }
    setLoading(false);
  };

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
    if (!isModalVisible) {
      loadData({
        page: 1,
        pageLimit: defaultPageLimit
      });
    }
  };

  const handleFilter = () => {
    loadData({
      page: 1,
      pageLimit: defaultPageLimit
    });
  };

  const handlePageChange = (page, pageLimit) => {
    const body = {
      ...dataFilter,
      page,
      pageLimit
    };
    setDataFilter(body);
    loadData(body);
  };

  const handleSortChange = (e) => {
    const body = {
      ...dataFilter,
      page: 1,
      pageLimit: 10,
      sort: e.key
    };
    setDataFilter(body);
    loadData(body);
  };

  const handleUpdate = async (value) => {
    console.log('value >>', value);
    try {
      setLoading(true);
      await mapIncidentIncident(value.id, {
        incidentId: id
      });
    } catch (err) {
      console.log('handleUpdate >>', err);
    }
    setLoading(false);
    setIsModalVisible(false);
  };

  const menuSort = (
    <Menu onClick={handleSortChange} selectedKeys={[dataFilter.sort]}>
      <Menu.Item key="caseNumber|asc">รหัสเคส (น้อย ไป มาก)</Menu.Item>
      <Menu.Item key="caseNumber|desc">รหัสเคส (มาก ไป น้อย)</Menu.Item>
      <Menu.Item key="updatedAt|asc">อัปเดตล่าสุด (เก่า ไป ใหม่)</Menu.Item>
      <Menu.Item key="updatedAt|desc">อัปเดตล่าสุด (ใหม่ ไป เก่า)</Menu.Item>
      <Menu.Item key="severityLevelId|asc">
        ระดับความเสี่ยง (น้อย ไป มาก)
      </Menu.Item>
      <Menu.Item key="severityLevelId|desc">
        ระดับความเสี่ยง (มาก ไป น้อย)
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'รหัสเคส',
      dataIndex: 'caseNumber',
      width: 140,
      key: 'caseNumber'
    },
    {
      title: 'ชื่อ - สกุล',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 240,
      render: (value, row) => {
        if (isEmpty('firstName')) return 'ไม่มีข้อมูล';
        return `${value} ${get(row, 'lastName', '')}`.trim();
      }
    },
    {
      title: 'เบอร์ติดต่อ',
      dataIndex: 'telephone',
      key: 'telephone',
      width: 140
    },
    {
      title: 'อัปเดตล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 220,
      render: (value) => getThaiDate(value)
    },
    {
      title: 'เชื่อมประวัติ',
      dataIndex: 'action',
      key: 'status',
      width: 140,
      render: (value, row) => {
        return (
          <Popconfirm
            onConfirm={() => {
              handleUpdate(row);
            }}
            title="ยืนยันการทำรายการ"
            okText="ยืนยัน"
            cancelText="ยกเลิก"
          >
            <Button primary size="sm" className="button-action">
              เชื่อมประวัติ
            </Button>
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <>
      <Button className="with-icon" size="sm" onClick={showModal}>
        <span>เชื่อมประวัติ</span>
        <Icon.Paperclip color="#EE6538" />
      </Button>
      <Modal
        title="เชื่อมประวัติผู้ป่วย"
        visible={isModalVisible}
        centered
        width={1200}
        footer={false}
        onCancel={showModal}
        className="main-modal"
      >
        <Style>
          <div className="header">
            <div className="input-search">
              <div className="icon">
                <Icon.Search />
              </div>
              <input
                ref={inputEl}
                type="text"
                placeholder="ค้นหา"
                autoComplete="off"
                onKeyPress={(event) => {
                  const keyCode = event.which || event.keyCode;
                  console.log('keyCode >', keyCode);
                  if (keyCode === 13) {
                    handleFilter();
                  }
                }}
              />
            </div>

            <div className="action">
              <Dropdown overlay={menuSort} trigger={['click']}>
                <Button>
                  <Icon.SortAmountDown color="#FF7A44" />
                  <span>เรียงตาม</span>
                </Button>
              </Dropdown>
            </div>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            loading={isLoading}
            dataSource={get(state, 'rows', [])}
            scroll={{ y: 400 }}
            pagination={{
              current: get(dataFilter, 'page', 1),
              pageSize: get(dataFilter, 'pageLimit', 10),
              total: get(state, 'total', 1),
              onChange: handlePageChange
            }}
          />
        </Style>
      </Modal>
    </>
  );
};

CaseLinkMedicalRecord.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

CaseLinkMedicalRecord.defaultProps = {};

export default CaseLinkMedicalRecord;

import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
import map from 'lodash/map';
import find from 'lodash/find';
import get from 'lodash/get';
import toString from 'lodash/toString';

import Style from './style';
import th from './icons/th.png';

const InputPhone = (props) => {
  const { value, onChange, disabled } = props;
  const options = [
    {
      label: '+66',
      value: '+66',
      img: th
    }
    // {
    //   label: '+26',
    //   value: '+26',
    //   img: th
    // }
  ];
  const getValueSelecct = find(options, (obj) => {
    return new RegExp(`^\\${obj.value}`, 'i').test(value);
  });
  const valueSelecct = get(getValueSelecct, 'value', '+66');

  const hancleInputChange = (e) => {
    if (`${e.target.value}`.length <= 10) {
      onChange(`${valueSelecct}${e.target.value}`);
    }
  };

  const hancleSelecctChange = (value) => {
    onChange(value);
  };

  const selectBefore = (
    <Select
      value={valueSelecct}
      disabled={disabled}
      onChange={hancleSelecctChange}
    >
      {map(options, (obj) => {
        return (
          <Select.Option value={obj.value} key={obj.value}>
            <img src={obj.img} alt="" /> {obj.label}
          </Select.Option>
        );
      })}
    </Select>
  );
  const valueInput = toString(value).replace(
    new RegExp(`^\\${valueSelecct}`, 'i'),
    ''
  );

  return (
    <Style className={`input-phone ${disabled ? 'disabled' : ''}`}>
      <Input
        addonBefore={selectBefore}
        placeholder="00000000"
        value={valueInput}
        onChange={hancleInputChange}
        autoComplete="off"
        type="number"
        disabled={disabled}
      />
    </Style>
  );
};

InputPhone.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

InputPhone.defaultProps = {
  value: '',
  onChange: () => null,
  disabled: false
};

export default InputPhone;

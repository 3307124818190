import React from 'react';

export default () => (
  <svg
    width="230"
    height="221"
    viewBox="0 0 230 221"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M110.395 220.814C171.364 220.814 220.789 171.383 220.789 110.407C220.789 49.4308 171.364 0 110.395 0C49.4254 0 0 49.4308 0 110.407C0 171.383 49.4254 220.814 110.395 220.814Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M45.4325 94.8495V81.1401H12.9758V69.6762L47.5731 25.8635H62.6754V67.2147H69.104V81.1611H62.6754V94.8704L45.4325 94.8495ZM29.5772 67.8562H48.3191V44.8446L29.5772 67.8562Z"
      fill="#FF937E"
    />
    <path
      d="M105.73 96.7813C98.0186 96.7813 91.8062 95.3866 87.0928 92.5974C82.3758 89.8064 78.7619 85.4781 76.8572 80.3385C74.7516 74.9412 73.6987 68.4329 73.6987 60.8135C73.6987 53.1941 74.7516 46.6858 76.8572 41.2885C78.7753 36.1476 82.3848 31.8122 87.0928 28.9947C91.8062 26.2054 98.0186 24.8108 105.73 24.8108C113.521 24.8108 119.752 26.2217 124.423 29.0435C129.108 31.8737 132.709 36.1922 134.652 41.3094C136.799 46.6648 137.871 53.1732 137.866 60.8344C137.861 68.4956 136.79 75.004 134.652 80.3594C132.729 85.4883 129.123 89.8127 124.423 92.6252C119.742 95.3913 113.511 96.7766 105.73 96.7813ZM105.73 81.9981C111.159 81.9981 114.927 80.1943 117.032 76.5869C119.138 72.9794 120.191 67.7123 120.191 60.7856C120.191 53.8635 119.138 48.5964 117.032 44.9843C114.927 41.3722 111.159 39.5684 105.73 39.5731C100.306 39.5731 96.5405 41.3768 94.4348 44.9843C92.3291 48.5918 91.2763 53.8589 91.2763 60.7856C91.2763 67.7169 92.3291 72.984 94.4348 76.5869C96.5405 80.1897 100.306 81.9911 105.73 81.9911V81.9981Z"
      fill="#FF937E"
    />
    <path
      d="M174.82 94.8495V81.1401H142.398V69.6762L176.995 25.8635H192.098V67.2147H198.526V81.1611H192.098V94.8704L174.82 94.8495ZM158.965 67.8562H177.714V44.8446L158.965 67.8562Z"
      fill="#FF937E"
    />
    <path
      d="M110.96 18.4931C110.96 18.4931 111.085 8.59807 97.5446 10.69C97.5446 10.69 93.1101 4.0376 86.2144 4.0376C79.8137 4.0376 76.8573 10.167 76.8573 10.167C76.8573 10.167 71.8093 10.167 71.6838 15.0483C70.5796 14.9767 69.4832 15.2762 68.5686 15.899C67.6541 16.5218 66.9738 17.4324 66.6357 18.4861L110.96 18.4931Z"
      fill="white"
    />
    <path
      d="M34.374 106.272C34.374 106.272 34.4437 100.923 27.1296 102.088C27.1296 102.088 24.7381 98.4966 21.0148 98.4966C17.5286 98.4966 15.9598 101.816 15.9598 101.816C15.9598 101.816 13.2336 101.816 13.1708 104.445C12.5746 104.405 11.9823 104.567 11.4883 104.903C10.9943 105.239 10.6269 105.731 10.4446 106.3L34.374 106.272Z"
      fill="white"
    />
    <path
      d="M230 102.757C230 102.757 230.223 85.1707 206.147 88.8875C206.147 88.8875 198.268 77.0679 186.011 77.0679C174.632 77.0679 169.381 88.0158 169.381 88.0158C169.381 88.0158 160.408 88.0158 160.192 96.6486C160.192 96.6486 153.408 96.0559 151.218 102.757H230Z"
      fill="white"
    />
    <path
      d="M79.5067 214.649C79.8353 216.719 79.6313 218.838 78.9141 220.807H131.207C128.746 215.04 124.632 206.609 124.235 204.378C122.799 196.122 156.266 157.657 151.86 152.302C147.453 146.946 128 132.198 128 132.198L101.505 127.505C101.505 127.505 98.8971 131.326 97.4329 129.172C95.9687 127.017 92.6637 134.241 92.6637 134.241"
      fill="#E3F1FF"
    />
    <path
      d="M98.9249 220.59L97.4537 206.895L87.0509 191.387L84.1643 162.469L87.2531 133.411C85.7819 133.886 84.5966 135.503 83.6623 137.372C83.6623 137.372 70.4146 142.295 72.5063 153.557C72.9038 155.747 73.3918 159.101 73.9008 163.068C73.9008 163.068 61.5945 180.857 75.4278 191.721C77.3243 201.951 79.1441 212.32 79.1441 212.32C79.4851 214.046 79.5252 215.818 79.2626 217.557C85.7102 219.167 92.2919 220.183 98.9249 220.59Z"
      fill="#FF7A44"
    />
    <path
      d="M124.807 201.072C126.445 197.272 137.357 180.153 140.286 175.676C143.556 181.387 146.477 188.228 149.308 193.36L152.794 199.908L172.317 191.763C173.579 191.61 168.182 179.211 157.312 160.628C153.979 154.91 151.037 149.875 148.994 146.451C145.843 135.545 134.192 135.545 134.192 135.545L126.857 153.327C126.466 153.417 126.076 153.501 125.685 153.564L126.25 154.415L96.8193 207.439L98.9111 220.59C101.17 220.73 103.443 220.807 105.73 220.807C114.182 220.811 122.606 219.831 130.831 217.885C128.153 213.213 123.433 204.252 124.807 201.072Z"
      fill="#FF7A44"
    />
    <path
      d="M125.915 97.7364C140.148 97.7364 151.686 86.1974 151.686 71.9634C151.686 57.7294 140.148 46.1904 125.915 46.1904C111.683 46.1904 100.145 57.7294 100.145 71.9634C100.145 86.1974 111.683 97.7364 125.915 97.7364Z"
      fill="#1A2E35"
    />
    <path
      d="M129.374 89.8777C130.686 87.6615 131.586 85.2257 132.03 82.6883C132.255 81.438 132.279 80.1597 132.1 78.9019C131.924 77.6509 131.461 76.4575 130.747 75.4153C130.013 74.4037 129.081 73.5519 128.007 72.9119L127.191 72.4447C126.905 72.3052 126.612 72.1867 126.327 72.0542C126.045 71.9142 125.754 71.7954 125.455 71.6986L124.563 71.3708C122.108 70.6438 119.588 70.1557 117.039 69.9134V69.8507C119.64 69.7707 122.238 70.0738 124.751 70.7502C126.016 71.123 127.224 71.6673 128.342 72.368C129.463 73.0836 130.426 74.021 131.172 75.1224C131.896 76.2357 132.354 77.5001 132.511 78.8182C132.662 80.1206 132.605 81.4385 132.344 82.7232C131.806 85.2614 130.816 87.6824 129.422 89.8707L129.374 89.8777Z"
      fill="#BABABA"
    />
    <path
      d="M102.99 73.0373C103.896 70.0179 105.265 67.1574 107.048 64.5579C108.805 61.8999 111.181 59.708 113.972 58.1704C116.829 56.6958 120.074 56.1428 123.259 56.5875C126.421 57.0154 129.47 58.0501 132.239 59.6348C133.623 60.4307 134.922 61.3652 136.116 62.4241C137.296 63.5076 138.357 64.7138 139.282 66.0223C141.073 68.68 142.329 71.6623 142.977 74.8015C143.586 77.9302 143.699 81.1352 143.312 84.299C142.988 87.4614 142.017 90.5233 140.46 93.2945L140.39 93.2526C141.099 91.8548 141.64 90.3778 142.001 88.8525C142.382 87.3396 142.638 85.798 142.768 84.2433C143.048 81.141 142.858 78.0141 142.203 74.9689C141.582 71.9276 140.372 69.0374 138.64 66.4616C137.765 65.185 136.758 64.0045 135.635 62.9401C134.495 61.889 133.252 60.9546 131.926 60.1508C129.238 58.5724 126.272 57.526 123.189 57.0687C120.093 56.5929 116.925 57.0925 114.125 58.4982C111.355 59.9613 108.984 62.077 107.215 64.6625C105.398 67.2197 103.987 70.0422 103.032 73.0303L102.99 73.0373Z"
      fill="#BABABA"
    />
    <path
      d="M151.741 71.2103C152.03 75.1345 151.513 79.0763 150.221 82.7928C148.922 86.5443 146.563 89.8385 143.43 92.2764C141.849 93.464 140.122 94.4436 138.291 95.1912C137.366 95.5359 136.423 95.8338 135.468 96.0838C134.986 96.2023 134.512 96.3488 134.031 96.4394L132.574 96.6974C128.68 97.2509 124.726 97.225 120.839 96.6207V96.5719C124.724 96.9159 128.636 96.7871 132.49 96.1884L133.92 95.9373C134.394 95.8467 134.861 95.7072 135.314 95.5887C136.258 95.3474 137.189 95.0565 138.103 94.717C139.909 94.0122 141.616 93.0745 143.179 91.9277C146.289 89.5687 148.65 86.3598 149.977 82.6882C151.328 79.0176 151.912 75.1087 151.692 71.2034L151.741 71.2103Z"
      fill="#293D42"
    />
    <path
      d="M118.636 87.0186C123.523 87.0186 127.484 83.0567 127.484 78.1696C127.484 73.2824 123.523 69.3206 118.636 69.3206C113.749 69.3206 109.788 73.2824 109.788 78.1696C109.788 83.0567 113.749 87.0186 118.636 87.0186Z"
      fill="#A5D3FF"
    />
    <path
      d="M105.646 55.981C107.732 54.8738 109.897 53.9228 112.124 53.1359C114.35 52.3329 116.631 51.692 118.95 51.2182C123.608 50.2001 128.432 50.2144 133.083 51.2601C135.414 51.8194 137.648 52.7218 139.714 53.9378C141.752 55.2007 143.598 56.7507 145.194 58.5401C148.234 62.2175 150.465 66.4944 151.741 71.0919H151.665C150.044 66.6898 147.653 62.6118 144.602 59.0492C143.032 57.3426 141.251 55.8429 139.302 54.5863C137.326 53.3656 135.178 52.4466 132.93 51.8598C128.357 50.7522 123.596 50.6618 118.985 51.5948C114.354 52.4419 109.867 53.9372 105.653 56.0367L105.646 55.981Z"
      fill="#BABABA"
    />
    <path
      d="M100.759 72.0681C100.468 68.1434 100.988 64.2009 102.286 60.4856C103.587 56.7351 105.945 53.4413 109.077 51.002C110.657 49.8129 112.384 48.8332 114.215 48.0872C115.129 47.7424 116.06 47.4445 117.004 47.1946C117.486 47.0761 117.96 46.9296 118.441 46.839L119.898 46.581C123.792 46.0275 127.746 46.0534 131.633 46.6577V46.7065C127.75 46.3625 123.84 46.4913 119.989 47.09L118.552 47.3411C118.078 47.4317 117.618 47.5712 117.158 47.6897C116.213 47.931 115.283 48.2219 114.369 48.5614C112.564 49.2695 110.858 50.2069 109.293 51.3507C106.179 53.7062 103.817 56.9163 102.495 60.5902C101.143 64.2605 100.561 68.17 100.787 72.075L100.759 72.0681Z"
      fill="#293D42"
    />
    <path
      d="M72.7506 199.643L93.5424 143.383L72.3601 149.757L61.0996 179.818L72.7506 199.643Z"
      fill="#FF7A44"
    />
    <path
      d="M88.7102 89.6616C88.7102 89.6616 82.8673 92.5624 86.1862 98.0852C89.5051 103.608 93.4794 104.012 88.0479 110.505C82.6163 116.997 91.7781 121.383 94.1976 120.964C94.1976 120.964 85.4611 116.662 90.279 111.983C95.097 107.304 96.2196 105.832 89.6585 101.014C83.0974 96.1955 90.5091 92.4927 90.5091 92.4927L91.0948 88.9573L88.7102 89.6616Z"
      fill="#1A2E35"
    />
    <path
      d="M53.151 129.074C53.151 129.074 46.4784 123.677 45.4813 121.947C44.4843 120.218 42.3786 116.215 40.8726 115.916L42.5599 122.303L44.7144 126.738L49.4277 130.127L53.151 129.074Z"
      fill="#FFBEB8"
    />
    <path
      d="M51.2264 127.498C51.2264 127.498 43.7311 126.048 42.0089 125.065C40.2867 124.081 36.4309 121.745 34.9597 122.275L39.694 126.885L43.8217 129.576L49.6018 130.05L51.2264 127.498Z"
      fill="#FFBEB8"
    />
    <path
      d="M53.4716 194.127L55.5633 148.209C55.6132 147.15 55.4277 146.094 55.0202 145.116C54.6127 144.138 53.9932 143.263 53.2066 142.553C49.1975 138.92 40.0845 130.545 35.2386 125.643C34.9388 125.337 32.6658 121.459 32.6658 121.459C32.6658 121.459 32.4218 120.699 33.2236 120.936C34.2276 121.236 33.5931 120.497 35.2107 121.041C38.5226 122.157 38.7667 123.677 40.6144 125.225C42.0316 126.324 43.7165 127.025 45.4951 127.254C46.534 127.352 47.2312 126.689 47.9703 127.052C49.624 127.881 51.3398 128.581 53.102 129.144C56.2187 129.688 55.2844 127.477 55.2844 127.477C55.2844 127.477 52.9138 122.171 52.7464 121.473C52.356 119.911 53.8341 116.732 54.7894 117.192C55.5633 117.561 57.2994 123.551 59.7258 127.254C61.0025 129.166 61.9259 131.292 62.4521 133.53L64.7809 143.934L72.1438 195.187L53.4716 194.127Z"
      fill="#FFBEB8"
    />
    <path
      d="M99.4549 114.382C99.7896 115.602 99.5525 123.74 99.3921 128.286C99.2967 129.962 98.9269 131.611 98.2974 133.167C97.0285 136.535 99.6083 144.806 102.111 146.87C103.018 147.616 101.895 153.341 102.111 153.313C102.446 153.271 103.722 147.734 105.284 147.128C112.612 144.213 120.916 132.052 120.916 132.052L120.839 108.566C120.839 108.566 98.9808 112.673 99.4549 114.382Z"
      fill="#FFBCB6"
    />
    <path
      d="M120.923 112.527V107.827C120.923 107.827 98.9389 112.764 99.406 114.479C99.7407 115.699 99.5664 116.606 99.7058 120.985C99.6919 120.985 114.55 130.476 120.923 112.527Z"
      fill="#CA6F67"
    />
    <path
      d="M127.156 100.742C129.52 88.9927 123.058 77.7813 112.722 75.7007C102.386 73.6201 92.0898 81.4582 89.7252 93.2075C87.3607 104.957 93.8229 116.168 104.159 118.249C114.495 120.329 124.791 112.491 127.156 100.742Z"
      fill="#FFBCB6"
    />
    <path
      d="M104.677 103.65C104.178 103.662 103.682 103.562 103.227 103.357C102.906 103.197 102.621 102.972 102.392 102.695C102.163 102.419 101.993 102.098 101.895 101.753C101.763 101.403 101.711 101.027 101.744 100.654C101.776 100.281 101.892 99.9204 102.083 99.5983C102.781 98.4617 104.391 98.0433 104.949 97.9317L106.079 88.5039C106.093 88.4421 106.13 88.3879 106.182 88.3516C106.235 88.3153 106.298 88.2996 106.361 88.3074C106.424 88.3151 106.482 88.3459 106.524 88.3937C106.566 88.4416 106.588 88.5032 106.588 88.5667L105.444 98.1827C105.436 98.238 105.411 98.2893 105.372 98.3293C105.333 98.3694 105.283 98.3962 105.228 98.4059C105.228 98.4059 103.255 98.7127 102.53 99.8772C102.378 100.135 102.288 100.425 102.265 100.723C102.242 101.022 102.287 101.321 102.397 101.6C102.473 101.877 102.606 102.136 102.789 102.358C102.971 102.581 103.199 102.762 103.457 102.89C104.677 103.475 106.392 102.834 106.406 102.827C106.438 102.815 106.472 102.809 106.506 102.809C106.539 102.81 106.573 102.817 106.604 102.831C106.635 102.844 106.663 102.864 106.687 102.888C106.71 102.913 106.729 102.942 106.741 102.973C106.753 103.005 106.759 103.039 106.759 103.073C106.758 103.106 106.751 103.14 106.737 103.171C106.724 103.202 106.704 103.23 106.679 103.254C106.655 103.277 106.626 103.296 106.595 103.308C105.979 103.527 105.331 103.642 104.677 103.65Z"
      fill="#1A2E35"
    />
    <path
      d="M126.647 94.9055C126.647 94.9055 137.601 89.055 136.876 98.7547C136.151 108.454 125.72 108.44 124.284 103.204L126.647 94.9055Z"
      fill="#FFBCB6"
    />
    <path
      d="M126.85 100.728C126.786 100.73 126.724 100.715 126.669 100.683C126.614 100.651 126.57 100.604 126.54 100.548C126.511 100.492 126.499 100.428 126.504 100.365C126.509 100.302 126.533 100.242 126.571 100.191L127.366 99.0405C128.009 98.0684 128.856 97.2476 129.848 96.6348C131.187 95.8329 132.295 95.6794 133.139 96.1676C134.708 97.088 134.652 99.9192 134.652 100.038C134.648 100.127 134.611 100.211 134.548 100.273C134.484 100.335 134.399 100.371 134.31 100.372C134.265 100.372 134.22 100.362 134.178 100.343C134.137 100.325 134.099 100.298 134.068 100.265C134.037 100.232 134.013 100.193 133.997 100.15C133.981 100.108 133.974 100.062 133.976 100.017C133.976 100.017 134.017 97.4785 132.797 96.7603C132.191 96.4047 131.319 96.565 130.21 97.2205C129.376 97.7309 128.651 98.4017 128.077 99.194C128.801 98.7565 129.636 98.5363 130.482 98.5594C130.696 98.5816 130.904 98.6467 131.092 98.751C131.28 98.8552 131.445 98.9964 131.577 99.1661C131.631 99.2387 131.655 99.3295 131.643 99.4193C131.631 99.5091 131.585 99.5909 131.514 99.6472C131.479 99.6758 131.438 99.6969 131.395 99.7092C131.351 99.7216 131.305 99.7249 131.26 99.7191C131.215 99.7132 131.172 99.6982 131.133 99.6751C131.094 99.6519 131.06 99.6211 131.033 99.5845C130.957 99.4865 130.861 99.405 130.752 99.3451C130.643 99.2852 130.523 99.248 130.399 99.2358C129.325 99.0963 127.61 100.219 127.038 100.63C126.988 100.683 126.922 100.717 126.85 100.728Z"
      fill="#1A2E35"
    />
    <path
      d="M97.649 101.83C96.1381 101.946 94.626 101.62 93.2971 100.892C91.9682 100.164 90.8799 99.0642 90.1649 97.728C89.4499 96.3917 89.1391 94.8763 89.2703 93.3665C89.4016 91.8566 89.9692 90.4176 90.9041 89.2248C91.8389 88.032 93.1006 87.1371 94.5352 86.649C95.9699 86.1609 97.5155 86.1008 98.9837 86.4761C100.452 86.8514 101.779 87.6457 102.804 88.7624C103.828 89.879 104.506 91.2696 104.754 92.7647C105.087 94.8094 104.597 96.9027 103.391 98.587C102.185 100.271 100.361 101.41 98.3184 101.753C98.0953 101.788 97.8722 101.816 97.649 101.83ZM96.5125 87.2698C96.3243 87.2698 96.129 87.3117 95.9338 87.3396C94.6083 87.5595 93.3778 88.1676 92.398 89.0871C91.4182 90.0066 90.7332 91.1961 90.4296 92.5052C90.1259 93.8142 90.2174 95.1838 90.6924 96.4408C91.1673 97.6978 92.0044 98.7857 93.0978 99.5667C94.1911 100.348 95.4916 100.787 96.8345 100.829C98.1775 100.87 99.5026 100.513 100.642 99.8008C101.782 99.089 102.685 98.0551 103.237 96.83C103.789 95.6048 103.965 94.2435 103.743 92.9181C103.465 91.2404 102.569 89.7279 101.23 88.6795C99.891 87.6311 98.2077 87.1231 96.5125 87.2559V87.2698Z"
      fill="white"
    />
    <path
      d="M114.745 102.346C113.233 102.465 111.718 102.141 110.386 101.414C109.054 100.686 107.962 99.5866 107.245 98.2492C106.527 96.9118 106.214 95.3944 106.344 93.8822C106.474 92.37 107.042 90.9284 107.978 89.7334C108.913 88.5383 110.177 87.6416 111.613 87.1526C113.05 86.6636 114.598 86.6035 116.068 86.9797C117.539 87.3558 118.868 88.1519 119.893 89.2708C120.919 90.3896 121.596 91.7829 121.843 93.2805C122.18 95.3248 121.691 97.4192 120.484 99.1033C119.278 100.787 117.452 101.924 115.408 102.262C115.206 102.297 114.969 102.325 114.745 102.346ZM113.602 87.7856C113.41 87.7985 113.219 87.8218 113.03 87.8553C111.704 88.0739 110.473 88.6811 109.492 89.6C108.511 90.5189 107.825 91.7081 107.52 93.0173C107.216 94.3264 107.306 95.6965 107.781 96.9541C108.255 98.2116 109.092 99.3002 110.185 100.082C111.278 100.864 112.579 101.303 113.922 101.345C115.266 101.387 116.591 101.03 117.731 100.318C118.871 99.6062 119.774 98.5722 120.326 97.3467C120.879 96.1212 121.055 94.7595 120.832 93.4339C120.555 91.7561 119.658 90.2436 118.319 89.1952C116.98 88.1468 115.297 87.6388 113.602 87.7716V87.7856Z"
      fill="white"
    />
    <path
      d="M139.442 171.569C139.382 171.57 139.324 171.549 139.278 171.511C139.232 171.472 139.201 171.419 139.191 171.36C137.036 161.235 139.351 154.896 141.673 151.354C143.064 149.119 145.012 147.285 147.328 146.033C147.389 146.01 147.456 146.011 147.517 146.035C147.577 146.06 147.626 146.106 147.654 146.166C147.681 146.225 147.685 146.293 147.665 146.355C147.644 146.417 147.601 146.469 147.544 146.5C147.425 146.556 135.691 152.26 139.693 171.255C139.705 171.323 139.691 171.392 139.654 171.449C139.616 171.506 139.557 171.547 139.491 171.562L139.442 171.569Z"
      fill="#1A2E35"
    />
    <path
      d="M102.697 173.284C101.386 173.284 100.306 171.98 100.292 170.377C100.281 169.965 100.351 169.556 100.498 169.172C100.645 168.788 100.866 168.437 101.149 168.138C101.342 167.934 101.575 167.77 101.832 167.658C102.09 167.545 102.367 167.486 102.648 167.483C103.959 167.483 105.04 168.794 105.054 170.39C105.077 171.222 104.771 172.029 104.203 172.636C104.007 172.838 103.773 172.999 103.515 173.111C103.256 173.222 102.979 173.281 102.697 173.284ZM102.634 167.999C102.421 168.002 102.21 168.048 102.015 168.134C101.82 168.22 101.644 168.345 101.498 168.501C101.026 169.009 100.775 169.684 100.801 170.377C100.801 171.694 101.665 172.768 102.697 172.775C102.91 172.772 103.119 172.726 103.313 172.64C103.507 172.553 103.682 172.429 103.827 172.273C104.299 171.765 104.549 171.09 104.524 170.397C104.524 169.08 103.666 168.006 102.634 167.999Z"
      fill="#1A2E35"
    />
    <path
      d="M102.132 186.094C100.814 186.094 99.7407 184.783 99.7268 183.186C99.6977 182.356 99.9986 181.549 100.563 180.941C100.759 180.739 100.991 180.579 101.249 180.467C101.506 180.356 101.782 180.297 102.063 180.292C103.38 180.292 104.454 181.603 104.468 183.2C104.48 183.611 104.412 184.02 104.266 184.404C104.12 184.788 103.899 185.14 103.617 185.438C103.426 185.641 103.196 185.804 102.941 185.916C102.686 186.029 102.411 186.089 102.132 186.094ZM102.07 180.808C101.857 180.811 101.646 180.856 101.451 180.941C101.256 181.026 101.08 181.149 100.933 181.303C100.463 181.813 100.213 182.487 100.236 183.179C100.236 184.497 101.093 185.571 102.125 185.578C102.34 185.575 102.552 185.53 102.748 185.443C102.945 185.357 103.122 185.232 103.269 185.076C103.502 184.826 103.684 184.532 103.804 184.211C103.923 183.89 103.978 183.549 103.966 183.207C103.966 181.889 103.108 180.808 102.076 180.808H102.07Z"
      fill="#1A2E35"
    />
    <path
      d="M122.82 95.031C124.108 95.4011 125.275 96.1061 126.201 97.0741C126.644 97.566 126.97 98.151 127.156 98.7858C127.342 99.4207 127.382 100.089 127.275 100.742C127.026 102.077 126.491 103.343 125.706 104.452C124.92 105.578 124.055 106.647 123.12 107.652C122.195 108.692 121.419 109.853 120.812 111.104C120.184 112.367 120 113.804 120.289 115.184C120.458 115.855 120.692 116.509 120.986 117.136C121.313 117.738 121.475 118.417 121.453 119.103C121.308 119.777 120.918 120.373 120.358 120.776C119.818 121.202 119.242 121.58 118.636 121.906C119.249 121.591 119.832 121.222 120.379 120.804C120.957 120.4 121.365 119.796 121.523 119.109C121.551 118.413 121.397 117.721 121.077 117.101C120.796 116.475 120.574 115.823 120.414 115.156C120.157 113.806 120.358 112.41 120.986 111.188C121.608 109.966 122.391 108.833 123.315 107.82C124.275 106.822 125.162 105.757 125.971 104.633C126.8 103.49 127.374 102.182 127.652 100.798C127.775 100.088 127.744 99.3606 127.561 98.664C127.363 97.9682 127.013 97.3251 126.536 96.7813C125.569 95.7229 124.333 94.9468 122.959 94.5359L122.82 95.031Z"
      fill="#293D42"
    />
    <path
      d="M123.573 94.3963C123.573 94.3963 129.074 97.1228 125.95 102.318C122.827 107.513 119.089 107.896 124.2 114.005C129.311 120.113 120.714 124.235 118.413 123.823C118.413 123.823 126.634 119.779 122.101 115.372C117.569 110.965 116.524 109.584 122.687 105.051C128.851 100.519 121.878 97.0321 121.878 97.0321L121.335 93.7129L123.573 94.3963Z"
      fill="#1A2E35"
    />
    <path
      d="M100.195 95.1939C101.069 95.1085 101.678 94.0295 101.557 92.7838C101.435 91.5381 100.628 90.5974 99.7541 90.6828C98.8802 90.7682 98.2705 91.8472 98.3922 93.093C98.5139 94.3387 99.3209 95.2793 100.195 95.1939Z"
      fill="#1A2E35"
    />
    <path
      d="M111.728 95.1951C112.602 95.1098 113.212 94.0307 113.09 92.785C112.968 91.5393 112.161 90.5986 111.288 90.684C110.414 90.7694 109.804 91.8485 109.926 93.0942C110.047 94.3399 110.854 95.2805 111.728 95.1951Z"
      fill="#1A2E35"
    />
    <path
      d="M125.664 153.536C126.236 153.369 124.967 153.648 125.664 153.536V153.536Z"
      fill="#1A2E35"
    />
    <path
      d="M55.3263 153.815C55.3263 153.815 41.6255 153.285 55.5146 149.764L55.3263 153.815Z"
      fill="#1A2E35"
    />
    <path
      d="M49.4417 152.706C49.4417 152.706 50.4178 153.599 55.3264 153.808L66.0221 152.623L68.0232 151.43L72.7505 199.643C72.7505 199.643 64.8089 212.509 59.3355 209.175L50.8013 198.646L49.4417 152.706Z"
      fill="#FF7A44"
    />
    <path
      d="M196.184 125.831C196.184 125.831 205.08 122.645 206.712 121.201C208.344 119.758 212.018 116.362 213.684 116.599L209.613 122.631L205.764 126.459L199.628 128.265L196.184 125.831Z"
      fill="#FFBEB8"
    />
    <path
      d="M198.77 124.897C198.77 124.897 207.095 126.118 209.229 125.72C211.363 125.323 216.264 124.325 217.596 125.371L211 128.44L205.729 129.744L199.572 128.133L198.77 124.897Z"
      fill="#FFBEB8"
    />
    <path
      d="M172.24 193.36L186.715 144.848C187.049 143.73 187.626 142.701 188.405 141.833C189.184 140.965 190.145 140.28 191.219 139.827C196.706 137.505 209.229 132.107 216.041 128.767C216.473 128.558 220.224 125.364 220.224 125.364C220.224 125.364 220.754 124.667 219.834 124.625C218.677 124.569 219.604 124.033 217.742 124.012C213.886 123.97 213.085 125.455 210.602 126.389C208.741 127.016 206.746 127.136 204.822 126.738C203.707 126.459 203.219 125.518 202.319 125.622C200.302 125.889 198.266 125.992 196.232 125.929C192.788 125.371 194.566 123.405 194.566 123.405C194.566 123.405 198.958 118.754 199.391 118.084C200.353 116.599 199.976 112.757 198.819 112.889C197.878 112.994 193.896 118.593 190.034 121.557C188.004 123.091 186.27 124.981 184.916 127.136L178.71 137.079L152.445 187.698L172.24 193.36Z"
      fill="#FFBEB8"
    />
    <path
      d="M184.93 150.768C184.93 150.768 199.37 155.189 186.206 146.486L184.93 150.768Z"
      fill="#1A2E35"
    />
    <path
      d="M191.47 151.723C191.47 151.723 190.132 152.302 184.951 150.74L173.969 145.517L150.193 192.063C150.193 192.063 153.777 208.325 160.652 206.846L169.835 201.233C172.133 199.813 173.899 197.676 174.862 195.152L191.47 151.723Z"
      fill="#FF7A44"
    />
    <path
      d="M101.045 144.875C99.8759 141.657 99.3408 138.242 99.4688 134.82L99.2039 122.373L95.0204 128.649C95.0204 128.649 90.3977 146.026 89.8747 148.822C89.3518 151.618 89.1775 159.631 89.1775 159.631L102.146 147.121L101.045 144.875Z"
      fill="#1A2E35"
    />
    <path
      d="M121.125 121.536C121.125 121.536 108.226 145.489 105.284 147.128C105.949 148.161 106.532 149.245 107.027 150.37L113.428 163.208L119.563 145.866L123.845 130.804L121.125 121.536Z"
      fill="#1A2E35"
    />
    <path
      d="M113.267 163.215C113.203 163.216 113.141 163.199 113.086 163.165C113.032 163.132 112.988 163.084 112.96 163.027L105.291 147.881L102.425 153.459C102.391 153.528 102.334 153.583 102.264 153.614C102.194 153.646 102.116 153.653 102.042 153.634C101.967 153.617 101.901 153.575 101.853 153.516C101.805 153.456 101.778 153.382 101.777 153.306L101.728 147.79L89.4075 159.868C89.3589 159.916 89.297 159.948 89.2299 159.96C89.1628 159.973 89.0935 159.965 89.031 159.937C88.9681 159.911 88.9145 159.866 88.8773 159.809C88.84 159.752 88.8207 159.685 88.8218 159.617C88.8218 159.261 88.9752 150.991 89.6794 148.034C90.3837 145.078 94.6368 128.718 94.6857 128.558C94.691 128.52 94.7053 128.484 94.7275 128.453L98.911 122.178C98.9527 122.116 99.0135 122.07 99.0839 122.046C99.1544 122.022 99.2308 122.022 99.3014 122.045C99.3724 122.067 99.4343 122.111 99.4772 122.172C99.5201 122.232 99.5417 122.306 99.5385 122.38C99.5385 122.589 98.9598 143.265 102.327 146.786C102.381 146.851 102.411 146.932 102.411 147.016L102.453 151.939L104.991 146.988C105.029 146.916 105.091 146.86 105.165 146.828C106.643 146.284 113.497 134.109 117.186 127.575C120.763 121.215 120.79 121.215 121.104 121.215C121.166 121.214 121.226 121.228 121.28 121.258C121.334 121.287 121.379 121.33 121.411 121.383C121.655 121.794 123.586 128.642 124.2 130.734C124.214 130.789 124.214 130.846 124.2 130.901C121.411 143.258 113.686 162.824 113.609 163.02C113.584 163.081 113.541 163.134 113.487 163.172C113.433 163.211 113.368 163.233 113.302 163.236L113.267 163.215ZM105.73 147.253L113.225 162.029C114.62 158.41 121.021 141.807 123.489 130.81C122.708 128.021 121.502 123.774 121.014 122.254C120.421 123.21 119.138 125.497 117.792 127.889C112.514 137.261 107.627 145.768 105.73 147.253ZM95.3132 128.788C95.0343 129.855 91.0112 145.356 90.3279 148.195C89.7701 150.538 89.5609 156.562 89.5051 158.787L101.581 146.974C98.7227 143.23 98.7576 127.958 98.8343 123.537L95.3132 128.788Z"
      fill="#1A2E35"
    />
    <path
      d="M96.5056 187.37C96.4418 187.37 96.3801 187.347 96.3324 187.305C96.2848 187.262 96.2546 187.203 96.2477 187.14C96.2477 187.056 95.3691 178.737 95.3691 172.141C95.3691 166.43 101.644 153.738 101.881 153.201C101.895 153.168 101.915 153.139 101.94 153.114C101.966 153.089 101.996 153.07 102.029 153.057C102.063 153.045 102.098 153.04 102.134 153.041C102.169 153.043 102.204 153.052 102.236 153.068C102.267 153.084 102.295 153.106 102.318 153.134C102.341 153.161 102.358 153.193 102.368 153.227C102.378 153.261 102.381 153.297 102.376 153.332C102.372 153.367 102.36 153.401 102.342 153.431C102.279 153.557 95.8851 166.555 95.8851 172.141C95.8851 178.709 96.7497 187.007 96.7636 187.091C96.7695 187.159 96.7486 187.225 96.7056 187.278C96.6626 187.33 96.6008 187.363 96.5335 187.37H96.5056Z"
      fill="#1A2E35"
    />
    <path
      d="M153.959 193.053C153.893 193.053 153.829 193.028 153.781 192.983C153.733 192.937 153.704 192.875 153.701 192.809C153.45 185.453 161.461 169.484 161.803 168.807C161.833 168.747 161.885 168.7 161.949 168.678C162.013 168.656 162.083 168.66 162.144 168.689C162.205 168.72 162.252 168.774 162.274 168.839C162.296 168.904 162.292 168.975 162.263 169.038C162.179 169.205 153.966 185.571 154.217 192.788C154.218 192.856 154.194 192.921 154.148 192.97C154.103 193.02 154.04 193.05 153.973 193.053H153.959Z"
      fill="#1A2E35"
    />
    <path d="M107.341 93.8035H103.827V95.0656H107.341V93.8035Z" fill="white" />
    <path
      d="M91.0671 88.1275C91.0671 88.1275 93.2356 78.4487 104.559 74.8296C115.882 71.2105 128.551 82.005 128.167 93.8037L125.567 100.345C125.567 100.345 125.086 88.6854 118.35 86.7329C111.615 84.7804 105.939 70.0041 91.0671 88.1275Z"
      fill="#1A2E35"
    />
    <path
      d="M93.9537 73.7138C98.4788 73.8882 104.322 76.0289 109.502 83.9435C109.502 83.9435 100.982 78.2046 90.6767 89.4872C85.7332 85.4079 87.316 73.4558 93.9537 73.7138Z"
      fill="white"
    />
    <path
      d="M109.502 83.9434C109.502 83.9434 117.367 96.9694 124.507 97.8411L125.901 98.4826C125.901 98.4826 127.805 94.6822 127.484 92.904C127.163 91.1259 123.468 85.0661 123.085 84.6198C122.701 84.1736 120.623 81.4749 118.525 80.1082C116.574 78.8301 114.491 77.7663 112.312 76.9354C110.149 76.1415 107.9 75.6032 105.612 75.3315L101.63 75.9661C101.63 75.9661 106.839 79.5712 109.502 83.9434Z"
      fill="#1A2E35"
    />
    <path
      d="M93.9537 73.7138C98.4788 73.8882 104.322 76.0289 109.502 83.9435C109.502 83.9435 100.982 78.2046 90.6767 89.4872C85.7332 85.4079 87.316 73.4558 93.9537 73.7138Z"
      fill="#424A4C"
    />
    <path
      d="M106.497 108.782C107.548 108.782 108.4 108.086 108.4 107.227C108.4 106.368 107.548 105.672 106.497 105.672C105.446 105.672 104.594 106.368 104.594 107.227C104.594 108.086 105.446 108.782 106.497 108.782Z"
      fill="#1A2E35"
    />
    <path
      d="M65.5339 149.213C65.5339 149.213 70.9167 151.13 66.022 152.623L65.5339 149.213Z"
      fill="#1A2E35"
    />
    <path
      d="M75.9996 193.946C75.9641 193.946 75.929 193.939 75.8965 193.924C75.8641 193.91 75.835 193.889 75.8114 193.862C63.8397 180.774 71.1538 168.654 74.8561 164.045C74.9296 163.964 74.9971 163.877 75.0583 163.787C75.5464 163.131 75.142 154.526 74.5145 146.758C74.5116 146.725 74.5156 146.691 74.526 146.659C74.5365 146.627 74.5533 146.597 74.5754 146.572C74.5975 146.547 74.6245 146.526 74.6548 146.511C74.685 146.497 74.7179 146.488 74.7515 146.486C74.7847 146.482 74.8183 146.485 74.8503 146.495C74.8823 146.505 74.9119 146.521 74.9374 146.542C74.9629 146.564 74.9837 146.591 74.9985 146.62C75.0133 146.65 75.0218 146.683 75.0235 146.716C75.2466 149.429 76.2925 162.985 75.4767 164.094L75.2605 164.373C71.6418 168.87 64.4881 180.725 76.1949 193.514C76.229 193.551 76.2514 193.597 76.2594 193.647C76.2674 193.697 76.2606 193.748 76.2398 193.794C76.219 193.84 76.1852 193.879 76.1425 193.906C76.0998 193.933 76.0501 193.947 75.9996 193.946Z"
      fill="#1A2E35"
    />
    <path
      d="M123.844 130.803C123.844 130.803 118.65 153.55 109.3 171.513C103.629 182.45 99.6333 194.178 97.4468 206.302L122.213 180.55L120.784 171.248L134.15 135.538L122.645 125.113L123.844 130.803Z"
      fill="#A93C1E"
    />
    <path
      d="M89.7073 187.984C87.3576 175.369 86.8626 165.153 89.7073 152.644C90.9206 147.302 91.5481 139.102 93.8908 133.551C94.5131 131.798 95.5913 130.243 97.0145 129.046L99.5385 123.872L83.7878 135.727L79.6043 163.975L74.6887 167.761L86.3118 192.168C87.811 195.314 89.7476 198.232 92.064 200.835L97.2446 206.728C97.2446 206.728 96.3103 205.047 93.5701 201.093C91.569 198.213 90.7602 193.639 89.7073 187.984Z"
      fill="#A93C1E"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="130.707"
        y1="231.978"
        x2="130.184"
        y2="-98.7413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE2BF" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

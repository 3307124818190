import React from 'react';
import PropTypes from 'prop-types';

import MainMenu from '../MainMenu';
import Style from './style';

const Layout = (props) => {
  const { children } = props;
  return <Style>{children}</Style>;
};

Layout.propTypes = {
  children: PropTypes.any
};

Layout.defaultProps = {
  children: ''
};

export default Layout;

const LayoutMenu = (props) => {
  const { children, isBackground } = props;
  return (
    <Style className={!isBackground ? 'not-background' : ''}>
      <MainMenu />
      <div className="children">{children}</div>
    </Style>
  );
};

LayoutMenu.propTypes = {
  children: PropTypes.any,
  isBackground: PropTypes.bool,
};

LayoutMenu.defaultProps = {
  children: '',
  isBackground: true
};

export { LayoutMenu };

import styled from '@emotion/styled';

export default styled.div`
  label: component-breadcrumb;
  display: flex;
  align-items: center;
  > button {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    margin-right: 27px;
  }
  span {
    color: #393f4d;
    font-family: Kanit;
    font-weight: 500;
    font-size: 28px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import Style from './style';

const Breadcrumb = (props) => {
  const { title, onBack, className } = props;
  return (
    <Style className={className}>
      <button onClick={onBack}>
        <svg
          width="15"
          height="26"
          viewBox="0 0 15 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9394 24.4294L1.33331 13.0008L13.9394 1.57227"
            stroke="#393939"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <span>{title}</span>
    </Style>
  );
};

Breadcrumb.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.any,
  className: PropTypes.string
};

Breadcrumb.defaultProps = {
  title: '',
  className: '',
  onBack: () => null
};

export default Breadcrumb;

import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import toLower from 'lodash/toLower';
import { toast } from 'react-toastify';

export { default as connectRedux } from './connectRedux';

const getErrorMessage = (err) => {
  let message = err;
  if (!isString(err)) {
    message = toString(err);
  }
  return message;
};

export const alert = {
  info: (err) => toast.info(getErrorMessage(err)),
  success: (err) => toast.success(getErrorMessage(err)),
  warn: (err) => toast.warn(getErrorMessage(err)),
  error: (err) => toast.error(getErrorMessage(err))
};

export const getThaiDate = (date, format = 'DD MMMM YYYY เวลา HH:mm น.') => {
  if (isEmpty(date) || date == null) return '';
  moment.updateLocale('th', {
    months: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม'
    ]
  });
  return moment(date).locale('th').add(543, 'year').format(format);
};

export const getDateRange = (start, end) => {
  const startToFormat = getThaiDate(start);
  const endToFormat = getThaiDate(end);
  const value = `${startToFormat} - ${endToFormat}`;
  if (value.replace(/\s/gi, '') === '-') return '';
  return value;
};

export const dateToFormat = (value, defaultValue = undefined) => {
  if (get(value, 'format')) return value.format();
  return defaultValue;
};

export const stringToDate = (value) => {
  if (isEmpty(value) || value == null) return undefined;
  return moment(value);
};

export const getLabelGender = (value, defaultValue = '') => {
  if (/^male$/i.test(value)) return 'ชาย';
  if (/^female$/i.test(value)) return 'หญิง';
  if (/^other$/i.test(value)) return 'อื่นๆ';
  return defaultValue;
};

export const getSortValue = (data = '') => {
  if (isEmpty(data)) return {};

  const values = data.split('|');
  return {
    sortBy: get(values, '0', ''),
    sortDirection: get(values, '1', 'ASC')
  };
};

export const optionOccurencePlace = [
  // {
  //   label: 'เลือก',
  //   value: ''
  // },
  {
    label: 'ในเคหสถาน',
    value: 'ในเคหสถาน'
  },
  {
    label: 'นอกเคหสถาน',
    value: 'นอกเคหสถาน'
  }
];

export const optionReportedChannel = [
  // {
  //   label: 'เลือก',
  //   value: ''
  // },
  {
    label: 'Influencer',
    value: 'Influencer'
  },
  {
    label: 'กองปราบฯ',
    value: 'กองปราบฯ'
  },
  {
    label: 'ทีมนักจิต',
    value: 'ทีมนักจิต'
  },
  {
    label: 'ทีมติดตาม',
    value: 'ทีมติดตาม'
  },
  {
    label: 'ทีมบริหาร',
    value: 'ทีมบริหาร'
  },
  {
    label: 'อื่น ๆ',
    value: 'อื่น ๆ'
  }
];

export const getMonthName = (index = 0) => {
  return get(
    [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    index,
    ''
  );
};

export const getTimeFormat = (date) => {
  const now = moment(); // todays date
  const end = moment(date); // another date
  const duration = moment.duration(now.diff(end));
  const hours = duration.asHours();

  const days = {
    sunday: 'วันอาทิตย์',
    monday: 'วันจันทร์',
    tuesday: 'วันอังคาร',
    wednesday: 'วันพุธ',
    thursday: 'วันพฤหัสบดี',
    friday: 'วันศุกร์',
    saturday: 'วันเสาร์'
  };
  const months = [
    '',
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ];
  if (hours < 0.05) {
    return 'เมื่อสักครู่';
  }
  /* ถ้าน้อยกว่า ชั่วโมง ให้แสดงเป็รนาที */
  if (hours < 1) {
    return `${Math.floor(60 * hours)} นาที`;
  }
  /* 5ถ้าอยู่ใช่ช่วง 1 วัน ให้ แสดงเป็น จำนวน ชั่วโมง */
  if (hours >= 1 && hours < 24) {
    return `${Math.floor(hours)} ชั่วโมง`;
  }
  const day = hours / 24;
  if (day < 7) {
    return days[toLower(end.format('dddd'))];
  }

  return `${end.format('DD')} ${months[end.format('M')]} ${
    Number(end.format('YYYY')) + 543
  }`;
};

export const getSeverityLevel = (id) => {
  const options = ['', 'น้อย', 'ปานกลาง', 'สูง'];
  return options[Number(id)];
};

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

let store;

function initStore(initialState) {
  const middlewares = [thunk];

  if (process.env.NODE_ENV === 'development') middlewares.push(createLogger());

  const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

  return store;
}

export const configureStore = (initialState) => {
  let initstore = store ?? initStore(initialState);
  if (initialState && store) {
    initstore = initStore({
      ...store.getState(),
      ...initialState,
    });
  }
  if (typeof window === 'undefined') return initstore;

  if (!store) store = initstore;
  return initstore;
};

export default configureStore;

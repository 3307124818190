import styled from '@emotion/styled';

export default styled.div`
  label: page-incident-detail;
  font-family: Kanit;
  .title-breadcrumb {
    > span {
      color: #ff7a44;
    }
  }
  .container {
    > .time {
      margin: 10px 12px 30px 54px;
      > div .date-time {
        color: rgba(57, 57, 57, 0.7);
        font-weight: 300;
        font-size: 20px;
        b {
          font-weight: 300;
        }
      }
    }
    > .content {
      background: #ffffff;
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 20px;
      padding: 42px;
      .label {
        text-align: right;
        color: #393f4d;
        font-weight: 500;
        font-size: 20px;
      }
      .value {
        color: #393f4d;
        font-weight: 300;
        font-size: 20px;
      }
      section.info {
        border-bottom: 1px solid #e5e5e5;
        .title {
          font-weight: 500;
          font-size: 24px;
          margin-bottom: 40px;
        }
        .ant-row {
          margin-bottom: 40px;
        }
        ul.images {
          margin: 0 -16px;
          > li {
            display: inline-block;
            margin: 0 16px 16px 16px;
          }
        }
      }
      section.detail {
        margin-top: 14px;
        .user-update {
          margin-bottom: 36px;
        }
        .wrap-form {
          background: #ffffff;
          border: 2px solid #ffe0c2;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 30px;
          /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
          margin-bottom: 20px;
          > .head {
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid #e5e5e5;
            padding-bottom: 20px;
            margin-bottom: 20px;
            .title {
              font-weight: 500;
              font-size: 24px;
              color: #393f4d;
            }
            .wrap-button button {
              margin-left: 10px;
            }
          }
          .ant-row {
            margin-bottom: 14px;
          }
        }
      }
      hr {
        border: none;
        border-bottom: 3px solid #ff9376;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      section.officer {
        background: #f2f6fb;
        border-radius: 20px;
        padding: 30px;
        .title {
          font-weight: 500;
          font-size: 24px;
          margin-bottom: 40px;
        }
      }
    }
    > .content section.officer {
      ul.steps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -10px;
        padding: 0;
        li {
          margin: 0 10px;
          padding: 0;
          flex: 1 1 auto;
          button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background: #feecd7;
            border: 2px solid #ffe0c2;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px #bed9f1;
            border-radius: 16px;
            padding: 20px;
            width: 100%;
            cursor: pointer;
            &:disabled {
              opacity: 0.5;
              pointer-events: none;
            }
            > span {
              margin-left: 10px;
            }
            &.active {
              background: #ff937e;
              color: #fff;
              pointer-events: none;
            }
          }
        }
      }
      hr {
        border-bottom: 2px solid #e5e5e5;
      }
      .wrap-button {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        button {
          margin: 0 16px;
          width: 250px;
        }
      }
    }
  }
  section.risk-assessment {
    h3 {
      margin-bottom: 27px;
    }
  }
  section.action-type {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #ff937e;
    box-sizing: border-box;
    border-radius: 10px;
    h3 {
      margin-bottom: 28px;
    }
    .ant-radio-group {
      display: block;
    }
    .ant-radio-wrapper {
      display: block;
      margin-bottom: 40px;
    }
    .next-follow-date {
      margin: 18px 0 0 16px;
      max-width: 300px;
    }
  }
  .officer-loading {
    text-align: center;
    .message {
      font-weight: 300;
      font-size: 20px;
      color: #000000;
      margin-bottom: 32px;
    }
    .wrap-loading {
      max-width: 724px;
      width: 100%;
      display: block;
      width: 100%;
      height: 32px;
      border-radius: 20px;
      overflow: hidden;
      margin: auto;
      position: relative;
      background: #e5e5e5;
      .loading {
        background: linear-gradient(270deg, #ffe0c2 -75.8%, #ff937e 101.86%);
        border-radius: 20px;
        overflow: hidden;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        transform: translateX(-50%);
      }
    }
  }
  .contact-list {
    > div {
      border-bottom: 1px solid #eeeeee;
      padding: 24px 0;
      &:last-child {
        border-bottom: none;
      }
      ul li {
        display: grid;
        grid-template-columns: 270px auto;
        font-size: 20px;
        margin-bottom: 16px;
        &.name {
          grid-template-columns: 270px auto auto;
          .wrap-button {
            display: flex;
            width: 100%;
            justify-content: flex-end;
          }
        }
        > .label {
          text-align: right;
          margin-right: 40px;
          font-weight: 500;
        }
      }
    }
  }
  .other-value {
    margin-top: -30px;
    max-width: 500px;
    margin-left: 26px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { useSelector } from 'react-redux';

import {
  Modal,
  Form,
  Dropdown,
  Menu,
  Input,
  DatePicker,
  Row,
  Col,
  Select
} from 'antd';

import { LayoutMenu } from '../../components/Layout';
import Button from '../../components/Button';
import useLoading from '../../hooks/loading';
import * as Icon from '../../components/Icons';
import Table from '../../components/Table';

import { getIncidents } from '../../service';
import { getThaiDate, getSortValue, dateToFormat, getSeverityLevel } from '../../utils';

import Style from './style';

const Incidents = (props) => {
  const { history } = props;
  const defaultPageLimit = 10;
  const loading = useLoading();
  const inputEl = React.useRef(null);
  const [form] = Form.useForm();
  const roleUser = useSelector(({ user }) => get(user, 'authUser.roleIds'));
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [dataFilter, setDataFilter] = React.useState({
    page: 1,
    pageLimit: defaultPageLimit,
    sort: 'updatedAt|desc',
    caseNumber: '',
    firstName: '',
    lastName: '',
    status: '',
    createdAtFrom: undefined,
    createdAtTo: undefined,
    updatedAtFrom: undefined,
    updatedAtTo: undefined,
  });
  const isEdit = find(roleUser, (role) => includes([1, 4], role)) !== undefined;
  const [state, setState] = React.useState({
    total: 1,
    rows: []
  });

  const loadData = async (query) => {
    setLoading(true);
    const { page, pageLimit = defaultPageLimit, sort = {} } = query;
    const pageSize = Number(pageLimit);
    try {
      const keyword = get(inputEl, 'current.value', '');
      const body = {
        offset: Number(page) * pageSize - pageSize,
        limit: pageSize,
        keyword,
        ...omit(query, ['page', 'pageLimit', 'sort']),
        ...getSortValue(sort)
      };
      const res = await getIncidents(body);

      setState({
        ...state,
        total: get(res, 'meta.total', 1),
        rows: get(res, 'data', [])
      });
    } catch (err) {
      console.log('loadData err', err);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loading.close();
    loadData(dataFilter);
  }, []); // eslint-disable-line

  const handleFilter = () => {
    loadData({
      page: 1,
      pageLimit: defaultPageLimit
    });
  };

  const handlePageChange = (page, pageLimit) => {
    const body = {
      ...dataFilter,
      page,
      pageLimit
    };
    setDataFilter(body);
    loadData(body);
  };

  const handleSortChange = (e) => {
    const body = {
      ...dataFilter,
      page: 1,
      pageLimit: 10,
      sort: e.key
    };
    setDataFilter(body);
    loadData(body);
  };

  const handleUpdate = (row) => {
    loading.open();
    setTimeout(() => {
      history.push(`/incidents/${row.id}/detail`);
    }, 500);
  };

  const onFinish = (values) => {
    const query = {
      sort: dataFilter.sort,
      page: 1,
      pageLimit: defaultPageLimit,
      caseNumber: get(values, 'caseNumber'),
      firstName: get(values, 'firstName'),
      lastName: get(values, 'lastName'),
      status: get(values, 'status'),
      createdAtFrom: dateToFormat(get(values, 'createdAt.0')),
      createdAtTo: dateToFormat(get(values, 'createdAt.1')),
      updatedAtFrom: dateToFormat(get(values, 'updatedAt.0')),
      updatedAtTo: dateToFormat(get(values, 'updatedAt.1')),
    };
    setDataFilter({
      ...dataFilter,
      ...query
    });
    loadData({
      ...query,
    });
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'รหัสเคส',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      render: (value) => {
        if (isEmpty(value)) return '';
        return `#${value}`;
      }
    },
    {
      title: 'ชื่อ - สกุล',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (value, row) => {
        if (isEmpty('firstName')) return 'ไม่มีข้อมูล';
        return `${value} ${get(row, 'lastName', '')}`.trim();
      }
    },
    {
      title: 'ระดับความเสี่ยง',
      dataIndex: 'severityLevelId',
      key: 'severityLevelId',
      render: (value) => {
        if (value === null) {
          return <span className="severity level">ยังไม่ได้ประเมิน</span>;
        }

        return <span className={`severity level-${value}`}>{getSeverityLevel(value)}</span>;
      }
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        switch (value) {
          case '10': {
            return <span className="status primary">ใหม่</span>;
          }
          case '20': {
            return <span className="status primary">เจ้าหน้าที่รับเรื่อง</span>;
          }
          case '30': {
            return <span className="status warning">อยู่ระหว่างดำเนินการ</span>;
          }
          case '40': {
            return (
              <span className="status warning">
                เจ้าหน้าที่ผู้เชี่ยวชาญลงพื้นที่
              </span>
            );
          }
          case '50': {
            return <span className="status info">รอติดตามผล</span>;
          }
          case '60': {
            return <span className="status secondary">ปิดเคส</span>;
          }
          case 'deleted': {
            return <span className="status secondary">ถูกลบออกจากระบบ</span>;
          }
          default: {
            return '';
          }
        }
      }
    },
    {
      title: 'อัปเดตล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value) => getThaiDate(value)
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'status',
      render: (value, row) => {
        let button = '';
        switch (row.status) {
          case '10': {
            button = (
              <Button
                primary
                size="sm"
                onClick={() => {
                  handleUpdate(row);
                }}
              >
                <span>verify</span>

                <Icon.Pen color="#FFFFFF" />
              </Button>
            );
            break;
          }
          case '20':
          case '30':
          case '40':
          case '50': {
            button = (
              <Button
                info
                size="sm"
                onClick={() => {
                  handleUpdate(row);
                }}
              >
                <span>อัปเดต</span>

                <Icon.Pen color="#FFFFFF" />
              </Button>
            );
            break;
          }
          default: {
            button = (
              <Button
                size="sm"
                onClick={() => {
                  handleUpdate(row);
                }}
              >
                ดูรายละเอียด
              </Button>
            );
            break;
          }
        }
        if (!isEdit) {
          button = (
            <Button
              size="sm"
              onClick={() => {
                handleUpdate(row);
              }}
            >
              ดูรายละเอียด
            </Button>
          );
        }
        return <div className="wrap-action">{button}</div>;
      }
    }
  ];

  const menuSort = (
    <Menu onClick={handleSortChange} selectedKeys={[dataFilter.sort]}>
      <Menu.Item key="caseNumber|asc">รหัสเคส (น้อย ไป มาก)</Menu.Item>
      <Menu.Item key="caseNumber|desc">รหัสเคส (มาก ไป น้อย)</Menu.Item>
      <Menu.Item key="updatedAt|asc">อัปเดตล่าสุด (เก่า ไป ใหม่)</Menu.Item>
      <Menu.Item key="updatedAt|desc">อัปเดตล่าสุด (ใหม่ ไป เก่า)</Menu.Item>
      <Menu.Item key="severityLevelId|asc">
        ระดับความเสี่ยง (น้อย ไป มาก)
      </Menu.Item>
      <Menu.Item key="severityLevelId|desc">
        ระดับความเสี่ยง (มาก ไป น้อย)
      </Menu.Item>
    </Menu>
  );

  return (
    <LayoutMenu>
      <Style>
        <div className="header">
          <div className="input-search">
            <div className="icon">
              <Icon.Search />
            </div>
            <input
              ref={inputEl}
              type="text"
              placeholder="ค้นหา"
              autoComplete="off"
              onKeyPress={(event) => {
                const keyCode = event.which || event.keyCode;
                console.log('keyCode >', keyCode);
                if (keyCode === 13) {
                  handleFilter();
                }
              }}
            />
          </div>

          <div className="action">
            <Dropdown overlay={menuSort} trigger={['click']}>
              <Button>
                <Icon.SortAmountDown color="#FF7A44" />
                <span>เรียงตาม</span>
              </Button>
            </Dropdown>

            <Button
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              <Icon.Filter color="#FF7A44" />
              <span>ตัวกรอง</span>
            </Button>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          loading={isLoading}
          dataSource={get(state, 'rows', [])}
          pagination={{
            current: get(dataFilter, 'page', 1),
            pageSize: get(dataFilter, 'pageLimit', 10),
            total: get(state, 'total', 1),
            onChange: handlePageChange
          }}
        />
      </Style>
      <Modal
        title="ค้นหาข้อมูล"
        width={700}
        footer={false}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          initialValues={{
            caseNumber: get(dataFilter, 'caseNumber'),
            firstName: get(dataFilter, 'firstName'),
            lastName: get(dataFilter, 'lastName'),
            status: get(dataFilter, 'status'),
            createdAt: [get(dataFilter, 'createdAtFrom'), get(dataFilter, 'createdAtTo')],
          }}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Row>
            <Col md={12}>
              <Form.Item name="caseNumber" label="รหัสเคส">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="firstName" label="ชื่อ">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="lastName" label="สกุล">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="createdAt" label="ช่วงวันที่สร้าง">
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="status" label="สถานะ" initialValue="">
                <Select
                  options={[
                    { value: '', label: 'ทั้งหมด' },
                    { value: '10', label: 'ใหม่' },
                    { value: '20', label: 'เจ้าหน้าที่รับเรื่อง' },
                    { value: '30', label: 'อยู่ระหว่างดำเนินการ' },
                    { value: '40', label: 'เจ้าหน้าที่ผู้เชี่ยวชาญลงพื้นที่' },
                    { value: '50', label: 'รอติดตามผล' },
                    { value: '60', label: 'ปิดเคส' }
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="wrap-button-filter">
            <Button
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              ยกเลิก
            </Button>
            <Button primary type="submit">
              ยืนยัน
            </Button>
          </div>
        </Form>
      </Modal>
    </LayoutMenu>
  );
};

Incidents.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

Incidents.defaultProps = {
  // children: ''
};

export default Incidents;

import { fetchWithResponse, GET, PATCH, POST, DELETE } from './api';
import {
  LOGIN,
  LOGIN_LINE,
  MY_PROFILE,
  ROLE_USERS,
  SIGNOUT,
  USERS,
  UPLOADS,
  INCIDENTS,
  PROVINCES,
  BACKOFFICE_INCIDENTS,
  SEVERITY_LEVELS,
  PROBLEM_TYPES,
  SUICIDAL_PERSONS,
  STATISTICS_PROVINCE,
  STATISTICS_GENDER,
  STATISTICS_PROBLEM,
  STATISTICS_GENDER_MONTH,
  STATISTICS_GENDER_YEAR,
  STATISTICS_TOTAL_USER,
  NOTIFICATIONS,
  ACCESS_TOKEN,
  MESSAGES
} from './apiList';

export const loginWithLine = (body) => POST(LOGIN_LINE, body);

export const login = (body) => POST(LOGIN, body, 1500);
export const signout = (body) => GET(SIGNOUT, body, 0);

export const getUserProfile = () => {
  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem('authenticated');
    fetchWithResponse(MY_PROFILE, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then((body) => {
        resolve(body);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAccessToken = () => {
  return GET(ACCESS_TOKEN, {}, 0);
};

export const uploadFile = (files) => {
  const formData = new FormData();
  formData.append('files', files);
  return fetchWithResponse(
    UPLOADS,
    {
      method: 'POST',
      data: formData
    },
    0
  );
};

export const getRoleUsers = (query = {}) => GET(ROLE_USERS, query, 0);
export const getUsers = (query = {}) => GET(USERS, query, 0);
export const getUserById = (id) => GET(`${USERS}/${id}`, {}, 0);
export const createUser = (body) => POST(USERS, body, 0);
export const updateUser = (id, body) => PATCH(`${USERS}/${id}`, body, 0);
export const deleteUser = (id) => DELETE(`${USERS}/${id}`, {}, 0);
export const getIncidents = (query = {}) => GET(INCIDENTS, query, 0);
export const updateIncident = (id, body) => PATCH(`${INCIDENTS}/${id}`, body, 0);
export const createIncident = (body) => POST(INCIDENTS, body, 0);
export const updateActionIncident = (id, body) => POST(`${BACKOFFICE_INCIDENTS}/${id}/actions`, body, 0);
export const mapIncidentIncident = (id, body) => POST(`${BACKOFFICE_INCIDENTS}/${id}/map-incident`, body, 0);
export const getIncidentByUserLogin = (query = {}) => GET(`${INCIDENTS}/active`, query, 0);
export const getIncidentById = (id) => GET(`${INCIDENTS}/${id}`, {}, 0);
export const getProvinces = () => GET(PROVINCES, {}, 0);
export const getSeverityLevels = () => GET(SEVERITY_LEVELS, {}, 0);
export const getProblemTypes = () => GET(PROBLEM_TYPES, {}, 0);

export const getSicidalPersons = (query) => GET(SUICIDAL_PERSONS, query, 0);
export const getSicidalPersonById = (id) => GET(`${SUICIDAL_PERSONS}/${id}`, {}, 0);

export const getStatisticProvince = (query) => GET(STATISTICS_PROVINCE, query, 1000);
export const getStatisticGender = (query) => GET(STATISTICS_GENDER, query, 1000);
export const getStatisticProblem = (query) => GET(STATISTICS_PROBLEM, query, 1000);
export const getStatisticGenderMonth = (query) => GET(STATISTICS_GENDER_MONTH, query, 1000);
export const getStatisticGenderYear = () => GET(STATISTICS_GENDER_YEAR, {}, 1000);
export const getStatisticTotalUser = () => GET(STATISTICS_TOTAL_USER, {}, 1000);
export const getNotifications = (query) => GET(`${NOTIFICATIONS}/me`, query, 0);
export const notificationOpen = (id) => PATCH(`${NOTIFICATIONS}/${id}/open`, {}, 0);
export const lastSeenNotification = (id) => PATCH(`${NOTIFICATIONS}/${id}/last-seen`, {}, 0);
export const getMessages = (userId, query = {}) => GET(`${MESSAGES}/${userId}`, query, 1000);
export const getMessageChannel = (userId) => GET(`${MESSAGES}/channel/${userId}`, {}, 0);
export const createMessage = (body) => POST(`${MESSAGES}/create`, body, 0);
export const getAllChannel = (query = {}) => GET(`${MESSAGES}/channels/all`, query, 0);
export const verifyChannel = (data) => POST(`${MESSAGES}/channels/verify`, data, 0);

import styled from '@emotion/styled';

export default styled.div`
  label: component-upload-file;
  max-width: 310px;
  .placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Kanit;
    .wrap-input {
      position: relative;
      > input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
      }
    }
    > span {
      font-weight: 300;
      font-size: 12px;
      color: #393f4d;
      opacity: 0.5;
    }
  }
  > ul {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    li {
      padding: 10px 0;
      margin: 0;
      list-style-type: none;
      border-bottom: 1px solid #ffe0c2;
      display: flex;
      align-items: flex-start;
      font-family: Kanit;
      font-weight: 300;
      font-size: 16px;
      color: #393f4d;
      button {
        cursor: pointer;
        padding: 0;
        margin: 0;
        background: none;
        outline: none;
        border: none;
      }
      .name {
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .info {
        display: flex;
        flex: 1 1 auto;
        justify-content: space-between;
        align-items: center;

        .delete {
          padding: 4px 10px;
          font-size: 16px;
        }
      }
      .wrap-media {
        width: 80px;
        height: 45px;
        margin-right: 14px;
        background: #000000;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        overflow: hidden;
        border: none;
        > i.icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          color: #ffffff;
        }
        img,
        video {
          max-width: 100%;
          max-height: 100%;
        }

        > .loading {
          border: 4px solid #f3f3f3; /* Light grey */
          border-top: 4px solid #ff7a44; /* Blue */
          border-radius: 50%;
          display: block;
          flex: 0 0 20px;
          height: 20px;
          animation: spinIconUpload 2s linear infinite;
        }
      }
    }
  }
  .preview-media {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #000;
    padding: 20px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    &.active {
      visibility: visible;
      opacity: 1;
    }
    > button.close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      background: none;
      border: none;
      outline: none;
      svg {
        width: 30px;
        height: 30px;
        path {
          stroke: #fff;
          stroke-width: 2px;
          stroke-opacity: 1;
        }
      }
    }
    img,
    video {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  @keyframes spinIconUpload {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

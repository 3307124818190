import React from 'react';
import { Menu, Dropdown, Badge } from 'antd';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { useHistory } from 'react-router-dom';

import { Bell } from '../Icons';

import {
  getNotifications,
  notificationOpen,
  lastSeenNotification
} from '../../service';
import { getTimeFormat } from '../../utils';
import Style, { StyleOverlay } from './style';

const Notification = () => {
  const history = useHistory();
  const [state, setState] = React.useState({
    rows: [],
    totalNew: 0
  });
  const [visible, setVisible] = React.useState(false);

  const reloadData = async () => {
    try {
      const res = await getNotifications({
        offset: 0,
        limit: 50
      });
      setState({
        rows: get(res, 'data', []),
        totalNew: get(res, 'meta.totalNew', 0)
      });
    } catch (err) {
      console.log('Notification >>', err);
    }
  };

  React.useEffect(() => {
    reloadData();
    const interval = setInterval(() => {
      reloadData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleSelectItem = async (value) => {
    setVisible(false);
    try {
      await notificationOpen(get(state, 'rows.0.id'));
    } catch (err) {
      console.log('handleSelectItem >>>', err);
    }
    switch (value.referenceType) {
      case 'users': {
        history.push(`/users/${get(value, 'referenceId')}/update`);
        break;
      }
      case 'incidents': {
        history.push(`/incidents/${get(value, 'referenceId')}/detail`);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleUpdateLastEvent = async () => {
    try {
      const sortData = orderBy(state.rows, 'id', 'desc');
      if (get(sortData, '0.id')) {
        await lastSeenNotification(get(sortData, '0.id'));
        setState({
          ...state,
          totalNew: 0
        });
      }
    } catch (err) {
      console.log('handleSelectItem >>>', err);
    }
  };

  return (
    <Style>
      <Dropdown
        // arrow
        visible={visible}
        onVisibleChange={(e) => {
          setVisible(e);
        }}
        overlay={() => {
          return (
            <StyleOverlay>
              <Menu>
                <Menu.Item key="title" disabled>
                  <h3>Notifications</h3>
                </Menu.Item>
                {isEmpty(state.rows) && (
                  <Menu.Item key="empty">
                    <div className="empty-notification">ไม่พบข้อมูล</div>
                  </Menu.Item>
                )}

                <Menu.ItemGroup key="group-options">
                  {map(state.rows, (item) => (
                    <Menu.Item key={item.id}>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSelectItem(item);
                        }}
                        className={`item-notification ${
                          item.isOpened ? 'new' : 'new'
                        }`}
                      >
                        <div className="title">{item.title}</div>
                        <div className="info">
                          <div className="role">{item.referenceType}</div>
                          <div className="date">
                            {getTimeFormat(item.createdAt)}
                          </div>
                        </div>
                      </button>
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              </Menu>
            </StyleOverlay>
          );
        }}
        trigger={['click']}
        overlayClassName="dropdown-notification"
      >
        <button className="button-notification" onClick={handleUpdateLastEvent}>
          <Badge count={get(state, 'totalNew', 0)} overflowCount={99}>
            <Bell />
          </Badge>
        </button>
      </Dropdown>
    </Style>
  );
};

export default Notification;

import React from 'react';
import liff from '@line/liff';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import get from 'lodash/get';
import set from 'lodash/set';

import Loading from './components/Loading';
import Header from './components/Header';
import Login from './pages/Login';
import { getUserProfile, loginWithLine } from './service';
import Routers from './Routers';
import { loginSuccess, appReady } from './actions/action';

const App = () => {
  const dispatch = useDispatch();
  const isReady = useSelector(({ app }) => get(app, 'isReady'));
  const isLogin = useSelector(({ user }) => get(user, 'isLogin'));
  const roleUser = useSelector(({ user }) => get(user, 'authUser.roleIds'));

  const initApp = async () => {
    console.log('initApp', process.env);
    try {
      await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
      let userLogin = {};
      if (liff.isLoggedIn()) {
        const lineIdToken = liff.getIDToken();
        userLogin = await loginWithLine({
          lineIdToken
        });
      } else {
        userLogin = await getUserProfile();
        const accessToken = localStorage.getItem('authenticated');
        set(userLogin, 'data.accessToken', accessToken);
      }
      dispatch(loginSuccess(get(userLogin, 'data', {})));
    } catch (err) {
      console.log('getUserProfile', err);
    }
    dispatch(appReady());
  };

  React.useEffect(() => {
    initApp();
  }, []); // eslint-disable-line

  return (
    <>
      <Loading />
      <ToastContainer />
      {isReady && !isLogin && <Login />}
      {isReady && isLogin && (
        <div className="layout">
          <Header />
          <div className="wrap-page">
            <Routers roleUser={roleUser} />
          </div>
        </div>
      )}
    </>
  );
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import UpdatedAt from '../UpdatedAt';
import MediaPreview from '../MediaPreview';

import Style from './style';

const NoteIncident = (props) => {
  const { items } = props;
  return (
    <Style>
      {map(items, (item, index) => {
        const files = get(item, 'files');
        return (
          <div className="wrap-item" key={index}>
            {get(item, 'isCrimeSuppressionFeedback') && (
              <div className="header-name">ข้อมูลเพิ่มเติมจากกองปราบฯ</div>
            )}
            {!isEmpty(files) && (
              <ul className="medias">
                {map(files, (file) => (
                  <li key={get(file, 'url', '')}>
                    <MediaPreview
                      src={get(file, 'url', '')}
                      mimeType={get(file, 'mimeType', '')}
                    />
                  </li>
                ))}
              </ul>
            )}
            <div className="content">{get(item, 'detail')}</div>
            <hr />
            <UpdatedAt
              className="time"
              user={{
                image: get(item, 'lastUpdatedByImageUrl'),
                name: get(item, 'createdBy'),
                role: get(item, 'lastUpdatedByRoles.0.name')
              }}
              date={get(item, 'updatedAt')}
            />
          </div>
        );
      })}
    </Style>
  );
};

NoteIncident.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape())
};

NoteIncident.defaultProps = {
  items: []
};

export default NoteIncident;

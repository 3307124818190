import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import liff from '@line/liff';

import Image from '../Image';
import Logo from '../Logo';

import useLoading from '../../hooks/loading';
import Notification from '../Notification';

import { signout } from '../../service';
import { logoutSuccess } from '../../actions/action';
import Style from './style';

const Header = () => {
  const loading = useLoading();

  const dispatch = useDispatch();
  const userInfo = useSelector(({ user }) => get(user, 'authUser'));
  const isLogin = useSelector(({ user }) => get(user, 'isLogin'));

  const handleLogout = async () => {
    loading.open();
    try {
      await signout();
      liff.logout();
      dispatch(logoutSuccess());
    } catch (err) {
      console.log('logout err', err);
    }
    loading.close();
  };

  const fullName = `${get(userInfo, 'firstName', '')} ${get(
    userInfo,
    'lastName',
    ''
  )}`.trim();
  const profileImageUrl = get(userInfo, 'profileImageUrl', '');
  const menu = (
    <Menu className="wrap-dropdown-user-info">
      <Menu.Item key="1">
        <div className="wrap-user-info-header">
          <div className="wrap-image">
            <Image src={profileImageUrl} alt="" />
          </div>
          <div className="name">{fullName}</div>
        </div>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <Link to={`/users/${userInfo.id}/update`}>ข้อมูลส่วนตัว</Link>
      </Menu.Item> */}
      <Menu.Item key="3" className="button-logout">
        <button onClick={handleLogout}>ออกจากระบบ</button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Style>
      <div className="wrap-header">
        <Link className="logo" to="/">
          <Logo />
        </Link>
        {isLogin && (
          <div className="user-info">
            <div className="alert">
              <Notification />
            </div>
            <div className="profile">
              <Image src={profileImageUrl} alt="" />
            </div>
            <Dropdown overlay={menu} trigger={['click']}>
              <button>
                <span className="name">{fullName}</span>
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0.856669L5.99998 5.58398L0.999939 0.856669"
                    stroke="#393F4D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Dropdown>
          </div>
        )}
      </div>
    </Style>
  );
};

Header.propTypes = {};

Header.defaultProps = {
  onBack: undefined
};

export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Modal } from 'antd';
import ReactCrop from 'react-image-crop';

import Button from '../Button';

import { uploadFile } from '../../service';

import Style from './style';
import 'react-image-crop/dist/ReactCrop.css';

const UploadImageProfile = (props) => {
  const { initialCrop, value, onChange, disabled } = props;
  const [image, setImage] = React.useState('');
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [crop, setCrop] = React.useState({});

  const getCroppedImg = (domImage, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = domImage.naturalWidth / domImage.width;
    const scaleY = domImage.naturalHeight / domImage.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      domImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      if (crop.width > 0) {
        canvas.toBlob(
          (blob) => {
            // eslint-disable-next-line
            blob.name = fileName;
            resolve(blob);
          },
          'image/jpeg',
          1
        );
      } else {
        resolve('');
      }
    });
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputChange = async (e) => {
    const file = get(e.target.files, '0');
    if (file && /(png|jpg|jpeg)/gi.test(file.type)) {
      const img = await toBase64(file);
      setCrop(initialCrop);
      setImage(img);
      setIsOpenModal(true);
    } else {
      console.error('File type not match', file);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleConfirm = async () => {
    try {
      const data = await getCroppedImg(
        document.querySelector('.wrap-croup-image img.ReactCrop__image'),
        crop,
        Date.now()
      );
      let dataBase64 = '';
      if (!isEmpty(data)) {
        dataBase64 = await toBase64(data);
      } else {
        dataBase64 = image;
      }
      // Usage example:
      const file = dataURLtoFile(dataBase64, `${Date.now()}.png`);
      const media = await uploadFile(file);
      onChange(get(media, 'data.url'));
    } catch (err) {
      console.error('handleConfirm', err);
    }
    setIsOpenModal(false);
  };

  const handleCancle = () => {
    setIsOpenModal(false);
  };

  return (
    <Style className={disabled ? 'disabled' : ''}>
      <div className="placeholder">
        <div className="wrap-input">
          <div className="wrap-image">
            <img src={value} alt="" />
          </div>
          <i className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="17"
              viewBox="0 0 21 17"
              fill="none"
            >
              <path
                d="M19.6192 14.0267C19.6192 14.4531 19.4498 14.8621 19.1483 15.1636C18.8467 15.4652 18.4377 15.6346 18.0113 15.6346H3.54004C3.1136 15.6346 2.70462 15.4652 2.40308 15.1636C2.10153 14.8621 1.93213 14.4531 1.93213 14.0267V5.18312C1.93213 4.75667 2.10153 4.34769 2.40308 4.04615C2.70462 3.74461 3.1136 3.5752 3.54004 3.5752H6.75588L8.36379 1.16333H13.1875L14.7955 3.5752H18.0113C18.4377 3.5752 18.8467 3.74461 19.1483 4.04615C19.4498 4.34769 19.6192 4.75667 19.6192 5.18312V14.0267Z"
                stroke="white"
                strokewdth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.7754 12.4187C12.5515 12.4187 13.9912 10.9789 13.9912 9.20289C13.9912 7.42684 12.5515 5.98706 10.7754 5.98706C8.99935 5.98706 7.55957 7.42684 7.55957 9.20289C7.55957 10.9789 8.99935 12.4187 10.7754 12.4187Z"
                stroke="white"
                strokewdth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
          <input type="file" disabled={disabled} onChange={handleInputChange} />
        </div>
      </div>
      <Modal
        title="Basic Modal"
        visible={isOpenModal}
        onOk={handleConfirm}
        onCancel={handleCancle}
        centered
        closeIcon={false}
        footer={false}
      >
        <div className="wrap-croup-image">
          <ReactCrop
            src={image}
            crop={crop}
            style={{ hight: 300 }}
            onChange={(newCrop) => {
              setCrop(newCrop);
            }}
          />
          <div className="group-button">
            <Button onClick={handleCancle}>Cancle</Button>
            <Button primary onClick={handleConfirm}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </Style>
  );
};

UploadImageProfile.propTypes = {
  initialCrop: PropTypes.shape(),
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

UploadImageProfile.defaultProps = {
  value: '',
  initialCrop: {
    aspect: 1 / 1,
    unit: 'px', // default, can be 'px' or '%'
    x: 10,
    y: 10,
    width: 200,
    height: 200
  },
  onChange: () => null,
  disabled: false
};

export default UploadImageProfile;

import styled from '@emotion/styled';

export default styled.div`
  label: component-notification;
  .button-notification {
    .ant-badge-count,
    .ant-badge-dot,
    .ant-badge .ant-scroll-number-custom-component {
      top: 6px;
    }
  }
`;

export const StyleOverlay = styled.div`
  label: component-notification-overlay;
  /* max-height: 300px; */
  /* overflow: auto; */
  min-width: 360px;
  background: #ffffff;
  box-shadow: 1px 2px 10px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding-top: 0;
  .ant-menu-item {
    border-top: 1px solid #a6d4ff;
    &:first-child {
      border-top: none;
      border-bottom: 1px solid #a6d4ff;
      margin-bottom: 0;
      padding-bottom: 8px;
    }
  }

  .ant-menu-item-group {
    max-height: 300px;
    overflow: auto;
    .ant-menu-item {
      &:first-child {
        border-top: none;
        border-bottom: none;
        margin-bottom: 8px;
        padding-bottom: 0px;
      }
    }
    > .ant-menu-item-group-title {
      display: none;
    }
    .ant-menu-item-group-list {
      .ant-menu-item {
        height: auto;
      }
    }
    .ant-menu-item-group-list .ant-menu-item,
    .ant-menu-item-group-list .ant-menu-submenu-title {
      padding: 0 16 px;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    color: #393f4d;
  }
  .empty-notification {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
  }
  .ant-menu-vertical > .ant-menu-item {
    height: auto;
  }
  button.item-notification {
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    font-family: Kanit;
    padding: 6px 6px 6px 18px;
    position: relative;
    &.new {
      &:after {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: #ff7a44;
        left: 0;
        top: 10px;
        display: inline-block;
      }
    }
    .title {
      font-weight: 300;
      font-size: 16px;
      color: #393f4d;
      margin-bottom: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .info {
      color: rgba(57, 63, 77, 0.5);
      display: flex;
      align-items: center;
      .role {
        margin-right: 20px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -10px;
          width: 2px;
          height: 2px;
          border-radius: 100%;
          background: rgba(57, 63, 77, 0.5);
          transform: translateY(-50%);
        }
      }
    }
  }
`;

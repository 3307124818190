import styled from '@emotion/styled';

export default styled.div`
  label: component-upload-image-profile;
  &.disabled {
    pointer-events: none;
  }
  .wrap-input {
    position: relative;
    width: 130px;
    height: 130px;
    border: 2px solid #ff7a44;
    padding: 8px;
    border-radius: 100%;
    .wrap-image {
      clip-path: circle(50%);
      background: #f5f5f5;
      width: 100%;
      height: 100%;
    }
    > i {
      position: absolute;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ff7a44;
      bottom: 7px;
      right: 5px;
      border-radius: 100%;
      overflow: hidden;
    }
    > input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
      &:disabled {
        pointer-events: none;
      }
    }
  }
`;

import styled from '@emotion/styled';

export default styled.div`
  label: component-country;
  font-family: Kanit;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  > .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrap-icon {
    position: relative;
  }
  .select-date {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 150px;
    z-index: 10;
    .ant-picker {
      height: 40px;
    }
  }
  .items-list {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 380px;
    > .title {
      display: block;
      text-align: center;
      width: 100%;
      background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
      border-radius: 10px;
      font-weight: 500;
      font-size: 28px;
      color: #ffffff;
      margin-bottom: 8px;
    }
    ul {
      background: #fffaf5;
      border-radius: 10px;
      border: 1px solid #ff967e;
      padding: 20px;
      max-height: 300px;
      overflow: auto;
      li {
        text-align: right;
        margin: 0 -6px 4px -6px;
        span {
          font-weight: 300;
          font-size: 12px;
          color: #393f4d;
          margin: 0 6px;
        }
      }
    }
  }
  svg {
    path {
      fill: #eeeeee;
    }
    #main-group > g {
      cursor: pointer;
      path {
        padding: 10px;
      }
    }
    #group-3 {
      &.active {
        path {
          fill: #ffd56a;
        }
      }
    }
    #group-4 {
      &.active {
        path {
          fill: #1172bc;
        }
      }
    }
    #group-2 {
      &.active {
        path {
          fill: #ee6538;
        }
      }
    }
    #group-5 {
      &.active {
        path {
          fill: #b2d6f8;
        }
      }
    }
    #group-1 {
      &.active {
        path {
          fill: #b7d1e5;
        }
      }
    }
  }
`;

/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import last from 'lodash/last';
import includes from 'lodash/includes';
import { useSelector } from 'react-redux';

import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  Radio,
  Checkbox,
  Collapse,
  DatePicker,
} from 'antd';

import Breadcrumb from '../../../components/Breadcrumb';
import Button from '../../../components/Button';
import MediaPreview from '../../../components/MediaPreview';
import EditPatientInfo from '../../../components/EditPatientInfo';
import EditRelatedPersonInfo from '../../../components/EditRelatedPersonInfo';
import EditCaseInfo from '../../../components/EditCaseInfo';
import CaseLinkMedicalRecord from '../../../components/CaseLinkMedicalRecord';
import * as Icon from '../../../components/Icons';
import UpdatedAt from '../../../components/UpdatedAt';
import ActionIncident from '../../../components/ActionIncident';
import NoteIncident from '../../../components/NoteIncident';

import useLoading from '../../../hooks/loading';
import {
  getIncidentById,
  updateActionIncident,
  getSeverityLevels,
  getProblemTypes,
} from '../../../service';
import { getLabelGender, getDateRange, dateToFormat } from '../../../utils';
import Style from './style';

const optionsCloseDetail = [
  { label: 'ติดต่อไม่ได้', value: 'ติดต่อไม่ได้' },
  { label: 'ประสานญาติ', value: 'ประสานญาติ' },
  {
    label: 'ประสานศูนย์ฉุกเฉิน (1669,1646) ',
    value: 'ประสานศูนย์ฉุกเฉิน (1669,1646) ',
  },
  { label: 'ประสานผู้ใหญ่บ้าน,อบต,อสม', value: 'ประสานผู้ใหญ่บ้าน,อบต,อสม' },
  {
    label: 'ตำรวจ นำส่ง โรงพยาบาล',
    value: 'ตำรวจ นำส่ง โรงพยาบาล',
    other: true,
  },
  { label: 'ประสานโรงพยาบาล', value: 'ประสานโรงพยาบาล', other: true },
];

const IncidentDetail = (props) => {
  const { match, history } = props;
  const id = get(match, 'params.id');
  const loading = useLoading();
  const [form] = Form.useForm();
  const roleUser = useSelector(({ user }) => get(user, 'authUser.roleIds'));
  const [state, setState] = React.useState({});
  const [actionType, setActionType] = React.useState();
  const [steps, setSteps] = React.useState({
    current: '',
  });
  const [cancleType, setCancleType] = React.useState('');
  const [hospital, setHospital] = React.useState('');
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const isEdit = find(roleUser, (role) => includes([1, 4], role)) !== undefined;
  const [problemTypes, setProblemTypes] = React.useState([]);
  const [severityLevels, setSeverityLevels] = React.useState([]);

  const closeDetailSelected = find(
    optionsCloseDetail,
    (obj) => obj.value === cancleType
  );

  const loadData = async () => {
    try {
      const res = await getIncidentById(id);
      const data = get(res, 'data', {});
      setState(data);
    } catch (err) {
      if (err.code === 404) {
        history.push('/404');
      }
    }
    loading.close();
  };

  const initPage = async () => {
    try {
      const resultSeverity = await getSeverityLevels();
      const resultProblemType = await getProblemTypes();
      setProblemTypes(
        map(get(resultProblemType, 'data', {}), (obj) => {
          return {
            label: obj.problemType,
            value: obj.id,
          };
        })
      );
      setSeverityLevels(
        map(get(resultSeverity, 'data', {}), (obj) => {
          return {
            label: obj.severityLevel,
            value: obj.id,
          };
        })
      );
    } catch (err) {
      console.log('loadData err', err);
    }
    loadData();
  };

  React.useEffect(() => {
    initPage();
  }, [id]); // eslint-disable-line

  const handlePageBack = () => {
    history.goBack();
  };

  const getValue = (key) => {
    const value = get(state, key);
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return value;
  };

  const getFullname = (keys) => {
    const value = reduce(
      keys,
      (pre, key) => {
        return `${pre} ${get(state, key)}`;
      },
      ''
    ).trim();
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return value;
  };

  const getAddress = (keys) => {
    const value = find(keys, (key) => !isEmpty(get(state, key)));
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return get(state, value);
  };

  const handleContaceChange = (value, name) => {
    console.log('handleContaceChange >>', value, name);
    setSteps({
      ...steps,
      [name]: value,
    });
  };

  const submitActionType = async () => {
    const values = form.getFieldsValue();
    let body = {};
    try {
      switch (get(values, 'action-type')) {
        case 'close': {
          body = {
            action: 'close',
            data: {
              cancleCause: get(values, 'cancleCause'),
              hospital: get(values, 'hospital'),
            },
          };
          break;
        }
        case 'follow': {
          body = {
            action: 'follow',
            data: {
              nextFollowDate: dateToFormat(get(values, 'nextFollowDate')),
            },
          };
          break;
        }
        case 'send_crime_suppression_onsite': {
          body = {
            action: 'send_crime_suppression_onsite',
          };
          break;
        }
        default: {
          break;
        }
      }

      if (!isEmpty(body)) {
        loading.open();
        await updateActionIncident(id, body);
        loadData();
        if (body.action === 'close') {
          history.push('/messages');
        }
      }
    } catch (err) {
      console.log('err >>', err);
      loading.close();
    }
  };

  const handleSubmitOfficer = async (values) => {
    try {
      let isCallService = false;
      /* ถ้ามีการ กรอก form การประเมิณ */
      if (
        !isEmpty(values.severityLevelId) ||
        !isEmpty(values.problemTypeIds) ||
        !isEmpty(values.detail)
      ) {
        loading.open();
        isCallService = true;
        await updateActionIncident(id, {
          action: 'risk_assessment',
          data: {
            severityLevelId: values.severityLevelId,
            problemTypeIds: values.problemTypeIds,
            detail: values.detail,
            suggestion: values.suggestion,
          },
        });
      }
      /* ถ้ามีการ กรอก form ขอ ข้อมูลจากกองปราบ  */
      if (
        get(values, 'requirePhoneNumber') !== undefined ||
        get(values, 'requireAddress') !== undefined
      ) {
        loading.open();
        isCallService = true;
        await updateActionIncident(id, {
          action: 'contact_crime_suppression',
          data: {
            requirePhoneNumber: values.requirePhoneNumber,
            requireAddress: values.requireAddress,
            requireContact: values.requireContact,
          },
        });
      }

      if (get(values, 'action-type')) {
        submitActionType();
      }
      if (isCallService) {
        setSteps({
          ...steps,
          current: '',
        });
        loadData();
      }
      form.resetFields();
    } catch (err) {
      console.log('err >>', err);
      // alert.err
      loading.close();
    }
  };

  const handleincidentClose = async () => {
    try {
      loading.open();
      const res = await updateActionIncident(id, {
        action: 'close',
        data: {
          cancleCause: cancleType,
          hospital,
        },
      });
      setIsOpenModal(false);
      loadData();
      history.push('/messages');
      console.log('res >>>', res);
    } catch (err) {
      console.log('error', err);
    }
    loading.close();
  };

  const handleActionTypeChange = (e) => {
    setActionType(e.target.value);
  };

  const renderofficerContent = () => {
    const lastAction = last(get(state, 'actions', []));
    const requireContactData = find(
      get(state, 'actions'),
      (obj) => obj.actionName === 'contact_crime_suppression'
    );
    const isActiveRequireContact =
      !isEmpty(requireContactData) ||
      steps.current === 'contact_crime_suppression';

    const assessmentData = find(
      get(state, 'actions'),
      (obj) => obj.actionName === 'risk_assessment'
    );
    const isActiveAssessment =
      !isEmpty(assessmentData) || steps.current === 'risk_assessment';

    // จะเป็น true ก็ต่อเมื่อ action ล่าสุด เป็นการ ประเมิณ หรือ ติดต่ออีกครั้ง
    const isShowSelectActionType =
      get(lastAction, 'actionName') === 'follow' ||
      get(lastAction, 'actionName') === 'send_crime_suppression_onsite' ||
      get(lastAction, 'actionName') === 'risk_assessment';

    const isHiddenButtonSubmit = get(lastAction, 'actionName') === 'close';

    const isOpenFormAssessment =
      steps.current === 'risk_assessment' ||
      get(lastAction, 'actionName') === 'follow' ||
      get(lastAction, 'actionName') === 'send_crime_suppression_onsite';

    return (
      <>
        {isEdit && (
          <ul className="steps">
            <li>
              <button
                className={isActiveRequireContact ? 'active' : ''}
                onClick={() => {
                  if (!isActiveRequireContact) {
                    handleContaceChange('contact_crime_suppression', 'current');
                  }
                }}
              >
                <Icon.Radio checked={isActiveRequireContact} />
                <span>ติดต่อกองปราบฯ เพื่อหาข้อมูล</span>
              </button>
            </li>
            <li>
              <button
                className={isActiveAssessment ? 'active' : ''}
                onClick={() => {
                  if (!isActiveAssessment) {
                    handleContaceChange('risk_assessment', 'current');
                  }
                }}
              >
                <Icon.Radio checked={isActiveAssessment} />
                <span>ดำเนินการประเมินความเสี่ยง</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  if (isEmpty(assessmentData)) {
                    setIsOpenModal(true);
                  }
                }}
                disabled={!isEmpty(assessmentData)}
              >
                <Icon.Radio />
                <span>ยุติการดำเนินการ</span>
              </button>
            </li>
          </ul>
        )}
        <hr />
        <section className="notes">
          <NoteIncident items={get(state, 'notes', [])} />
        </section>
        {get(state, 'notes', []).length > 0 && <hr />}
        <section className="actions">
          <ActionIncident
            items={get(state, 'actions', [])}
            severityLevels={severityLevels}
          />
        </section>

        <Form
          layout="vertical"
          form={form}
          name="form-incidents"
          onFinish={handleSubmitOfficer}
          autoComplete="off"
          initialValues={{
            gender: 'other',
          }}
        >
          {steps.current === 'contact_crime_suppression' && (
            <section className="contact">
              <h3>ติดต่อกองปราบฯ </h3>
              <Form.Item name="requirePhoneNumber" valuePropName="checked">
                <Checkbox>เบอร์โทรศัพท์ติดต่อ</Checkbox>
              </Form.Item>

              <Form.Item name="requireAddress" valuePropName="checked">
                <Checkbox>ที่อยู่/ สถานที่เกิดเหตุ</Checkbox>
              </Form.Item>
              <Form.Item name="requireContact" valuePropName="checked">
                <Checkbox>เบอร์โทรศัพท์ติดต่อญาติ</Checkbox>
              </Form.Item>
            </section>
          )}
          {isOpenFormAssessment && (
            <section className="risk-assessment">
              <h3>ดำเนินการประเมินความเสี่ยง</h3>
              <Collapse>
                <Collapse.Panel header="ประเมินอาการ" key="1">
                  <h3>รายละเอียดผู้ขอรับบริการ</h3>
                  <Row>
                    <Col md={12}>
                      <Form.Item label="ประเภทกลุ่มปัญหา" name="problemTypeIds">
                        <Select mode="multiple" options={problemTypes} />
                      </Form.Item>
                      <Form.Item label="ระดับความเสี่ยง" name="severityLevelId">
                        <Select options={severityLevels} />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item label="รายละเอียด" name="detail">
                        <Input.TextArea rows={6} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <hr />
                  <h3>ข้อเสนอแนะ</h3>
                  <Row>
                    <Col md={12}>
                      <Form.Item label="รายละเอียด" name="suggestion">
                        <Input.TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </section>
          )}

          {isShowSelectActionType && isEdit && (
            <section className="action-type">
              <h3>การดำเนินการต่อ</h3>
              <Form.Item
                name="action-type"
                rules={[
                  {
                    required: true,
                    message: 'กรุณาระบุประเภทการดำเนินการต่อ',
                  },
                ]}
              >
                <Radio.Group onChange={handleActionTypeChange}>
                  <Radio value="close">ยุติการให้คำปรึกษา</Radio>
                  {actionType === 'close' && (
                    <div className="other-value">
                      <Form.Item label="สาเหตุ" name="cancleCause">
                        <Select
                          options={optionsCloseDetail}
                          onChange={(value) => {
                            setCancleType(value);
                          }}
                        />
                      </Form.Item>
                      {get(closeDetailSelected, 'other', false) && (
                        <Form.Item label="โรงพยาบาล" name="hospital">
                          <Input />
                        </Form.Item>
                      )}
                    </div>
                  )}
                  <Radio value="follow">ติดต่ออีกครั้ง</Radio>
                  {actionType === 'follow' && (
                    <div className="other-value">
                      <Form.Item label="วัน/เดือน/ปี" name="nextFollowDate">
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </div>
                  )}
                  <Radio value="send_crime_suppression_onsite">
                    ส่งต่อกองปราบฯ เพื่อเตรียมลงพื้นที่
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </section>
          )}
          {!isHiddenButtonSubmit && isEdit && (
            <div className="wrap-button">
              <Button size="lg" onClick={handlePageBack}>
                ยกเลิก
              </Button>
              <Button primary size="lg" type="submit">
                บันทึก
              </Button>
            </div>
          )}
          {(isHiddenButtonSubmit || !isEdit) && (
            <div className="wrap-button">
              <Button primary size="lg" onClick={handlePageBack}>
                กลับ
              </Button>
            </div>
          )}
        </Form>
      </>
    );
  };

  const isOfficerLoading = get(state, 'crimeSuppressionStatus') === 'pending';
  const title = (
    <div className="title-breadcrumb">
      จัดการเคส <span>#{get(state, 'caseNumber')}</span>
    </div>
  );

  return (
    <Style>
      <div className="container">
        <Breadcrumb title={title} onBack={handlePageBack} />
        <UpdatedAt className="time" date={get(state, 'updatedAt')} />
        <div className="content">
          <section className="info">
            <div className="title">รายละเอียดเหตุการณ์</div>
            <Row>
              <Col md={4}>
                <div className="label">รายละเอียด</div>
              </Col>
              <Col md={20} className="value">
                {getValue('detail')}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="label">ภาพที่เกี่ยวข้อง</div>
              </Col>
              <Col md={20}>
                <ul className="images">
                  {map(get(state, 'files'), (file) => (
                    <li key={get(file, 'url', '')}>
                      <MediaPreview
                        src={get(file, 'url', '')}
                        mimeType={get(file, 'mimeType', '')}
                      />
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </section>
          <section className="detail">
            <UpdatedAt
              className="user-update"
              date={get(state, 'updatedAt')}
              user={{
                image: get(state, 'lastUpdatedByImageUrl'),
                name: get(state, 'lastUpdatedBy'),
                role: get(state, 'lastUpdatedByRoles.0.name'),
              }}
            />
            <div className="wrap-form">
              <div className="head">
                <div className="title">ข้อมูลผู้มีความเสี่ยง</div>
                {isEdit && (
                  <div className="wrap-button">
                    <CaseLinkMedicalRecord id={id} />
                    <EditPatientInfo value={state} onSuccess={loadData} />
                  </div>
                )}
              </div>
              <Row>
                <Col md={6} className="label">
                  ชื่อ-นามสกุล
                </Col>
                <Col md={18} className="value">
                  {getFullname(['firstName', 'lastName'])}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="label">
                  เพศ
                </Col>
                <Col md={18} className="value">
                  {getLabelGender(get(state, 'gender'), 'ไม่มีข้อมูล')}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="label">
                  เบอร์โทรศัพท์
                </Col>
                <Col md={18} className="value">
                  {getValue('telephone')}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="label">
                  บัญชีผู้ใช้ FG, IG, Tiktok
                </Col>
                <Col md={18} className="value">
                  {getValue('socila')}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="label">
                  ที่อยู่
                </Col>
                <Col md={18} className="value">
                  {getAddress(['address', 'provinceName'])}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="label">
                  Map
                </Col>
                <Col md={18} className="value">
                  {get(state, 'latitude') && get(state, 'longitude') ? (
                    <a
                      href={`https://maps.google.com/?q=${get(
                        state,
                        'latitude'
                      )},${get(state, 'longitude')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button size="sm" primary>
                        Go to Map
                      </Button>
                    </a>
                  ) : (
                    'ไม่มีข้อมูล'
                  )}
                </Col>
              </Row>
            </div>
            <div className="wrap-form">
              <div className="head">
                <div className="title">ข้อมูลผู้เกี่ยวข้อง</div>
              </div>
              <div className="contact-list">
                {map(get(state, 'contacts', []), (contact, index) => {
                  return (
                    <div key={index}>
                      <ul>
                        <li className="name">
                          <span className="label">ชื่อ-นามสกุล</span>
                          <span className="value">
                            {`${get(contact, 'contactFirstName')} ${get(
                              contact,
                              'contactLastName'
                            )}`}
                          </span>
                          {isEdit && (
                            <div className="wrap-button">
                              <EditRelatedPersonInfo
                                value={contact}
                                index={index}
                                data={state}
                                onSuccess={loadData}
                              />
                            </div>
                          )}
                        </li>
                        <li>
                          <span className="label">เบอร์โทรศัพท์</span>
                          <span className="value">
                            {get(contact, 'contactTelephone')}
                          </span>
                        </li>
                        <li>
                          <span className="label">ความเกี่ยวข้อง</span>
                          <span className="value">
                            {get(contact, 'contactRelation')}
                          </span>
                        </li>
                        <li>
                          <span className="label">ที่อยู่</span>
                          <span className="value">
                            {get(contact, 'contactAddress')}
                          </span>
                        </li>
                        <li>
                          <span className="label">Map</span>
                          <span className="value">
                            {get(state, 'contactLatitude') &&
                            get(state, 'contactLongitude') ? (
                              <a
                                href={`https://maps.google.com/?q=${get(
                                  state,
                                  'contactLatitude'
                                )},${get(state, 'contactLongitude')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button size="sm" primary>
                                  Go to Map
                                </Button>
                              </a>
                            ) : (
                              'ไม่มีข้อมูล'
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="wrap-form">
              <div className="head">
                <div className="title">ข้อมูลเบื้องต้น</div>
                {isEdit && (
                  <div className="wrap-button">
                    <EditCaseInfo value={state} onSuccess={loadData} />
                  </div>
                )}
              </div>
              <Row>
                <Col md={6} className="label">
                  ช่วงเวลาที่แจ้ง
                </Col>
                <Col md={18} className="value">
                  {getDateRange(
                    get(state, 'occurencePeriodFrom'),
                    get(state, 'occurencePeriodTo')
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="label">
                  สถานที่เกิดเหตุ
                </Col>
                <Col md={18} className="value">
                  {getValue('occurencePlace')}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="label">
                  ช่องทางการแจ้งเตือน
                </Col>
                <Col md={18} className="value">
                  {getValue('reportedChannel')}
                </Col>
              </Row>
            </div>
          </section>
          <hr />
          <section className="officer">
            <div className="title">สำหรับเจ้าหน้าที่ (Verify Case)</div>
            {!isOfficerLoading && renderofficerContent()}
            {isOfficerLoading && (
              <div className="officer-loading">
                <div className="message">
                  ขณะนี้กองปราบฯ กำลังดำเนินการหาข้อมูล
                </div>
                <div className="wrap-loading">
                  <span className="loading" />
                </div>
                <hr />
                <div className="wrap-button">
                  <Button primary size="lg" onClick={handlePageBack}>
                    กลับ
                  </Button>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>

      <Modal
        title="ยืนยันการยุติการดำเนินการ"
        centered
        visible={isOpenModal}
        onOk={handleincidentClose}
        className="modal-close-incidents"
        onCancel={() => {
          setIsOpenModal(false);
        }}
      >
        <div className="wrap-incidents-cancle">
          <div className="wrap-input">
            <span>สาเหตุ</span>
            <Select
              options={optionsCloseDetail}
              placeholder="สาเหตุ"
              onChange={(value) => {
                setCancleType(value);
              }}
            />
          </div>
          {get(closeDetailSelected, 'other', false) && (
            <div className="wrap-input">
              <span>โรงพยาบาล</span>
              <Input
                value={hospital}
                placeholder="โรงพยาบาล"
                onChange={(e) => {
                  setHospital(e.target.value);
                }}
              />
            </div>
          )}
        </div>
      </Modal>
    </Style>
  );
};

IncidentDetail.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
};

IncidentDetail.defaultProps = {};

export default IncidentDetail;

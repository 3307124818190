import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import IconLoading from './Icon';

import Style from './style';

const Loading = () => {
  const isOpen = useSelector(({ app }) => app.isLoading);

  return (
    <Style className={`${isOpen ? 'active' : 'hidden'}`}>
      <div className="content">
        <div className="wrap-icon">
          <IconLoading />
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="14"
            viewBox="0 0 94 14"
            fill="none"
          >
            <circle cx="7" cy="7" r="7" fill="#FF7A44" />
            <circle cx="28" cy="7" r="6" fill="#FF8928" fillOpacity="0.7" />
            <circle cx="48" cy="7" r="6" fill="#FF8928" fillOpacity="0.5" />
            <circle cx="68" cy="7" r="6" fill="#FF8928" fillOpacity="0.3" />
            <circle cx="88" cy="7" r="6" fill="#FF8928" fillOpacity="0.1" />
          </svg>
        </div>
        <div className="message">L O A D I N G . . .</div>
      </div>
    </Style>
  );
};

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;

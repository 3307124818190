import React from 'react';
import PropTypes from 'prop-types';

import { Clock } from '../Icons';
import Image from '../Image';

import { getThaiDate } from '../../utils';
import Style from './style';

const UpdatedAt = (props) => {
  const { date, user = {}, label, className } = props;
  const { image, name, role } = user;
  return (
    <Style className={className}>
      <Clock />
      <div>
        <div className="date-time">
          <b>{label}</b> {getThaiDate(date, 'DD MMMM YYYY | เวลา HH.mm น.')}
        </div>
        {user && (
          <div className="user-info">
            {image && (
              <div className="wrap-image">
                <Image src={image} />
              </div>
            )}
            <div className="info">
              {name && <div className="name">{name}</div>}
              {role && <div className="role">{role}</div>}
            </div>
          </div>
        )}
      </div>
    </Style>
  );
};

UpdatedAt.propTypes = {
  date: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  user: PropTypes.shape({})
};

UpdatedAt.defaultProps = {
  label: 'วันที่อัปเดต',
  date: '',
  className: '',
  user: undefined
};

export default UpdatedAt;

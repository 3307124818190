import styled from '@emotion/styled';

export default styled.div`
  label: component-country;
  font-family: Kanit;
  position: relative;
  > .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrap-hrader-chart {
    > .title {
      font-weight: 600;
      font-size: 24px;
      color: #495057;
      text-align: center;
    }
  }
  .wrap-gender {
    max-width: 344px;
    margin: auto;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        margin: 10px;
        text-align: center;
      }
    }
  }
  .wrap-type {
    max-width: 344px;
    margin: auto;
    ul {
      li {
        display: flex;
        margin-bottom: 16px;
        > .label {
          display: flex;
          align-items: center;
          padding: 6px;
          flex: 1 1 auto;
          border-radius: 10px;
          > .icon {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-radius: 100%;
          }
          .name {
            margin-left: 16px;
          }
        }
        > .value {
          margin-left: 10px;
          border-radius: 10px;
          padding: 14px;
          min-width: 50px;
          text-align: center;
        }
        &:nth-child(1) {
          .label,
          .value {
            background: rgba(255, 147, 126, 0.25);
            .icon {
              background: #FF937E;
            }
          }
        }
        &:nth-child(2) {
          .label,
          .value {
            background: rgba(255, 167, 143, 0.2);
            .icon {
              background: rgba(255, 147, 126, 0.5);
            }
          }
        }
        &:nth-child(3) {
          .label,
          .value {
            background: #fff2ce;
            .icon {
              background: #FFD56A;
            }
          }
        }
        &:nth-child(4) {
          .label,
          .value {
            background: rgba(255, 223, 140, 0.2);
            .icon {
              background: rgba(255, 216, 106, 0.5);
            }
          }
        }
        &:nth-child(5) {
          .label,
          .value {
            background: #e3f1ff;
            .icon {
              background: #A6D4FF;
            }
          }
        }
        &:nth-child(6) {
          .label,
          .value {
            background: rgba(179, 218, 255, 0.2);
            .icon {
              background: rgba(166, 212, 255, 0.5);
            }
          }
        }
      }
    }
  }
  .wrap-chart {
    text {
      font-family: Kanit;
      font-weight: 300;
    }
    .name-chart {
      font-size: 40px;
      font-weight: 500;
      color: #393f4d;
    }
    .value-chart {
      font-size: 18px;
      color: #333333;
    }
  }
`;

import styled from '@emotion/styled';

export default styled.div`
  label: component-country;
  font-family: Kanit;
  position: relative;
  > .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrap-hrader-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    > .title {
      font-weight: 600;
      font-size: 15px;
      color: #495057;
    }
  }
  .wrap-chart {
    text {
      font-family: Kanit;
      font-weight: 300;
    }
  }
  .legend {
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -10px;
      li {
        flex: 1 1 50%;
        padding: 10px;
        .name {
          display: flex;
          align-items: flex-start;
          color: #74788d;
          font-size: 14px;
        }
        .icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 8px;
        }
        .value {
          margin-left: 20px;
          color: #495057;
          font-size: 16px;
        }
      }
    }
  }
`;

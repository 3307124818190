/* eslint-disable react/prop-types */

import React from 'react';
import find from 'lodash/find';
import includes from 'lodash/includes';

import { Route, Switch, Redirect } from 'react-router-dom';

import Home from './pages/Home';
import Users from './pages/Users';
import UpdateUser from './pages/Users/Update';
import CreateUser from './pages/Users/Create';
import Incidents from './pages/Incidents';
import MyIncidents from './pages/Incidents/MyIncidents';
import IncidentDetail from './pages/Incidents/Detail';
import CreateIncident from './pages/Incidents/Create';
import MedicalRecord from './pages/MedicalRecord';
import MedicalRecordDetail from './pages/MedicalRecord/Detail';
import Dashboard from './pages/Dashboard';
import NoMatch from './pages/404';
import Message from './pages/Message';

const PrivateRoute = ({
  component: Component,
  roleUser,
  roleAllow = [1, 2, 3, 4],
  ...rest
}) => {
  const getRole = find(roleUser, (role) => includes(roleAllow, role));
  const isAllow = getRole !== undefined;
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAllow ? <Component {...props} /> : <Redirect to="/404" />;
      }}
    />
  );
};

export default ({ roleUser }) => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <PrivateRoute
        roleUser={roleUser}
        roleAllow={[1]}
        exact
        path="/users"
        component={Users}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/users/create"
        component={CreateUser}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/users/:id/update"
        component={UpdateUser}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/incidents"
        component={Incidents}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/incidents/me"
        component={MyIncidents}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/incidents/create"
        component={CreateIncident}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/incidents/:id/detail"
        component={IncidentDetail}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/medical-record"
        component={MedicalRecord}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/medical-record/:id/detail"
        component={MedicalRecordDetail}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        roleUser={roleUser}
        exact
        path="/messages"
        component={Message}
      />

      <Route component={NoMatch} />
    </Switch>
  );
};

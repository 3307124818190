import styled from '@emotion/styled';

export default styled.div`
  label: component-edit-related-person-info;
  padding: 20px 40px;
  hr {
    margin: 30px 0;
    border: none;
    border-bottom: 2px solid #E5E5E5;
  }
  .wrap-button {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
    button {
      width: 260px;
      margin: 0 10px;
    }
  }
`;

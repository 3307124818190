import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import { Row, Col } from 'antd';

import Breadcrumb from '../../../components/Breadcrumb';
import UpdatedAt from '../../../components/UpdatedAt';

import useLoading from '../../../hooks/loading';

import { getSicidalPersonById } from '../../../service';

import { getLabelGender } from '../../../utils';

import Style from './style';

const MedicalRecordDetail = (props) => {
  const { history, match } = props;
  const id = get(match, 'params.id');
  const loading = useLoading();
  const [state, setState] = React.useState({});

  const loadData = async () => {
    try {
      const res = await getSicidalPersonById(id);
      setState(get(res, 'data', {}));
    } catch (err) {
      console.log('loadData err', err);
    }
    loading.close();
  };

  React.useEffect(() => {
    loadData();
  }, [id]); // eslint-disable-line

  const handlePageBack = () => {
    history.goBack();
  };

  const getValue = (key) => {
    const value = get(state, key);
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return value;
  };

  const getFullname = (keys) => {
    const value = reduce(
      keys,
      (pre, key) => {
        return `${pre} ${get(state, key)}`;
      },
      ''
    ).trim();
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return value;
  };

  const fullname = getFullname(['firstName', 'lastName']);
  const title = (
    <div className="title-breadcrumb">
      {fullname} <span>{get(state, 'telephone')} </span>
    </div>
  );

  const incidents = get(state, 'incidents', []);
  return (
    <Style>
      <div className="container">
        <Breadcrumb title={title} onBack={handlePageBack} />
        <UpdatedAt className="time" date={get(state, 'updatedAt')} />
        <section>
          <h2>ข้อมูลผู้มีความเสี่ยง</h2>
          <Row>
            <Col md={6} className="label">
              ชื่อ-นามสกุล
            </Col>
            <Col md={18} className="value">
              {fullname}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="label">
              เพศ
            </Col>
            <Col md={18} className="value">
              {getLabelGender(get(state, 'gender'), 'ไม่มีข้อมูล')}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="label">
              เบอร์โทรศัพท์
            </Col>
            <Col md={18} className="value">
              {getValue('telephone')}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="label">
              ที่อยู่
            </Col>
            <Col md={18} className="value">
              {getValue('address')}
            </Col>
          </Row>
          <hr />
          <h2>ข้อมูลผู้เกี่ยวข้อง</h2>
          <Row>
            <Col md={6} className="label">
              ชื่อ-นามสกุล
            </Col>
            <Col md={18} className="value">
              {getFullname(['contactFirstName', 'contactLastName'])}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="label">
              เบอร์โทรศัพท์
            </Col>
            <Col md={18} className="value">
              {getValue('contactTelephone')}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="label">
              ความเกี่ยวข้อง
            </Col>
            <Col md={18} className="value">
              {getValue('contactRelation')}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="label">
              ที่อยู่
            </Col>
            <Col md={18} className="value">
              {getValue('contactAddress')}
            </Col>
          </Row>
        </section>
        {size(incidents) > 0 && (
          <section className="incidents">
            <ul>
              {map(get(state, 'incidents', []), (incident) => {
                return (
                  <li key={incident.id}>
                    <div className="info">
                      <div className="title">
                        <b>เคส #{incident.caseNumber}</b>
                        <div className="name">
                          {`${get(incident, 'firstName')} ${get(
                            incident,
                            'lastName'
                          )}`.trim()}
                        </div>
                        <div
                          className={`status lavel-${get(incident, 'status')}`}
                        >
                          {get(incident, 'statusText')}
                        </div>
                      </div>
                      <div
                        className={`severity level-${get(
                          incident,
                          'severityLevelId'
                        )}`}
                      >
                        {get(incident, 'severityLevel')}
                      </div>
                    </div>
                    <UpdatedAt
                      className="update-at"
                      date={get(incident, 'updatedAt')}
                      user={{
                        image: get(incident, 'lastUpdatedByImageUrl'),
                        name: get(incident, 'lastUpdatedBy'),
                        role: get(incident, 'lastUpdatedByRoles.0.name')
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </section>
        )}
      </div>
    </Style>
  );
};

MedicalRecordDetail.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

MedicalRecordDetail.defaultProps = {};

export default MedicalRecordDetail;

/* eslint react/prop-types:off  */
import React from 'react';

export const SortAmountDown = ({ color = '#393F4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
  >
    <path
      d="M21.1613 1C21.1613 0.585786 20.8255 0.25 20.4113 0.25C19.9971 0.25 19.6613 0.585786 19.6613 1H21.1613ZM20.4113 21L19.958 21.5975L20.4113 21.9414L20.8646 21.5975L20.4113 21ZM17.2692 17.6752C16.9392 17.4249 16.4687 17.4895 16.2184 17.8195C15.968 18.1495 16.0326 18.6199 16.3627 18.8703L17.2692 17.6752ZM24.46 18.8703C24.79 18.6199 24.8546 18.1495 24.6042 17.8195C24.3539 17.4895 23.8834 17.4249 23.5534 17.6752L24.46 18.8703ZM19.6613 1V21H21.1613V1H19.6613ZM20.8646 20.4025L17.2692 17.6752L16.3627 18.8703L19.958 21.5975L20.8646 20.4025ZM20.8646 21.5975L24.46 18.8703L23.5534 17.6752L19.958 20.4025L20.8646 21.5975Z"
      fill={color}
    />
    <path
      d="M1.53564 16.4531H13.2207"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.13135 11H13.221"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.72656 5.54688H13.2208"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const Clock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99988 1.39535C5.24772 1.39535 1.39533 5.24778 1.39533 10C1.39533 14.7522 5.24772 18.6047 9.99988 18.6047C14.752 18.6047 18.6044 14.7522 18.6044 10C18.6044 5.24778 14.752 1.39535 9.99988 1.39535ZM0 10C0 4.47715 4.4771 0 9.99988 0C15.5227 0 19.9998 4.47715 19.9998 10C19.9998 15.5228 15.5227 20 9.99988 20C4.4771 20 0 15.5228 0 10Z"
      fill="#393F4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99942 3.72266C10.3847 3.72266 10.6971 4.03502 10.6971 4.42033V9.57053L14.0323 11.2382C14.3769 11.4105 14.5166 11.8295 14.3443 12.1742C14.172 12.5188 13.7529 12.6585 13.4083 12.4862L9.68742 10.6257C9.45106 10.5076 9.30176 10.266 9.30176 10.0017V4.42033C9.30176 4.03502 9.61411 3.72266 9.99942 3.72266Z"
      fill="#393F4D"
    />
  </svg>
);

export const Plus = ({ color = '#393F4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
  >
    <path
      d="M12.7207 0C10.2484 0 7.83169 0.733112 5.77608 2.10663C3.72047 3.48015 2.11831 5.43238 1.17221 7.71646C0.226119 10.0005 -0.0214225 12.5139 0.460893 14.9386C0.943208 17.3634 2.13372 19.5907 3.88187 21.3388C5.63003 23.087 7.85732 24.2775 10.2821 24.7598C12.7068 25.2421 15.2202 24.9946 17.5042 24.0485C19.7883 23.1024 21.7406 21.5002 23.1141 19.4446C24.4876 17.389 25.2207 14.9723 25.2207 12.5C25.2169 9.18597 23.8987 6.00877 21.5553 3.66539C19.2119 1.32202 16.0347 0.0038303 12.7207 0ZM17.5284 13.4615H13.6822V17.3077C13.6822 17.5627 13.5809 17.8073 13.4006 17.9876C13.2203 18.1679 12.9757 18.2692 12.7207 18.2692C12.4657 18.2692 12.2211 18.1679 12.0408 17.9876C11.8605 17.8073 11.7592 17.5627 11.7592 17.3077V13.4615H7.91301C7.658 13.4615 7.41343 13.3602 7.2331 13.1799C7.05278 12.9996 6.95148 12.755 6.95148 12.5C6.95148 12.245 7.05278 12.0004 7.2331 11.8201C7.41343 11.6398 7.658 11.5385 7.91301 11.5385H11.7592V7.69231C11.7592 7.43729 11.8605 7.19272 12.0408 7.0124C12.2211 6.83207 12.4657 6.73077 12.7207 6.73077C12.9757 6.73077 13.2203 6.83207 13.4006 7.0124C13.5809 7.19272 13.6822 7.43729 13.6822 7.69231V11.5385H17.5284C17.7834 11.5385 18.028 11.6398 18.2083 11.8201C18.3886 12.0004 18.4899 12.245 18.4899 12.5C18.4899 12.755 18.3886 12.9996 18.2083 13.1799C18.028 13.3602 17.7834 13.4615 17.5284 13.4615Z"
      fill={color}
    />
  </svg>
);

export const Paperclip = ({ color = '#393F4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M16.3887 8.64926L9.11327 15.9247C8.22197 16.816 7.01312 17.3167 5.75264 17.3167C4.49216 17.3167 3.28331 16.816 2.39202 15.9247C1.50072 15.0334 1 13.8245 1 12.5641C1 11.3036 1.50072 10.0947 2.39202 9.20343L9.66743 1.92801C10.2616 1.33382 11.0675 1 11.9078 1C12.7482 1 13.5541 1.33382 14.1483 1.92801C14.7425 2.52221 15.0763 3.32811 15.0763 4.16843C15.0763 5.00875 14.7425 5.81465 14.1483 6.40884L6.86493 13.6843C6.56784 13.9814 6.16488 14.1483 5.74472 14.1483C5.32457 14.1483 4.92161 13.9814 4.62452 13.6843C4.32742 13.3872 4.16051 12.9842 4.16051 12.5641C4.16051 12.1439 4.32742 11.7409 4.62452 11.4438L11.3458 4.73051"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Filter = ({ color = '#393F4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
  >
    <path
      d="M22.3688 1H1.94043L10.1118 10.46V19.3333L14.1974 21.3333V10.46L22.3688 1Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronLeft = ({ color = '#393F4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="26"
    viewBox="0 0 15 26"
    fill="none"
  >
    <path
      d="M14.0448 24.4275L1.43878 12.9989L14.0448 1.57031"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Pen = ({ color = '#393F4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path d="M3.6 13.0391L2 17.9991L6.96 16.3991L3.6 13.0391Z" fill={color} />
    <path
      d="M12.5299 4.08431L4.72363 11.8906L8.11769 15.2847L15.924 7.47837L12.5299 4.08431Z"
      fill={color}
    />
    <path
      d="M17.7602 4.48L15.5202 2.24C15.2002 1.92 14.7202 1.92 14.4002 2.24L13.6802 2.96L17.0402 6.32L17.7602 5.6C18.0802 5.28 18.0802 4.8 17.7602 4.48Z"
      fill={color}
    />
  </svg>
);

export const Search = ({ color = '#393F4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
  >
    <ellipse
      cx="11.6851"
      cy="12"
      rx="7.14992"
      ry="7"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M16.7925 17.5L23.4317 24"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const Bell = ({ color = '#393F4D' }) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Tabbar/inbox_fill">
      <path
        id="Vector"
        d="M30 16.5C30 14.1131 29.0518 11.8239 27.364 10.136C25.6761 8.44821 23.3869 7.5 21 7.5C18.6131 7.5 16.3239 8.44821 14.636 10.136C12.9482 11.8239 12 14.1131 12 16.5C12 27 7.5 30 7.5 30H34.5C34.5 30 30 27 30 16.5Z"
        fill={color}
      />
      <path
        id="Vector_2"
        d="M23.5953 34.5C23.3316 34.9546 22.953 35.332 22.4976 35.5943C22.0422 35.8566 21.5258 35.9947 21.0003 35.9947C20.4747 35.9947 19.9584 35.8566 19.5029 35.5943C19.0475 35.332 18.669 34.9546 18.4053 34.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const Bin = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 3">
      <path
        id="&#226;&#134;&#179; Symbol"
        d="M15.5548 18.1041L16.1963 4.88291H17.312C17.6932 4.88291 18 4.56466 18 4.19185C18 3.81904 17.6932 3.50988 17.312 3.50988H13.063V2.14594C13.063 0.836551 12.1612 0 10.7293 0H7.25207C5.82025 0 4.92769 0.836551 4.92769 2.14594V3.50988H0.688016C0.316116 3.50988 0 3.82813 0 4.19185C0 4.56466 0.316116 4.88291 0.688016 4.88291H1.81302L2.45455 18.1132C2.51963 19.4134 3.39359 20.25 4.74174 20.25H13.2676C14.5971 20.25 15.4897 19.4044 15.5548 18.1041ZM6.41529 2.23687C6.41529 1.71857 6.79649 1.35485 7.36364 1.35485H10.6271C11.1942 1.35485 11.5847 1.71857 11.5847 2.23687V3.50988H6.41529V2.23687ZM4.8719 18.877C4.34194 18.877 3.94215 18.486 3.91426 17.9495L3.26343 4.88291H14.7087L14.095 17.9495C14.0671 18.4951 13.6767 18.877 13.1188 18.877H4.8719ZM11.7614 17.2857C12.0682 17.2857 12.2913 17.0402 12.3006 16.6947L12.5795 7.15615C12.5888 6.81971 12.3564 6.56511 12.0403 6.56511C11.7521 6.56511 11.5103 6.82881 11.501 7.15615L11.2221 16.6856C11.2128 17.022 11.4452 17.2857 11.7614 17.2857ZM6.24793 17.2857C6.56405 17.2857 6.79649 17.022 6.78719 16.6856L6.49897 7.15615C6.48967 6.82881 6.24793 6.56511 5.95971 6.56511C5.64359 6.56511 5.41116 6.81971 5.42045 7.15615L5.69938 16.6947C5.70868 17.0402 5.93182 17.2857 6.24793 17.2857ZM9 17.2857C9.30682 17.2857 9.56715 17.022 9.56715 16.6947V7.15615C9.56715 6.82881 9.30682 6.56511 9 6.56511C8.70248 6.56511 8.44215 6.82881 8.44215 7.15615V16.6947C8.44215 17.022 8.70248 17.2857 9 17.2857Z"
        fill="#FF967E"
      />
    </g>
  </svg>
);

export const Radio = ({ checked = false }) => {
  if (checked) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M24.9183 12.6506C24.9183 19.0625 19.5868 24.3012 12.9591 24.3012C6.33144 24.3012 1 19.0625 1 12.6506C1 6.23873 6.33144 1 12.9591 1C19.5868 1 24.9183 6.23873 24.9183 12.6506Z"
          fill="#FF7A44"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M18.6288 7.38281L10.5293 18.4521L6.47961 13.7081"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M23.553 13.1434C23.553 19.3158 18.5659 24.3141 12.4208 24.3141C6.27562 24.3141 1.28857 19.3158 1.28857 13.1434C1.28857 6.97097 6.27562 1.97266 12.4208 1.97266C18.5659 1.97266 23.553 6.97097 23.553 13.1434Z"
        fill="white"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
    </svg>
  );
};

import { useDispatch } from 'react-redux';

export default () => {
  const dispatch = useDispatch();
  const handleOpenLoading = () => {
    dispatch({
      type: 'APP_LOADING_OPEN'
    });
  };
  const handleCloseLoading = () => {
    dispatch({
      type: 'APP_LOADING_CLOSE'
    });
  };

  return {
    open: handleOpenLoading,
    close: handleCloseLoading
  };
};

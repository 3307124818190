import React from 'react';
import PropTypes from 'prop-types';
import { Table as AntTable, Pagination } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Style from './style';

const Table = (props) => {
  const {
    columns = [],
    dataSource,
    rowKey,
    rowSelection,
    pagination,
    scroll,
    onChange,
    loading
  } = props;
  return (
    <Style>
      <AntTable
        dataSource={dataSource}
        rowSelection={rowSelection}
        pagination={false}
        rowKey={rowKey}
        columns={columns}
        loading={loading}
        scroll={scroll}
        onChange={onChange}
      />
      {!isEmpty(pagination) && dataSource.length > 0 && (
        <div className="wrap-pagination">
          <Pagination
            current={get(pagination, 'current')}
            pageSize={get(pagination, 'pageSize')}
            total={get(pagination, 'total', 1)}
            onChange={get(pagination, 'onChange')}
          />
        </div>
      )}
    </Style>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rowKey: PropTypes.string.isRequired,
  rowSelection: PropTypes.shape(),
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func
  }),
  actions: PropTypes.shape({
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
  }),
  scroll: PropTypes.shape()
};

Table.defaultProps = {
  rowSelection: undefined,
  pagination: {},
  scroll: undefined,
  actions: undefined,
  loading: false,
  onChange: () => {}
};

export default Table;

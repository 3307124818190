import styled from '@emotion/styled';

export default styled.div`
  label: component-table;
  .ant-table-wrapper {
    border-bottom: 2px solid #e3f1ff;
    padding-bottom: 30px;
  }
  .ant-table-thead > tr > th {
    background: #fff;
    border-bottom: 2px solid #a6d4ff;
    border-top: 1px solid #a6d4ff;
    padding: 6px 16px;
    font-family: Kanit;
    font-weight: 500;
    font-size: 16px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-tbody > tr > td {
    font-family: Kanit;
  }
  .wrap-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    .ant-pagination-item {
      border-radius: 8px;
      overflow: hidden;
      border: 2px solid #ffe0c2;
      a {
        color: rgba(57, 57, 57, 0.7);
      }
      &.ant-pagination-item-active {
        a {
          background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
          color: #fff;
        }
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      border: none;
      button {
        background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
        border-radius: 10px;
        color: #fff;
        border: none;
        &:disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        height: 30px;
        min-height: unset;
        border: 1px solid #ffe0c2;
      }
    }
  }
`;

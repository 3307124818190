import styled from '@emotion/styled';

export default styled.div`
  label: component-node-incident-detail;
  font-family: Kanit;
  .wrap-item {
    padding: 30px;
    margin-bottom: 30px;
    background: #ffffff;
    border: 2px solid #ffe0c2;
    box-sizing: border-box;
    border-radius: 20px;
    > .header-name {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 16px;
    }
    .medias {
      margin-bottom: 20px;
    }
    .content {
      margin-bottom: 30px;
    }
    .time .date-time {
      font-size: 16px;
      color: rgba(57, 57, 57, 0.7);

      b {
        font-weight: 300;
      }
    }
  }
`;

import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const DebounceSelect = (props) => {
  const { fetchOptions, debounceTimeout } = props;
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      {...props}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : 'Data not found'}
      options={options}
      showSearch
      size="large"
    />
  );
};

DebounceSelect.propTypes = {
  onChange: PropTypes.func,
  fetchOptions: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
  value: PropTypes.string,
};

DebounceSelect.defaultProps = {
  debounceTimeout: 800,
  onChange: () => null,
  value: '',
};

export default DebounceSelect;

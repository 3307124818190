import React from 'react';
import PropTypes from 'prop-types';

import Style from './style';

import Image from '../Image';

const IconClose = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4321 16.9319L3.5744 4.07406"
      stroke="#393F4D"
      strokeOpacity="0.5"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3.57422 16.9319L16.4319 4.07406"
      stroke="#393F4D"
      strokeOpacity="0.5"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const MediaPreview = ({ src, mimeType }) => {
  const [isPewview, setIsPewview] = React.useState(false);
  const isVideo = mimeType && /^video/.test(mimeType);
  const mediaPreview = () => {
    if (isVideo) {
      return (
        <video controls>
          <source src={src} type={mimeType} />
          <track kind="captions" />
        </video>
      );
    }
    return <Image src={src} />;
  };

  const renderMedia = () => {
    let media = '';
    if (isVideo) {
      media = (
        <video>
          <source src={`${src}#t=2.0`} type={mimeType} />
          <track kind="captions" />
        </video>
      );
    } else {
      media = <Image src={src} />;
    }

    return (
      <>
        <button
          type="button"
          className="wrap-media"
          onClick={() => setIsPewview(true)}
        >
          {media}
        </button>
      </>
    );
  };

  return (
    <Style>
      {renderMedia()}

      <div className={`preview-media ${isPewview ? 'active' : ''}`}>
        <button
          className="close"
          type="button"
          onClick={() => {
            setIsPewview(false);
          }}
        >
          <IconClose />
        </button>
        {isPewview && (
          <div className="content">
            {mediaPreview()}
          </div>
        )}
      </div>
    </Style>
  );
};

MediaPreview.propTypes = {
  src: PropTypes.string,
  mimeType: PropTypes.string
};

MediaPreview.defaultProps = {
  src: '',
  mimeType: ''
};

export default MediaPreview;

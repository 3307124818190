import styled from '@emotion/styled';

export default styled.div`
  label: component-media-preview;
  margin: 0 -12px;
  > button.wrap-media {
    max-width: 152px;
    max-height: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    margin: 10px 12px;
    video {
      max-width: 100%;
      max-height: 100%;
    }
    img {
      max-height: 160px;
    }
  }
  .preview-media {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #000;
    padding: 20px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    &.active {
      visibility: visible;
      opacity: 1;
    }
    > button.close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      background: none;
      border: none;
      outline: none;
      svg {
        width: 30px;
        height: 30px;
        path {
          stroke: #fff;
          stroke-width: 2px;
          stroke-opacity: 1;
        }
      }
    }
    img,
    video {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { Modal, Form, Dropdown, Menu, Input, DatePicker, Row, Col } from 'antd';

import { LayoutMenu } from '../../components/Layout';
import Button from '../../components/Button';
import useLoading from '../../hooks/loading';
import * as Icon from '../../components/Icons';
import Table from '../../components/Table';
import InputPhone from '../../components/InputPhone';

import { getSicidalPersons } from '../../service';
import { getThaiDate, getSortValue, dateToFormat } from '../../utils';

import Style from './style';

const MedicalRecord = (props) => {
  const { history } = props;
  const defaultPageLimit = 10;
  const loading = useLoading();
  const inputEl = React.useRef(null);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [dataFilter, setDataFilter] = React.useState({
    page: 1,
    pageLimit: defaultPageLimit,
    sort: 'updatedAt|desc'
  });

  const [state, setState] = React.useState({
    total: 1,
    rows: []
  });

  const loadData = async (query) => {
    setLoading(true);
    const { page, pageLimit = defaultPageLimit, sort = {} } = query;
    const pageSize = Number(pageLimit);
    try {
      const keyword = get(inputEl, 'current.value', '');
      const body = {
        offset: Number(page) * pageSize - pageSize,
        limit: pageSize,
        keyword,
        ...omit(query, ['page', 'pageLimit', 'sort']),
        ...getSortValue(sort)
      };
      const res = await getSicidalPersons(body);
      setState({
        ...state,
        total: get(res, 'meta.total', 1),
        rows: get(res, 'data', [])
      });
    } catch (err) {
      console.log('loadData err', err);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loading.close();
    loadData(dataFilter);
  }, []); // eslint-disable-line

  const handleFilter = () => {
    loadData({
      page: 1,
      pageLimit: defaultPageLimit
    });
  };

  const handlePageChange = (page, pageLimit) => {
    const body = {
      ...dataFilter,
      page,
      pageLimit
    };
    setDataFilter(body);
    loadData(body);
  };

  const handleSortChange = (e) => {
    const body = {
      ...dataFilter,
      page: 1,
      pageLimit: 10,
      sort: e.key
    };
    setDataFilter(body);
    loadData(body);
  };

  const onFinish = (values) => {
    const query = {
      sort: dataFilter.sort,
      page: 1,
      pageLimit: defaultPageLimit,
      telephone: get(values, 'telephone'),
      firstName: get(values, 'firstName'),
      lastName: get(values, 'lastName'),
      updatedAtFrom: dateToFormat(get(values, 'updatedAt.0')),
      updatedAtTo: dateToFormat(get(values, 'updatedAt.1'))
    };
    loadData({
      ...query
    });
    setIsModalVisible(false);
  };

  const handleUpdate = (row) => {
    loading.open();
    setTimeout(() => {
      history.push(`/medical-record/${row.id}/detail`);
    }, 500);
  };

  const columns = [
    {
      title: 'ชื่อ - สกุล',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (value, row) => {
        if (isEmpty('firstName')) return 'ไม่มีข้อมูล';
        return `${value} ${get(row, 'lastName', '')}`.trim();
      }
    },
    {
      title: 'เบอร์ติดต่อ',
      dataIndex: 'telephone',
      key: 'telephone'
    },
    {
      title: 'อัปเดตล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value) => getThaiDate(value)
    },
    {
      title: 'ดูรายละเอียด',
      dataIndex: 'action',
      key: 'status',
      render: (value, row) => {
        return (
          <div className="wrap-action ">
            <Button
              primary
              size="sm"
              onClick={() => {
                handleUpdate(row);
              }}
            >
              <span>ดูรายละเอียด</span>

              <Icon.Search color="#FFFFFF" />
            </Button>
          </div>
        );
      }
    }
  ];

  const menuSort = (
    <Menu onClick={handleSortChange} selectedKeys={[dataFilter.sort]}>
      <Menu.Item key="updatedAt|asc">อัปเดตล่าสุด (เก่า ไป ใหม่)</Menu.Item>
      <Menu.Item key="updatedAt|desc">อัปเดตล่าสุด (ใหม่ ไป เก่า)</Menu.Item>
    </Menu>
  );

  return (
    <LayoutMenu>
      <Style>
        <div className="header">
          <div className="input-search">
            <div className="icon">
              <Icon.Search />
            </div>
            <input
              ref={inputEl}
              type="text"
              placeholder="ค้นหา"
              autoComplete="off"
              onKeyPress={(event) => {
                const keyCode = event.which || event.keyCode;
                if (keyCode === 13) {
                  handleFilter();
                }
              }}
            />
          </div>

          <div className="action">
            <Dropdown overlay={menuSort} trigger={['click']}>
              <Button>
                <Icon.SortAmountDown color="#FF7A44" />
                <span>เรียงตาม</span>
              </Button>
            </Dropdown>

            <Button
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              <Icon.Filter color="#FF7A44" />
              <span>ตัวกรอง</span>
            </Button>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          loading={isLoading}
          dataSource={get(state, 'rows', [])}
          pagination={{
            current: get(dataFilter, 'page', 1),
            pageSize: get(dataFilter, 'pageLimit', 10),
            total: get(state, 'total', 1),
            onChange: handlePageChange
          }}
        />
      </Style>
      <Modal
        title="ค้นหาข้อมูล"
        width={700}
        footer={false}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Row>

            <Col md={12}>
              <Form.Item name="firstName" label="ชื่อ">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="lastName" label="สกุล">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="telephone" label="เบอร์ติดต่อ">
                <InputPhone />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="updatedAt" label="ช่วงวันที่อัปเดตล่าสุด">
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <div className="wrap-button-filter">
            <Button
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              ยกเลิก
            </Button>
            <Button primary type="submit">
              ยืนยัน
            </Button>
          </div>
        </Form>
      </Modal>
    </LayoutMenu>
  );
};

MedicalRecord.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

MedicalRecord.defaultProps = {
  // children: ''
};

export default MedicalRecord;

import styled from '@emotion/styled';

export default styled.button`
  label: component-button;
  text-align: center;
  padding: 0 16px;
  font-family: Kanit;
  background: #fff;
  color: #ee6538;
  border: 1px solid #ff7a44;
  cursor: pointer;
  &.sm {
    height: 30px;
    border-radius: 20px;
    min-width: 100px;
    font-weight: 300;
    font-size: 14px;
  }
  &.md {
    height: 40px;
    border-radius: 30px;
    min-width: 140px;
    font-weight: 300;
    font-size: 16px;
  }
  &.lg {
    height: 60px;
    border-radius: 30px;
    min-width: 180px;
    font-weight: 500;
    font-size: 20px;
  }
  &.primary {
    background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
    box-shadow: 0px 4px 10px #ffe0c2;
    border: none;
    color: #fff;
  }
  &.info {
    background: linear-gradient(180deg, #a6d4ff 0%, #94cbff 100%);
    box-shadow: 0px 4px 10px;
    border: none;
    color: #fff;
  }
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  transform: scale(1);
  transition: all 0.4s ease-in-out;
  &:active {
    transform: scale(0.85);
  }
`;

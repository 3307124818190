import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../../components/Breadcrumb';

import useLoading from '../../hooks/loading';
import { createUser } from '../../service';

import FormData from './Form';
import { StylePageInfo as Style } from './style';

const Create = (props) => {
  const { history } = props;

  const loading = useLoading();

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleSubmit = async (body) => {
    loading.open();
    try {
      await createUser(body);
      history.push('/users');
    } catch (err) {
      console.log('error ', err);
    }
    loading.close();
  };

  const handlePageBack = () => {
    history.push('/users');
  };

  return (
    <Style>
      <div className="container">
        <Breadcrumb title="จัดการผู้ใช้งาน" onBack={handlePageBack} />
        <FormData onSubmit={handleSubmit} />
      </div>
    </Style>
  );
};

Create.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

Create.defaultProps = {};

export default Create;

const base = process.env.REACT_APP_URL_API || 'http://65.21.242.65:3001';

export const LOGIN_LINE = `${base}/users/login-line`;
export const ACCESS_TOKEN = `${base}/users/access-tokens`;
export const MY_PROFILE = `${base}/users/me`;
export const LOGIN = `${base}/users/login`;
export const SIGNOUT = `${base}/users/signout`;
export const UPLOADS = `${base}/uploads`;
export const ROLE_USERS = `${base}/backoffice/roles`;
export const USERS = `${base}/backoffice/users`;
export const INCIDENTS = `${base}/incidents`;
export const BACKOFFICE_INCIDENTS = `${base}/backoffice/incidents`;
export const PROVINCES = `${base}/incidents/info/provinces`;
export const SEVERITY_LEVELS = `${base}/incidents/info/severity-levels`;
export const PROBLEM_TYPES = `${base}/incidents/info/problem-types`;
export const SUICIDAL_PERSONS = `${base}/backoffice/suicidal-persons`;
export const STATISTICS_PROVINCE = `${base}/statistics/by-province`;
export const STATISTICS_GENDER = `${base}/statistics/by-gender`;
export const STATISTICS_PROBLEM = `${base}/statistics/by-problem`;
export const STATISTICS_GENDER_MONTH = `${base}/statistics/by-gender-month`;
export const STATISTICS_GENDER_YEAR = `${base}/statistics/by-gender-year`;
export const STATISTICS_TOTAL_USER = `${base}/statistics/total-user`;
export const NOTIFICATIONS = `${base}/notifications`;
export const MESSAGES = `${base}/messages`;

import styled from '@emotion/styled';

export default styled.footer`
  background: #e3f1ff;
  padding: 38px 20px 40px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .logo {
    margin-bottom: 40px;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 6px 20px;
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 20px;
    }
  }
`;

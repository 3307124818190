import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Row, Col } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';

import Button from '../Button';
import InputPhone from '../InputPhone';

import useLoading from '../../hooks/loading';
import { updateIncident } from '../../service';
import { Pen } from '../Icons';
import Style from './style';

const EditRelatedPersonInfo = (props) => {
  const { data, index, value, onSuccess } = props;
  const [form] = Form.useForm();
  const loading = useLoading();

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
    form.setFields([
      {
        name: 'contactFirstName',
        value: get(value, 'contactFirstName')
      },
      {
        name: 'contactLastName',
        value: get(value, 'contactLastName')
      },
      {
        name: 'contactTelephone',
        value: get(value, 'contactTelephone')
      },
      {
        name: 'contactRelation',
        value: get(value, 'contactRelation')
      },
      {
        name: 'contactAddress',
        value: get(value, 'contactAddress')
      }
    ]);
  };

  const onFinish = async (values) => {
    loading.open();
    try {
      const body = {
        ...data,
        contacts: map(get(data, 'contacts', []), (obj, key) => {
          if (key === index) {
            return values;
          }
          return obj;
        })
      };
      await updateIncident(get(data, 'id'), body);
      setIsModalVisible(false);
      onSuccess();
    } catch (err) {
      console.log('err >>', err);
      loading.close();
    }
  };

  return (
    <>
      <Button className="with-icon" size="sm" primary onClick={showModal}>
        <span>แก้ไข</span>
        <Pen color="#FFFFFF" />
      </Button>
      <Modal
        title="แก้ไขข้อมูลผู้มีความเกี่ยวข้อง"
        visible={isModalVisible}
        centered
        width={700}
        footer={false}
        className="main-modal"
      >
        <Style>
          <div className="wrap-form">
            <Form
              name="basic"
              onFinish={onFinish}
              form={form}
              autoComplete="off"
              layout="vertical"
            >
              <Row>
                <Col md={12}>
                  <Form.Item label="ชื่อ ผู้เกี่ยวข้อง" name="contactFirstName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="นามสกุล ผู้เกี่ยวข้อง"
                    name="contactLastName"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Item
                    label="เบอร์โทรศัพท์มือถือ ผู้เกี่ยวข้อง"
                    name="contactTelephone"
                  >
                    <InputPhone />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="ความเกี่ยวข้อง" name="contactRelation">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="ที่อยู่" name="contactAddress">
                <Input.TextArea rows={4} />
              </Form.Item>
              <hr />
              <div className="wrap-button">
                <Button size="lg" onClick={showModal}>
                  ยกเลิกการแก้ไขข้อมูล
                </Button>
                <Button size="lg" type="submit" primary>
                  บันทึก
                </Button>
              </div>
            </Form>
          </div>
        </Style>
      </Modal>
    </>
  );
};

EditRelatedPersonInfo.propTypes = {
  value: PropTypes.shape(),
  data: PropTypes.shape(),
  index: PropTypes.number.isRequired,
  onSuccess: PropTypes.func
};

EditRelatedPersonInfo.defaultProps = {
  value: {},
  data: {},
  onSuccess: () => null
};

export default EditRelatedPersonInfo;

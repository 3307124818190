import styled from '@emotion/styled';

export default styled.div`
  label: page-incidents;
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;
    .input-search {
      position: relative;
      flex: 1 1 auto;
      margin-right: 20px;
      .icon {
        position: absolute;
        top: 12px;
        left: 20px;
      }
      input {
        height: 50px;
        max-width: 500px;
        background: #f2f6fb;
        border-radius: 30px;
        border: none;
        padding-left: 60px;
        padding-right: 10px;
        width: 100%;
        font-weight: 300;
        font-size: 20px;
        outline: none;
      }
    }
    .action {
      display: flex;
      button {
        display: flex;
        align-items: center;
        margin-left: 14px;
        > span {
          margin-left: 10px;
          color: #4f4f4f;
        }
      }
    }
  }
  .severity {
    padding-left: 32px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: rgba(57, 63, 77, 0.5);
      top: 0;
      left: 0;
    }
    &.level-1:after {
      background: #a6d4ff;
    }
    &.level-2:after {
      background: #ffd56a;
    }
    &.level-3:after {
      background: #ff7a44;
    }
    &.level-4:after {
      background: #e64300;
    }
  }
  .status {
    padding: 4px 20px;
    border-radius: 30px;
    font-family: Kanit;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    &.primary {
      color: #fff;
      background: #ff937e;
    }
    &.warning {
      color: #ff7a44;
      background: #feecd7;
    }
    &.info {
      color: #1172bc;
      background: #e3f1ff;
    }
    &.secondary {
      color: #393f4d;
      background: #e5e5e5;
    }
  }
  .wrap-action {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-right: 10px;
      }
    }
  }
`;

export const StyleCreate = styled.div`
  .time {
    margin: 10px 12px 30px 54px;
    > div .date-time {
      color: rgba(57, 57, 57, 0.7);
      font-weight: 300;
      font-size: 20px;
      b {
        font-weight: 300;
      }
    }
  }
`;

import styled from '@emotion/styled';

export default styled.div`
  label: component-caseLinkMedicalRecord;
  padding: 20px;
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;
    .input-search {
      position: relative;
      flex: 1 1 auto;
      margin-right: 20px;
      .icon {
        position: absolute;
        top: 12px;
        left: 20px;
      }
      input {
        height: 50px;
        max-width: 500px;
        background: #f2f6fb;
        border-radius: 30px;
        border: none;
        padding-left: 60px;
        padding-right: 10px;
        width: 100%;
        font-weight: 300;
        font-size: 20px;
        outline: none;
      }
    }
    .action {
      display: flex;
      button {
        display: flex;
        align-items: center;
        margin-left: 14px;
        > span {
          margin-left: 10px;
          color: #4f4f4f;
        }
      }
    }
  }
`;

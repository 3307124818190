import styled from '@emotion/styled';

export default styled.div`
  label: component-update-at;
  display: flex;
  align-items: flex-start;
  font-family: Kanit;
  > svg {
    margin-top: 6px;
  }
  > div {
    margin-left: 8px;
    .date-time {
      font-size: 20px;
      color: #393f4d;
    }
    .user-info {
      display: flex;
      align-items: center;
      .wrap-image {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 12px;
      }
      .name {
        font-weight: 300;
        font-size: 16px;
        color: #393f4d;
        margin-bottom: 4px;
      }
      .role {
        font-weight: 300;
        font-size: 12px;
        color: rgba(57, 63, 77, 0.5);
      }
    }
  }
`;

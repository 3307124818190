import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { useSelector } from 'react-redux';

const Home = (props) => {
  const { history } = props;
  const roleUser = useSelector(({ user }) => get(user, 'authUser.roleIds'));

  React.useEffect(() => {
    const isAdmin = includes(roleUser, 1);
    console.log('roleUser >>', roleUser);
    if (isAdmin) {
      history.push('/incidents');
    } else if (includes(roleUser, 4)) {
      history.push('/incidents/me');
    } else {
      history.push('/incidents');
    }
  }, []); // eslint-disable-line

  return '';
};

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default Home;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Radio, Select, Row, Col } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from '../../../components/Button';
import UploadImageProfile from '../../../components/UploadImageProfile';
import InputPhone from '../../../components/InputPhone';
import * as Icon from '../../../components/Icons';

import { getRoleUsers } from '../../../service';

import Style from './style';

const FormData = (props) => {
  const { defaultValue, isUpdate, onSubmit, id } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const userLogin = useSelector(({ user }) => {
    return get(user, 'authUser', {});
  });
  const [roles, setRoles] = React.useState([]);
  const [isDisableForm, setIsDisableForm] = React.useState(isUpdate);

  const initPage = async () => {
    try {
      const res = await getRoleUsers();
      setRoles(get(res, 'data', []));
      form.setFields([
        {
          name: 'profileImageUrl',
          value: get(defaultValue, 'profileImageUrl', '')
        },
        {
          name: 'firstName',
          value: get(defaultValue, 'firstName', '')
        },
        {
          name: 'lastName',
          value: get(defaultValue, 'lastName', '')
        },
        {
          name: 'gender',
          value: get(defaultValue, 'gender', 'other')
        },
        {
          name: 'lineId',
          value: get(defaultValue, 'providerLoginId', '')
        },
        {
          name: 'email',
          value: get(defaultValue, 'email', '')
        },
        {
          name: 'roleIds',
          value: get(defaultValue, 'roleIds', '')
        },
        {
          name: 'status',
          value: get(defaultValue, 'status', '')
        }
      ]);
    } catch (err) {
      console.err('initPage profile', err);
    }
  };

  React.useEffect(() => {
    initPage();
  }, [id]); // eslint-disable-line

  const onFinish = (values) => {
    console.log('Success:', values);
    onSubmit(values);
  };

  const handleCancle = () => {
    if (isUpdate) setIsDisableForm(true);
    history.push('/users');
  };

  let isAllowEdit = isUpdate;
  if (
    includes(get(userLogin, 'roleIds', []), 1) ||
    get(userLogin, 'id') === get(defaultValue, 'id', '')
  ) {
    isAllowEdit = true;
  }

  const isEditForm = !isDisableForm && isAllowEdit;
  return (
    <Style>
      <div className="title">
        {!isUpdate && <span>เพิ่มสมาชิก</span>}
        {isUpdate && (
          <span>
            ประวัติของ{' '}
            {`${get(defaultValue, 'firstName', '')} ${get(
              defaultValue,
              'lastName',
              ''
            )}`.trim()}
          </span>
        )}

        {isUpdate && isAllowEdit && (
          <Button
            className=""
            size="sm"
            onClick={() => setIsDisableForm(false)}
          >
            แก้ไข <Icon.Pen color="#EE6538" />
          </Button>
        )}
      </div>
      <div className="wrap-form">
        <Form
          name="form-user"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Row>
            <Col md={6}>
              <Form.Item name="profileImageUrl">
                <UploadImageProfile disabled={!isEditForm} />
              </Form.Item>
            </Col>
            <Col md={18}>
              <Row>
                <Col md={12}>
                  <Form.Item
                    label="ชื่อ"
                    name="firstName"
                    rules={
                      isEditForm && [
                        {
                          required: true,
                          message: 'กรุณาระบุชื่อ'
                        }
                      ]
                    }
                  >
                    <Input disabled={!isEditForm} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="นามสกุล"
                    name="lastName"
                    rules={
                      !isUpdate && [
                        {
                          required: true,
                          message: 'กรุณาระบุนามสกุล'
                        }
                      ]
                    }
                  >
                    <Input disabled={!isEditForm} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="เพศ" name="gender">
                <Radio.Group disabled={!isEditForm}>
                  <Radio value="male">ชาย</Radio>
                  <Radio value="female">หญิง</Radio>
                  <Radio value="other">ไม่ระบุ</Radio>
                </Radio.Group>
              </Form.Item>
              <Row>
                <Col md={12}>
                  <Form.Item label="เบอร์โทรศัพท์มือถือ" name="telephone">
                    <InputPhone disabled={!isEditForm} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Line ID"
                    name="lineId"
                    rules={
                      isEditForm && [
                        {
                          required: true,
                          message: 'กรุณาระบุ Line ID'
                        }
                      ]
                    }
                  >
                    <Input disabled={!isEditForm} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={
                      !isUpdate && [
                        {
                          required: true,
                          message: 'กรุณาระบุนามสกุล'
                        }
                      ]
                    }
                  >
                    <Input
                      placeholder="abc@xyz.com"
                      autoComplete="off"
                      type="email"
                      disabled={!isEditForm}
                    />
                  </Form.Item>
                </Col>
                {!isUpdate && (
                  <Col md={12}>
                    <Form.Item
                      label="รหัสผ่าน"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาระบุรหัสผ่าน'
                        }
                      ]}
                    >
                      <Input.Password autoComplete="off" />
                    </Form.Item>
                  </Col>
                )}
                <Col md={12}>
                  <Form.Item
                    label="ประเภทผู้ใช้งาน"
                    name="roleIds"
                    rules={
                      isEditForm && [
                        {
                          required: true,
                          message: 'กรุณาระบุประเภทผู้ใช้งาน'
                        }
                      ]
                    }
                  >
                    <Select
                      disabled={!isEditForm}
                      placeholder="select"
                      mode="multiple"
                    >
                      {map(roles, (role) => {
                        return (
                          <Select.Option
                            key={get(role, 'id')}
                            value={get(role, 'id')}
                          >
                            {get(role, 'name')}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="สถานะของผู้ใช้งาน"
                    name="status"
                    rules={
                      isEditForm && [
                        {
                          required: true,
                          message: 'กรุณาระบุสถานะของผู้ใช้งาน'
                        }
                      ]
                    }
                  >
                    <Select disabled={!isEditForm} placeholder="select">
                      <Select.Option value="wait_verify">
                        รอยืนยัน
                      </Select.Option>
                      <Select.Option value="verified">เป็นสมาชิก</Select.Option>
                      <Select.Option value="disabled">
                        ปิดการเป็นสมาชิก
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          {isEditForm && (
            <div className="wrap-button">
              <Button onClick={handleCancle}>ยกเลิก</Button>
              <Button type="submit" primary>
                บันทึก
              </Button>
            </div>
          )}
        </Form>
      </div>
    </Style>
  );
};

FormData.propTypes = {
  defaultValue: PropTypes.shape(),
  isUpdate: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

FormData.defaultProps = {
  defaultValue: {},
  isUpdate: false,
  id: ''
};

export default FormData;

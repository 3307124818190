import React from 'react';
import PropTypes from 'prop-types';

import useLoading from '../../hooks/loading';

import Icon from './Icon';
import Style from './style';

const PageNotFound = () => {
  const loading = useLoading();
  React.useEffect(() => {
    loading.close();
    return () => {
      loading.open();
    };
  }, []); // eslint-disable-line

  return (
    <Style>
      <div className="container">
        <Icon />
        <h1>404</h1>
        <p className="message">ไม่พบหน้าที่ต้องการ</p>
      </div>
    </Style>
  );
};

PageNotFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

PageNotFound.defaultProps = {};

export default PageNotFound;

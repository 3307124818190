import styled from '@emotion/styled';

export default styled.div`
  label: component-action-incident-detail;
  font-family: Kanit;
  .item-detail {
    margin-bottom: 30px;
  }
  .wrap-content {
    background: #ffffff;
    border: 2px solid #ffe0c2;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 30px;
  }
  .title-item {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 22px;
  }
  .ant-row {
    margin: 0 12px 16px 12px;
  }
  .time {
    .date-time {
      font-size: 16px;
      color: rgba(57, 57, 57, 0.7);

      b {
        font-weight: 300;
      }
    }
  }
`;

import styled from '@emotion/styled';

export default styled.div`
  label: page-message;
  display: flex;
  align-items: flex-start;

  > .list {
    flex: 0 0 350px;
    padding: 0 16px;
    height: calc(100vh - 360px);
    border-right: 1px solid #edf2f7;
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 20px;
      font-weight: 500;
      padding: 24px;
      border-bottom: 1px solid #edf2f7;
      .count {
        font-size: 12px;
        font-weight: 300;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #edf2f7;
        margin-left: 10px;
      }
    }
    .wrap-search {
      margin-top: 12px;
      .wrap-input-search {
        position: relative;
        margin-bottom: 24px;
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
        }
        input {
          display: block;
          height: 48px;
          background: #f3f3f3;
          padding-left: 44px;
          padding-right: 10px;
          width: 100%;
          border: none;
          border-radius: 10px;
        }
      }
      .group-button {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: 8px;
          }
        }
      }
    }
    .wrap-customer {
      ul {
        li {
          button {
            background: none;
            border: none;
            padding: 12px;
            display: block;
            width: 100%;
            .id {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 4px;
              .time {
                font-size: 10px;
                font-weight: 300;
              }
            }
            .name {
              display: block;
              text-align: left;
              font-size: 16px;
              color: #393f4d;
              margin-bottom: 8px;
            }
            .tag {
              display: block;
              text-align: left;
              > .new {
                background: #ff937e;
                color: #fff;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 58px;
                height: 26px;
              }
            }
          }
          &.active button {
            background: #fff1ed;
          }
        }
      }
    }
  }
  > .wrap-chat {
    flex: 1 1 auto;
    height: calc(100vh - 360px);
  }
`;

export const StylePageInfo = styled.div``;

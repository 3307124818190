import React from 'react';

export default () => (
  <svg
    width="112"
    height="38"
    viewBox="0 0 112 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16.0621C0 11.0687 0 6.07535 0 1.04654C0 0.232016 0.211372 0.0195312 0.986403 0.0195312C2.14895 0.0195312 3.3115 0.0195312 4.47404 0.0195312C5.24907 0.0195312 5.46044 0.26743 5.46044 1.01113C5.46044 4.6942 5.46044 8.34186 5.46044 12.0249C5.46044 12.5207 5.56613 12.6978 6.09456 12.6978C9.72311 12.6624 13.3164 12.6978 16.945 12.6978C17.4382 12.6978 17.6143 12.5561 17.6143 12.0249C17.5791 8.34186 17.6143 4.65879 17.6143 0.975714C17.6143 0.302845 17.8609 0.0549454 18.5655 0.0549454C19.7985 0.0549454 20.9963 0.0549454 22.2293 0.0549454C22.8634 0.0549454 23.1452 0.338259 23.1452 0.975714C23.1452 11.0687 23.1452 21.1618 23.1452 31.2548C23.1452 31.8214 22.8634 32.1048 22.2998 32.1048C21.0315 32.1048 19.7985 32.1048 18.5303 32.1048C17.8962 32.1048 17.6143 31.8569 17.6143 31.184C17.6143 27.0759 17.6143 22.9325 17.6143 18.8245C17.6143 18.2932 17.4734 18.1516 16.945 18.1516C13.3517 18.187 9.79357 18.1516 6.20025 18.1516C5.49567 18.1516 5.49567 18.1516 5.49567 18.8599C5.49567 22.8971 5.49567 26.9343 5.49567 30.9361C5.49567 31.8923 5.31953 32.0693 4.36836 32.0693C3.24104 32.0693 2.11372 32.0693 0.986403 32.0693C0.211372 32.0693 0 31.8569 0 31.0778C0 26.0844 0 21.091 0 16.0621Z"
      fill="#1172BA"
    />
    <path
      d="M90.0798 16.0619C90.0798 12.4851 90.0798 8.94364 90.0798 5.36681C90.0798 2.35661 92.4049 0.0546875 95.3993 0.0546875C100.507 0.0546875 105.651 0.0546875 110.759 0.0546875C111.499 0.0546875 111.71 0.302587 111.71 1.01087C111.71 2.21495 111.71 3.38362 111.71 4.5877C111.71 5.3314 111.464 5.54388 110.759 5.54388C106.003 5.54388 101.247 5.54388 96.4914 5.54388C95.5754 5.54388 95.5402 5.61471 95.5402 6.53548C95.5402 8.37702 95.5402 10.2186 95.5402 12.0247C95.5402 12.5559 95.7164 12.6975 96.2096 12.6975C99.5915 12.6975 102.973 12.6975 106.355 12.6975C107.13 12.6975 107.342 12.91 107.342 13.6891C107.342 14.8224 107.342 15.9556 107.342 17.0889C107.342 18.0097 107.166 18.1513 106.285 18.1513C102.973 18.1513 99.6267 18.1513 96.3152 18.1513C95.5402 18.1513 95.5402 18.1513 95.5402 18.9304C95.5402 21.1969 95.5402 23.4635 95.5402 25.73C95.5402 26.4737 95.6459 26.6153 96.4209 26.6153C101.212 26.6153 105.968 26.6153 110.759 26.6153C111.534 26.6153 111.745 26.8278 111.745 27.6069C111.745 28.811 111.745 29.9797 111.745 31.1837C111.745 31.8566 111.499 32.1045 110.865 32.1045C105.651 32.1045 100.402 32.1045 95.1879 32.1045C92.4401 32.1045 90.1502 29.7318 90.1502 26.9695C90.0798 23.3218 90.0798 19.6741 90.0798 16.0619Z"
      fill="#1172BA"
    />
    <path
      d="M60.2411 18.1867C60.2411 13.9725 60.2411 9.75817 60.2411 5.54388C60.2411 2.78158 61.9321 0.656728 64.539 0.125516C64.8561 0.0546875 65.1731 0.0546875 65.4902 0.0546875C68.0266 0.0546875 70.5631 0.0546875 73.0996 0.0546875C77.7145 0.0901017 81.6249 3.70235 82.0124 8.27077C82.4352 12.9809 79.2646 17.1597 74.6849 18.0451C74.086 18.1513 73.4519 18.1867 72.8177 18.1867C70.6688 18.1867 68.5198 18.2222 66.3709 18.1867C65.8777 18.1867 65.7015 18.3284 65.7015 18.8596C65.7368 22.9322 65.7015 27.0403 65.7015 31.1129C65.7015 31.9274 65.4902 32.1399 64.6799 32.1399C63.4821 32.1399 62.3196 32.1399 61.1218 32.1399C60.4525 32.1399 60.2059 31.892 60.2059 31.1837C60.2411 26.8278 60.2411 22.5073 60.2411 18.1867ZM69.8585 5.50847C68.7664 5.50847 67.6391 5.50847 66.547 5.50847C65.8072 5.50847 65.772 5.5793 65.772 6.32299C65.772 8.19995 65.772 10.1123 65.772 11.9893C65.772 12.6975 65.772 12.6975 66.5118 12.6975C68.696 12.6975 70.8802 12.6975 73.0643 12.6975C75.1076 12.6975 76.6577 11.1747 76.6929 9.15613C76.6929 7.13752 75.1428 5.54388 73.0996 5.54388C71.9723 5.50847 70.9154 5.50847 69.8585 5.50847Z"
      fill="#1172BA"
    />
    <path
      d="M42.521 5.71995C42.7324 5.40123 42.9085 5.0825 43.1551 4.8346C44.6347 3.45345 46.3257 2.46185 48.369 2.32019C50.9759 2.14312 52.8078 3.5951 53.2657 6.18034C53.6533 8.41143 53.3362 10.5717 52.4203 12.6257C50.6941 16.5921 48.9326 20.5585 47.0655 24.454C45.1632 28.385 43.2608 32.316 42.1335 36.5657C41.9926 37.1323 41.746 37.6635 41.0766 37.7698C40.3721 37.876 39.8789 37.5219 39.4913 36.9552C38.9277 36.1407 38.7868 35.2554 39.0334 34.2992C40.1255 30.3328 41.8164 26.5789 43.4722 22.8604C45.445 18.4691 47.453 14.1131 49.4611 9.72176C49.6372 9.3322 49.7781 8.90723 49.919 8.51767C50.0952 7.98646 49.9543 7.70315 49.3906 7.73856C48.6508 7.77398 47.8758 7.80939 47.2064 8.0927C45.1279 8.90723 43.6836 10.3946 42.979 12.5549C42.6267 13.6173 42.0983 14.1131 41.3937 14.0777C40.6539 14.0069 40.0198 13.2986 39.8789 12.2716C39.7379 11.3508 39.6675 10.4655 39.5266 9.54468C39.3504 8.51767 39.0686 7.56149 38.4345 6.71155C37.2015 5.04709 35.3696 4.90543 33.9252 6.35741C32.5161 7.80939 32.2695 9.54468 32.657 11.457C33.1502 13.8652 34.348 15.9192 35.7923 17.8316C36.8492 19.2128 37.9413 20.5585 39.0334 21.9396C39.9141 23.0729 39.9141 24.3124 39.4913 25.5519C39.2095 26.4372 38.2231 26.7206 37.5538 26.0831C33.8548 22.6125 30.8603 18.717 29.733 13.6527C28.9932 10.4655 28.9932 7.24276 30.4023 4.19714C31.2126 2.42644 32.4104 0.974456 34.2775 0.301587C36.1798 -0.371282 37.9061 0.124516 39.4561 1.36401C40.6539 2.32019 41.4641 3.5951 41.9926 5.04709C42.0278 5.18874 42.0983 5.3304 42.1687 5.43664C42.2039 5.47205 42.2744 5.50747 42.521 5.71995Z"
      fill="#EA6338"
    />
  </svg>
);

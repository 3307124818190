import styled from '@emotion/styled';

export default styled.div`
  label: component-country;
  font-family: Kanit;
  position: relative;
  > .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrap-hrader-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    > .title {
      font-weight: 600;
      font-size: 15px;
      color: #495057;
    }
  }
  .wrap-type {
    .ant-radio-group {
      /* margin: 0 -10px; */
      .ant-radio-button-wrapper {
        /* margin: 0 10px; */
        width: 70px;
        text-align: center;
        color: #495057;
        border: none;
        border-radius: 20px;
        &.ant-radio-button-wrapper-checked {
          background: #ff7a44;
          color: #fff;
        }
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        content: unset;
      }
    }
  }
  .wrap-chart {
    text {
      font-family: Kanit;
      font-weight: 300;
    }
  }
  .legend {
    ul {
      display: flex;
      justify-content: center;
      li {
        padding-left: 22px;
        position: relative;
        font-size: 14px;
        color: #393f4d;
        margin: 4px 10px;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 100%;
          width: 15px;
          height: 15px;
          background: #eee;
        }
        &.female {
          &:after {
            background: #ff937e;
          }
        }
        &.male {
          &:after {
            background: #ffd56a;
          }
        }
        &.other {
          &:after {
            background: #a6d4ff;
          }
        }
      }
    }
  }
`;

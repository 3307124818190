import styled from '@emotion/styled';

export default styled.div`
  label: page-users;
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
    }
    .action {
      display: flex;
      button {
        display: flex;
        align-items: center;
        margin-left: 14px;
        &.button-sort span {
          color: #4f4f4f;
        }
      }
    }
  }
  .user-status {
    &.wait-verify {
      color: #ff7a44;
    }
    &.verified {
      color: #000000;
    }
    &.disabled {
      color: rgba(57, 63, 77, 0.5);
    }
  }
  .row-user-info {
    display: flex;
    align-items: center;
    .wrap-image {
      height: 40px;
      width: 40px;
      margin-right: 20px;
      border-radius: 100%;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .wrap-action {
    button {
      background: none;
      outline: none;
      border: none;
      color: #393939;
      font-size: 18px;
      &.delete {
        color: #ff7a44;
      }
    }
  }
`;

export const StylePageInfo = styled.div``;

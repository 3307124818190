import React from 'react';
// import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import liff from '@line/liff';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import useLoading from '../../hooks/loading';
import { login } from '../../service';
import { loginSuccess } from '../../actions/action';

import Style from './style';

const Login = () => {
  const loading = useLoading();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleLoginError = () => {
    form.setFields([
      {
        name: 'password',
        value: undefined,
        errors: ['กรุณาตรวจสอบ E-mail หรือ รหัสผ่าน'],
      },
    ]);
  };

  const onFinish = async (values) => {
    loading.open();
    try {
      const res = await login(values);
      dispatch(loginSuccess(get(res, 'data', {})));
    } catch (err) {
      handleLoginError();
      console.log('login err', err);
      loading.close();
    }
  };

  const handleLoginWithLine = async () => {
    try {
      await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
      liff.login({ redirectUri: process.env.REACT_APP_BASE_URL });
    } catch (err) {
      console.log('initLiff', err);
    }
  };

  return (
    <Style>
      <Header />
      <div className="wrap-form">
        <Form
          form={form}
          name="form-login"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            email: 'superadmin@local.com',
            password: '1234',
          }}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder="E-mail address" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="password" />
          </Form.Item>
          <button type="submit" className="button-login">
            Log in
          </button>
        </Form>
        <div className="or">
          <span>Or try anotther login method</span>
        </div>
        <div className="social-login">
          <button className="line" onClick={handleLoginWithLine}>
            Login with line
          </button>
        </div>
      </div>
      <div className="wrap-footer">
        <Footer />
      </div>
    </Style>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;

import styled from '@emotion/styled';

export default styled.header`
  label: component-layout;
  padding: 0 12px;
  .children {
    margin: auto;
    max-width: 1200px;
    background: #ffffff;
    box-shadow: 0px 2px 10px #ffe0c2;
    border-radius: 20px;
    padding: 38px 40px;
  }
  &.not-background {
    .children {
      background: none;
      box-shadow: unset;
      padding: 0;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { Spin } from 'antd';
import { GenderMale, GenderFemale, GenderOther } from './Icons';

import { getStatisticGender, getStatisticProblem } from '../../../service';

import Style from './style';

const ProblemChart = ({ year }) => {
  const COLORS = ['#E4F2FF', '#FFD56A', '#FF937E'];
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    gender: [],
    problem: []
  });

  const loadData = async () => {
    setLoading(true);
    try {
      const [resGender, resProblem] = await Promise.all([
        getStatisticGender({ year }),
        getStatisticProblem({ year })
      ]);
      setState({
        gender: get(resGender, 'data', []),
        problem: get(resProblem, 'data', [])
      });
    } catch (err) {
      console.log('error', err);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, [year]); // eslint-disable-line

  const renderActiveShape = (values) => {
    const { cx, cy, value } = values;
    return (
      <g>
        <g>
          <text x={cx} y={cy} dy={0} textAnchor="middle" className="name-chart">
            {value === 9999999999 ? 0 : value}
          </text>
          <text
            x={cx}
            y={cy}
            dy={20}
            textAnchor="middle"
            className="value-chart"
          >
            ราย
          </text>
        </g>
      </g>
    );
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (values) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, value } = values;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="#393F4D"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {Number(value)
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </text>
    );
  };

  const { gender, problem } = state;
  const totalGender = reduce(
    gender,
    (sum, value) => {
      return sum + value.totalIncident;
    },
    0
  );

  const male = find(gender, (obj) => obj.gender === 'male');
  const female = find(gender, (obj) => obj.gender === 'female');
  const other = find(gender, (obj) => obj.gender === 'other');

  let data = [
    {
      name: 'female',
      value: get(female, 'totalIncident', 0)
    },
    {
      name: 'male',
      value: get(male, 'totalIncident', 0)
    },
    {
      name: 'other',
      value: get(other, 'totalIncident', 0)
    }
  ];

  /* ถ้า totalGender เป็น 0 ให้แสดง chart ปล่าว */
  if (totalGender === 0) {
    data = [
      {
        name: 'total',
        value: 100
      }
    ];
  }
  return (
    <Style>
      {loading && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      <div className="wrap-hrader-chart">
        <div className="title">พบปัญหาใน</div>
      </div>
      <div className="wrap-gender">
        <ul>
          <li>
            <GenderFemale />
            <span>
              {Number(get(female, 'totalIncident', 0))
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          </li>
          <li>
            <GenderMale />
            <span>
              {Number(get(male, 'totalIncident', 0))
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          </li>
          <li>
            <GenderOther />
            <span>
              {Number(get(other, 'totalIncident', 0))
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          </li>
        </ul>
      </div>
      <div className="wrap-chart" style={{ width: '100%', height: '300px' }}>
        {!loading && (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                innerRadius={70}
                outerRadius={100}
                paddingAngle={2}
                label={totalGender > 0 ? renderCustomizedLabel : undefined}
                fill="#EEEEEE"
                dataKey="value"
              >
                {data.map((entry, index) => {
                  const key = `cell-${index}`;
                  return (
                    <Cell
                      key={key}
                      fill={
                        totalGender <= 0
                          ? '#EEEEEE'
                          : COLORS[index % COLORS.length]
                      }
                    />
                  );
                })}
              </Pie>
              {/* <Tooltip /> */}
              {/* ถ้า totalGender เท่ากับ 0 ให้ default value เป็น 9999999999 เพื่อ แสดงค่า */}
              <Pie
                data={[
                  {
                    name: 'total',
                    value: totalGender <= 0 ? 9999999999 : totalGender
                  }
                ]}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={60}
                paddingAngle={-1}
                label={renderActiveShape}
                fill="#ffffff"
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className="wrap-type">
        <ul>
          <li>
            <div className="label">
              <span className="icon">
                <svg
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.25449 17C11.49 16.0101 13.1917 14.9178 14.5263 13.8256C15.4939 13.0064 16.2613 12.1871 16.8619 11.4021C19.1641 8.3983 18.9973 5.87241 18.9973 5.87241C19.0641 2.80037 16.7618 0.274477 13.859 0.172076C12.1239 0.137942 10.5891 0.957153 9.58814 2.28837C9.02092 1.46916 8.2535 0.820618 7.35263 0.411014C7.21916 0.342746 7.11907 0.308612 6.98561 0.274478C6.48512 0.10381 5.98463 0.00140753 5.41741 0.00140753C2.51458 -0.0668599 0.0788642 2.35663 0.0121325 5.42867C-0.388258 11.9141 9.25449 17 9.25449 17Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div className="name">สัมพันธภาพในครอบครัว</div>
            </div>
            <div className="value">
              {get(
                find(problem, (obj) => obj.problemTypeId === 1),
                'totalIncident',
                0
              )}
            </div>
          </li>
          <li>
            <div className="label">
              <span className="icon">
                <svg
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.48 3.09091H2.28C2.07844 3.09091 1.88513 3.0095 1.7426 2.86458C1.60007 2.71967 1.52 2.52312 1.52 2.31818C1.52 2.11324 1.60007 1.9167 1.7426 1.77178C1.88513 1.62687 2.07844 1.54545 2.28 1.54545H15.2C15.4016 1.54545 15.5949 1.46404 15.7374 1.31913C15.8799 1.17421 15.96 0.977667 15.96 0.772727C15.96 0.567787 15.8799 0.371241 15.7374 0.226327C15.5949 0.0814123 15.4016 0 15.2 0H2.28C1.67552 0.000703026 1.09599 0.245165 0.66856 0.679756C0.241127 1.11435 0.000691447 1.70358 0 2.31818V14.6818C0.000691447 15.2964 0.241127 15.8857 0.66856 16.3202C1.09599 16.7548 1.67552 16.9993 2.28 17H17.48C17.883 16.9995 18.2693 16.8366 18.5543 16.5468C18.8392 16.2571 18.9995 15.8643 19 15.4545V4.63636C18.9995 4.22663 18.8392 3.83381 18.5543 3.54408C18.2693 3.25435 17.883 3.09138 17.48 3.09091ZM14.06 11.2045C13.8345 11.2045 13.6141 11.1366 13.4267 11.0092C13.2392 10.8818 13.0931 10.7008 13.0068 10.489C12.9205 10.2772 12.8979 10.0442 12.9419 9.81933C12.9859 9.59449 13.0945 9.38796 13.2539 9.22585C13.4133 9.06375 13.6165 8.95336 13.8376 8.90864C14.0587 8.86391 14.288 8.88687 14.4963 8.97459C14.7046 9.06232 14.8826 9.21089 15.0079 9.4015C15.1331 9.59211 15.2 9.81621 15.2 10.0455C15.2 10.3529 15.0799 10.6477 14.8661 10.8651C14.6523 11.0824 14.3623 11.2045 14.06 11.2045Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div className="name">เศรษฐกิจการเงิน</div>
            </div>
            <div className="value">
              {get(
                find(problem, (obj) => obj.problemTypeId === 8),
                'totalIncident',
                0
              )}
            </div>
          </li>
          <li>
            <div className="label">
              <span className="icon">
                <svg
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.25449 17C11.49 16.0101 13.1917 14.9178 14.5263 13.8256C15.4939 13.0064 16.2613 12.1871 16.8619 11.4021C19.1641 8.3983 18.9973 5.87241 18.9973 5.87241C19.0641 2.80037 16.7618 0.274477 13.859 0.172076C12.1239 0.137942 10.5891 0.957153 9.58814 2.28837C9.02092 1.46916 8.2535 0.820618 7.35263 0.411014C7.21916 0.342746 7.11907 0.308612 6.98561 0.274478C6.48512 0.10381 5.98463 0.00140753 5.41741 0.00140753C2.51458 -0.0668599 0.0788642 2.35663 0.0121325 5.42867C-0.388258 11.9141 9.25449 17 9.25449 17Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div className="name">ความรัก</div>
            </div>
            <div className="value">
              {get(
                find(problem, (obj) => obj.problemTypeId === 2),
                'totalIncident',
                0
              )}
            </div>
          </li>
          <li>
            <div className="label">
              <span className="icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.308 1.69453C19.7707 1.1573 19.1329 0.731142 18.4308 0.440394C17.7288 0.149646 16.9764 0 16.2166 0C15.4567 0 14.7043 0.149646 14.0023 0.440394C13.3003 0.731142 12.6624 1.1573 12.1251 1.69453L1.6947 12.1236C0.609592 13.2086 -1.00038e-05 14.6802 1.23127e-10 16.2146C1.00041e-05 17.749 0.609631 19.2205 1.69475 20.3055C2.77988 21.3905 4.25161 22 5.7862 22C7.32078 22 8.79251 21.3904 9.87762 20.3055L20.308 9.87634C21.3915 8.7905 22 7.31928 22 5.78543C22 4.25158 21.3915 2.78036 20.308 1.69453ZM19.0787 8.64728L14.4782 13.2473L8.75377 7.52362L13.3544 2.92359C14.1136 2.16487 15.143 1.73872 16.2164 1.73884C17.2898 1.73897 18.3191 2.16538 19.0781 2.92427C19.8371 3.68317 20.2635 4.71242 20.2636 5.78566C20.2638 6.85889 19.8375 7.88822 19.0787 8.64728ZM17.7637 6.10344C17.843 6.18553 17.9053 6.28243 17.9472 6.38862C17.989 6.4948 18.0095 6.60819 18.0076 6.72231C18.0056 6.83642 17.9811 6.94903 17.9356 7.0537C17.8901 7.15837 17.8244 7.25305 17.7423 7.33234L15.0895 9.89443C15.0075 9.97383 14.9105 10.0363 14.8043 10.0782C14.6981 10.1202 14.5846 10.1408 14.4704 10.1388C14.3563 10.1369 14.2436 10.1125 14.1388 10.067C14.0341 10.0215 13.9393 9.95579 13.86 9.87365C13.7806 9.79152 13.7182 9.69455 13.6764 9.5883C13.6346 9.48205 13.6141 9.36859 13.6161 9.25442C13.6181 9.14024 13.6427 9.02759 13.6883 8.92289C13.7339 8.8182 13.7996 8.72351 13.8819 8.64426L16.5347 6.08217C16.6167 6.00286 16.7137 5.9405 16.8198 5.89865C16.926 5.8568 17.0394 5.83627 17.1536 5.83825C17.2677 5.84022 17.3803 5.86466 17.485 5.91016C17.5897 5.95566 17.6844 6.02134 17.7637 6.10344V6.10344Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div className="name">สารเสพติด</div>
            </div>
            <div className="value">
              {get(
                find(problem, (obj) => obj.problemTypeId === 6),
                'totalIncident',
                0
              )}
            </div>
          </li>
          <li>
            <div className="label">
              <span className="icon">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 10.5556C18 11.4024 17.7852 12.2349 17.3764 12.9728C16.9676 13.7107 16.3785 14.3289 15.6661 14.7679C14.9537 15.2069 14.1419 15.4518 13.3092 15.479C12.4765 15.5061 11.651 15.3146 10.9124 14.9229L11.9457 18.0738C11.9804 18.1796 11.9899 18.2922 11.9734 18.4025C11.9568 18.5127 11.9147 18.6173 11.8506 18.7078C11.7865 18.7982 11.7021 18.8719 11.6044 18.9227C11.5068 18.9735 11.3987 19 11.289 19H6.71104C6.60134 19 6.4932 18.9735 6.39555 18.9227C6.2979 18.8719 6.21352 18.7982 6.14938 18.7078C6.08524 18.6173 6.04316 18.5126 6.02663 18.4024C6.01009 18.2922 6.01957 18.1795 6.05427 18.0737L7.08762 14.9229C6.34898 15.3146 5.52346 15.5061 4.69077 15.479C3.85808 15.4518 3.04633 15.2069 2.3339 14.7679C1.62146 14.3289 1.0324 13.7107 0.623579 12.9728C0.214758 12.2349 -1.26019e-05 11.4024 5.54585e-10 10.5556C5.54584e-10 5.84312 8.45356 0.289616 8.8134 0.0555351C8.8691 0.0192728 8.93386 0 9 0C9.06614 0 9.1309 0.0192728 9.1866 0.0555351C9.54644 0.289616 18 5.84312 18 10.5556Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div className="name">การพนัน</div>
            </div>
            <div className="value">
              {get(
                find(problem, (obj) => obj.problemTypeId === 5),
                'totalIncident',
                0
              )}
            </div>
          </li>
          <li>
            <div className="label">
              <span className="icon">
                <svg
                  width="18"
                  height="4"
                  viewBox="0 0 18 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="white" />
                  <circle cx="9" cy="2" r="2" fill="white" />
                  <circle cx="16" cy="2" r="2" fill="white" />
                </svg>
              </span>
              <div className="name">ไม่ระบุ</div>
            </div>
            <div className="value">
              {get(
                find(problem, (obj) => obj.problemTypeId === 9),
                'totalIncident',
                0
              )}
            </div>
          </li>
        </ul>
      </div>
    </Style>
  );
};

ProblemChart.propTypes = {
  year: PropTypes.string.isRequired
};

ProblemChart.defaultProps = {};

export default ProblemChart;

import React from 'react';
// import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import moment from 'moment';

import { LayoutMenu } from '../../components/Layout';

import useLoading from '../../hooks/loading';
import Country from './Country';
import CompareChart from './CompareChart';
import CustomerChart from './CustomerChart';
import ProblemChart from './ProblemChart';

import Style from './style';

const Dashboard = () => {
  const loading = useLoading();
  const [year, setYear] = React.useState(moment().format('YYYY'));

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  return (
    <LayoutMenu isBackground={false}>
      <Style>
        <Row>
          <Col md={12} sm={24}>
            <div className="wrap-card">
              <Country year={year} onChange={(value) => setYear(value)} />
            </div>
          </Col>
          <Col md={12} sm={24}>
            <div className="wrap-card">
              <ProblemChart year={year} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={18} sm={24}>
            <div className="wrap-card">
              <CompareChart year={year} />
            </div>
          </Col>
          <Col md={6} sm={24}>
            <div className="wrap-card">
              <CustomerChart year={year} />
            </div>
          </Col>
        </Row>
      </Style>
    </LayoutMenu>
  );
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Image = (props) => {
  const { src } = props;
  const [image, setImage] = useState('');

  useEffect(() => {
    let link = src;
    if (!/^http+/.test(src) && !/^\/assets+/.test(src)) {
      link = `${process.env.REACT_APP_IMG_URL}${src}`;
    }
    setImage(link);
  }, [src]);

  const handleLoadImageError = () => {
    setImage('/assets/img-not-found.png');
  };

  return <img {...props} src={image} alt="" onError={handleLoadImageError} />;
};

Image.propTypes = {
  src: PropTypes.string
};

Image.defaultProps = {
  src: undefined
};

export default Image;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { Popconfirm, Dropdown, Menu } from 'antd';

import { LayoutMenu } from '../../components/Layout';
import Button from '../../components/Button';
import useLoading from '../../hooks/loading';
import * as Icon from '../../components/Icons';
import Table from '../../components/Table';
import Image from '../../components/Image';

import { getUsers, getRoleUsers, deleteUser } from '../../service';
import { getThaiDate, getSortValue } from '../../utils';

import Style from './style';

const Users = (props) => {
  const { history } = props;
  const defaultPageLimit = 10;

  const loading = useLoading();
  const [roles, setRoles] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [dataFilter, setDataFilter] = React.useState({
    page: 1,
    pageLimit: defaultPageLimit,
    sort: 'createdAt|desc'
  });

  const [state, setState] = React.useState({
    total: 1,
    rows: []
  });

  const loadData = async (query) => {
    setLoading(true);
    const { page, pageLimit = 10, sort = {} } = query;
    const pageSize = Number(pageLimit);
    try {
      const query = {
        offset: Number(page) * pageSize - pageSize,
        limit: pageSize,
        ...getSortValue(sort)
      };
      const res = await getUsers(query);
      setState({
        ...state,
        total: get(res, 'meta.total', 1),
        rows: get(res, 'data', [])
      });
    } catch (err) {
      console.log('loadData err', err);
    }
    setLoading(false);
  };

  const initPage = async () => {
    try {
      const res = await getRoleUsers();
      setRoles(get(res, 'data', []));
    } catch (err) {
      console.log('getRoleUsers err', err);
    }
    loading.close();
    loadData(dataFilter);
  };

  React.useEffect(() => {
    initPage();
  }, []); // eslint-disable-line

  const handlePageChange = (page, pageLimit) => {
    const body = {
      ...dataFilter,
      page,
      pageLimit
    };
    setDataFilter(body);
    loadData(body);
  };

  const handleSortChange = (e) => {
    const body = {
      ...dataFilter,
      page: 1,
      pageLimit: 10,
      sort: e.key
    };
    setDataFilter(body);
    loadData(body);
  };

  const handleDelete = async (value) => {
    setLoading(true);
    try {
      await deleteUser(value.id);

      loadData(dataFilter);
    } catch (err) {
      console.log('deleteUser err ', err);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'ผู้ใช้งาน',
      dataIndex: 'profileImageUrl',
      key: 'profileImageUrl',
      width: 400,
      render: (value, row) => (
        <div className="row-user-info">
          <div className="wrap-image">
            <Image src={value} alt="" />
          </div>
          {`${get(row, 'firstName', '')} ${get(row, 'lastName', '')}`.trim()}
        </div>
      )
    },
    {
      title: 'วันที่เป็นสมาชิก',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => {
        return getThaiDate(value);
      }
    },
    {
      title: 'ประเภทผู้ใช้งาน',
      dataIndex: 'roleIds',
      key: 'roleIds',
      render: (value) => {
        const filterRoles = filter(roles, (role) => includes(value, role.id));
        return (
          <ul>
            {map(filterRoles, (role) => (
              <li key={role.id}>{role.description}</li>
            ))}
          </ul>
        );
      }
    },
    {
      title: 'สถานะการใช้งาน',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        switch (value) {
          case 'wait_verify': {
            return <span className="user-status wait-verify">รอยืนยัน</span>;
          }
          case 'verified': {
            return <span className="user-status verified">เป็นสมาชิก</span>;
          }
          case 'disabled': {
            return (
              <span className="user-status disabled">ปิดการเป็นสมาชิก</span>
            );
          }
          case 'deleted': {
            return (
              <span className="user-status disabled">ยกเลิกการเป็นสมาชิก</span>
            );
          }
          default: {
            return '';
          }
        }
      }
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'status',
      render: (value, row) => {
        if (row.status === 'deleted') return '';
        return (
          <div className="wrap-action ">
            <button
              onClick={() => {
                history.push(`/users/${row.id}/update`);
              }}
            >
              <Icon.Search />
            </button>
            <Popconfirm
              title="ยืนยันการทำรายการ"
              okText="ยืนยัน"
              cancelText="ยกเลิก"
              onConfirm={() => handleDelete(row)}
            >
              <button>
                <Icon.Bin />
              </button>
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  const menuSort = (
    <Menu onClick={handleSortChange} selectedKeys={[dataFilter.sort]}>
      <Menu.Item key="createdAt|asc">วันที่เป็นสมาชิก (เก่า ไป ใหม่)</Menu.Item>
      <Menu.Item key="createdAt|desc">วันที่เป็นสมาชิก (ใหม่ ไป เก่า)</Menu.Item>
    </Menu>
  );

  return (
    <LayoutMenu>
      <Style>
        <div className="header">
          <h1>จัดการผู้ใช้งาน</h1>

          <div className="action">
            <Dropdown overlay={menuSort} trigger={['click']}>
              <Button className="button-sort with-icon">
                <Icon.SortAmountDown color="#FF7A44" />
                <span>เรียงตาม</span>
              </Button>
            </Dropdown>

            <Button
              primary
              className="with-icon"
              onClick={() => {
                history.push('/users/create');
              }}
            >
              <Icon.Plus color="#FFFFFF" />
              <span>เพิ่มสมาชิก</span>
            </Button>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          loading={isLoading}
          dataSource={get(state, 'rows', [])}
          pagination={{
            current: get(dataFilter, 'page', 1),
            pageSize: get(dataFilter, 'pageLimit', 10),
            total: get(state, 'total', 1),
            onChange: handlePageChange
          }}
        />
      </Style>
    </LayoutMenu>
  );
};

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

Users.defaultProps = {
  // children: ''
};

export default Users;

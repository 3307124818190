import React from 'react';
// import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import includes from 'lodash/includes';
import filter from 'lodash/filter';

import { useSelector } from 'react-redux';

import Style from './style';

const MainMenu = () => {
  const roleUser = useSelector(({ user }) => get(user, 'authUser.roleIds'));

  const menus = [
    {
      to: '/messages',
      label: 'เคสทั้งหมด',
      key: 'messages',
      roles: [1, 2, 3, 4]
    },
    {
      to: '/incidents/me',
      label: 'เคสของฉัน',
      key: 'incidents-me',
      roles: [1, 2, 3, 4]
    },
    {
      to: '/medical-record',
      label: 'ประวัติผู้ป่วย',
      key: 'medical-record',
      roles: [1, 2, 3, 4]
    },
    {
      to: '/dashboard',
      label: 'แดชบอร์ด',
      key: 'dashboard',
      roles: [1, 2, 3, 4]
    },
    {
      to: '/users',
      label: 'จัดการผู้ใช้งาน',
      key: 'users',
      roles: [1]
    },
  ];

  const filterMenu = filter(menus, (menu) => {
    const getValue = find(roleUser, (role) => includes(menu.roles || [], role));
    return getValue !== undefined;
  });

  return (
    <Style>
      <div className="container">
        <ul className="list-menu">
          {map(filterMenu, (menu) => {
            return (
              <li key={menu.key}>
                <NavLink exact activeClassName="selected" to={menu.to}>
                  {menu.label}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </Style>
  );
};

MainMenu.propTypes = {};

MainMenu.defaultProps = {
  onBack: undefined
};

export default MainMenu;

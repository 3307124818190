import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { useLocation } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb';
import UpdatedAt from '../../components/UpdatedAt';
import { alert } from '../../utils';

import FormData from './Form';
import {
  createIncident,
  updateActionIncident,
  verifyChannel,
} from '../../service';

import useLoading from '../../hooks/loading';

import { StyleCreate as Style } from './style';

const CreateIncident = (props) => {
  const { history } = props;
  const loading = useLoading();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userId = searchParams.get('userId');
  const channe = searchParams.get('channe');

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleSubmit = async ({ create, action }) => {
    let refId = null;
    let channeId = null;
    if (userId !== null && !isNaN(Number(userId))) refId = Number(userId);
    if (channe !== null && !isNaN(Number(channe))) channeId = Number(channe);
    try {
      loading.open();
      const result = await createIncident({
        ...create,
        refId,
      });
      if (!isEmpty(action) && get(result, 'data.id')) {
        await updateActionIncident(get(result, 'data.id'), action);
        if (channeId) {
          await verifyChannel({
            id: channeId,
            status: 'update',
            incidentId: get(result, 'data.id'),
          });
        }
      } else if (get(result, 'data.id') && channeId) {
        await verifyChannel({
          id: channeId,
          status: 'verify',
          incidentId: get(result, 'data.id'),
        });
      }

      history.push(`/incidents/${get(result, 'data.id')}/detail`);
    } catch (err) {
      console.log('err', err);
      alert.error('Syatem error');
      loading.close();
    }
  };

  const handlePageBack = () => {
    history.goBack();
  };

  const handleCancle = async () => {
    let channeId = null;
    if (channe !== null && !isNaN(Number(channe))) {
      channeId = Number(channe);
      try {
        loading.open();
        await verifyChannel({
          id: channeId,
          status: 'done',
          incidentId: null,
        });
        history.push('/messages');
      } catch (err) {
        alert.error('Syatem error');
        loading.close();
      }
    } else {
      history.push('/messages');
    }
  };

  return (
    <Style>
      <div className="container">
        <Breadcrumb title="สร้างเคส" onBack={handlePageBack} />
        <UpdatedAt className="time" date={moment().format()} />
        <FormData onCancle={handleCancle} onSubmit={handleSubmit} />
      </div>
    </Style>
  );
};

CreateIncident.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

CreateIncident.defaultProps = {};

export default CreateIncident;

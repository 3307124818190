import styled from '@emotion/styled';

export default styled.div`
  label: page-medical-record-detail;
  font-family: Kanit;
  .title-breadcrumb {
    > span {
      color: #ff7a44;
    }
  }
  .container {
    h2 {
      font-weight: 500;
      font-size: 24px;
      color: #393f4d;
      margin-bottom: 26px;
    }
    > .time {
      margin: 10px 0 30px 40px;
    }

    .label {
      text-align: right;
      color: #393f4d;
      font-weight: 500;
      font-size: 20px;
    }
    .value {
      color: #393f4d;
      font-weight: 300;
      font-size: 20px;
    }
    section {
      background: #ffffff;
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 20px;
      padding: 40px 60px;
      margin-bottom: 16px;
      hr {
        border: none;
        border-bottom: 2px solid #e5e5e5;
        margin: 40px 0;
      }
      .ant-row {
        margin-bottom: 16px;
      }
      &.incidents {
        padding: 0;
      }
      .wrap-button {
        display: flex;
        justify-content: flex-end;
        padding: 30px 40px;
      }
      ul {
        margin: 0;
        padding: 0 40px 40px 40px;
        li {
          border-bottom: 2px solid #e5e5e5;
          padding: 24px 0;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    section.incidents {
      > ul > li {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #e5e5e5;
        @media (max-width: 768px) {
          flex-wrap: wrap;
          .info {
            flex: 1 1 100%;
            margin-bottom: 10px;
          }
        }
        .info .title {
          display: flex;
          align-items: center;
          font-size: 24px;
          .name {
            margin: 0 10px;
          }
        }
      }
      .update-at {
        .date-time {
          font-size: 16px;
        }
      }
      .status {
        padding: 4px 20px;
        border-radius: 30px;
        font-family: Kanit;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        &.lavel-10,
        &.lavel-20 {
          color: #fff;
          background: #ff937e;
        }
        &.lavel-30,
        &.lavel-40 {
          color: #ff7a44;
          background: #feecd7;
        }
        &.lavel-50 {
          color: #1172bc;
          background: #e3f1ff;
        }
        &.lavel-60,
        &.lavel-delete {
          color: #393f4d;
          background: #e5e5e5;
        }
      }
      .severity {
        padding-left: 32px;
        position: relative;
        line-height: 1;
        margin-top: 10px;
        &:after {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background: rgba(57, 63, 77, 0.5);
          top: 0;
          left: 0;
        }
        &.level-1:after {
          background: #a6d4ff;
        }
        &.level-2:after {
          background: #ffd56a;
        }
        &.level-3:after {
          background: #ff7a44;
        }
        &.level-4:after {
          background: #e64300;
        }
      }
    }
  }
`;

import styled from '@emotion/styled';

export default styled.div`
  label: form-user;
  background: #ffffff;
  box-shadow: 0px 2px 10px #ffe0c2;
  border-radius: 20px;
  padding: 50px 90px;
  margin-top: 48px;
  > .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    font-family: Kanit;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 6px;
        height: 18px;
      }
    }
  }
  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin: 12px;
    }
  }
`;

import styled from '@emotion/styled';

export default styled.header`
  label: component-header;
  height: 68px;
  .wrap-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 68px;
    padding: 0 20px;
    background: #fff;
    display: flex;
    align-items: center;
    z-index: 90;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    > .user-info {
      display: flex;
      align-items: center;
      font-family: Kanit;
      color: #393f4d;
      font-weight: 300;
      .alert {
        button {
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
        }
      }
      .profile {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 100%;
        margin: 0 15px;
      }
      > button {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        height: 60px;
        .name {
          margin-right: 10px;
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`;

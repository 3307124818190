import * as ActionType from '../actions/actionType';

const innitialState = {
  isLoading: true,
  isReady: false
};

export default (state = innitialState, action) => {
  switch (action.type) {
    case ActionType.INITAPP: {
      return {
        ...state,
        isReady: true
      };
    }
    case 'APP_LOADING_OPEN': {
      return {
        ...state,
        isLoading: true
      };
    }
    case 'APP_LOADING_CLOSE': {
      return {
        ...state,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
